import React from 'react'
import Spinner from 'react-loader-spinner'

const RedirectLoader = () => {
  return (
    <div className="w-50 mx-auto" style={{ textAlign: 'center' }}>
      <h2 className="mt-4">Redirecting...</h2>
      <Spinner
        type="TailSpin"
        ariaLabel="loading-indicator"
        color="#552E19"
        className="mt-4"
      />
    </div>
  )
}

export default RedirectLoader
