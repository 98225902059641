import React from 'react'
import { Form } from 'react-bootstrap'

const SearchBarList = ({ query, setQuery, placeholder, searchHandler }) => {
  return (
    <Form onSubmit={searchHandler} className="d-flex w-100 searchbar">
      <Form.Control
        type="text"
        name="q"
        onChange={(e) => setQuery(e.target.value)}
        placeholder={placeholder}
        value={query || ''}
        className="mr-sm-2 ml-sm-5"
        autoCorrect="off"
        spellCheck="false"
        autoComplete="off"
        autoCapitalize="off"
        maxLength={100}
        style={{
          borderRadius: '0px',
          border: 'none',
          maxHeight: '45px',
        }}
      ></Form.Control>
    </Form>
  )
}

export default SearchBarList
