import React, { useState } from 'react'
import { Form, Button, Breadcrumb, Row } from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import Meta from '../components/Meta'
import axios from 'axios'
import Message from '../components/Message'
import ButtonSpinner from '../components/ButtonSpinner'
import '../css/ForgotPasswordScreen.css'
import { LinkContainer } from 'react-router-bootstrap'

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const submitHandler = async (e) => {
    setError('')
    setSuccess(false)

    setLoading(true)

    e.preventDefault()

    try {
      await axios.post(
        '/api/users/forgotpassword',
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      setSuccess(true)
    } catch (error) {
      if (error.response.data.message === 'Failed to send reset link') {
        setError(
          'Er is een fout opgetreden bij het versturen van de reset link. Probeer het later opnieuw.',
        )
      } else {
        setError(error.response.data.message)
      }
    }

    setLoading(false)
  }

  return (
    <>
      <Meta title="Wachtwoord vergeten" />
      <FormContainer>
        <Breadcrumb>
          <LinkContainer className="active" to="/login">
            <Breadcrumb.Item>&lt; Ga terug</Breadcrumb.Item>
          </LinkContainer>
        </Breadcrumb>
        <h2>Wachtwoord vergeten</h2>
        <p id="forgot-explanation">
          Vul uw e-mailadres in en we sturen u een e-mail met instructies om uw
          wachtwoord te wijzigen.
        </p>
        {error && <Message variant="danger">{error}</Message>}
        {success && (
          <Message variant="success">E-mail verzonden. Check uw inbox.</Message>
        )}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="email" className="mt-2">
            <Form.Label>Uw e-mailadres</Form.Label>
            <Form.Control
              type="email"
              placeholder="E-mailadres"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoCorrect="off"
              spellCheck="false"
              autoCapitalize="off"
            ></Form.Control>
          </Form.Group>

          <Row style={{ marginTop: '61px' }}>
            <Button
              type="submit"
              variant="primary"
              className="mx-auto w-50 text-center"
              disabled={loading}
            >
              {loading ? <ButtonSpinner /> : 'Verzenden'}
            </Button>
          </Row>
        </Form>
      </FormContainer>
    </>
  )
}

export default ForgotPasswordScreen
