import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import { countries } from '../config'
import Address from '../utils/Address'
import { updateUserProfile } from '../actions/userActions'
import BasicSet from '../utils/BasicSet'

const NewAddressForm = ({
  index = -1,
  editCompanyName = '',
  editFirstName = '',
  editLastName = '',
  editPostalCode = '',
  editAddress = '',
  editAddition = '',
  editCity = '',
  editCountry = countries[0],
}) => {
  const dispatch = useDispatch()

  const [validated, setValidated] = useState(false)

  const [companyName, setCompanyName] = useState(editCompanyName)
  const [firstName, setFirstName] = useState(editFirstName)
  const [lastName, setLastName] = useState(editLastName)
  const [postalCode, setPostalCode] = useState(editPostalCode)
  const [address, setAddress] = useState(editAddress)
  const [addition, setAddition] = useState(editAddition)
  const [city, setCity] = useState(editCity)
  const [country, setCountry] = useState(editCountry)

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // @ts-ignore
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { loading } = userUpdateProfile

  const submitHandler = async () => {
    const newAddress = new Address({
      companyName,
      firstName,
      lastName,
      postalCode,
      address,
      addition,
      city,
      country,
    })

    const addresses = userInfo.addresses.map((address) => new Address(address))

    if (index > -1) {
      addresses[index] = newAddress
    }

    await dispatch(
      updateUserProfile({
        id: userInfo._id,
        addresses: new BasicSet(...addresses, newAddress).values,
      }),
    )
  }

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={async (e) => {
        e.preventDefault()

        const form = e.currentTarget
        if (form.checkValidity()) await submitHandler()

        setValidated(true)
      }}
    >
      <Form.Group controlId="company-name" className="mt-2 mb-4">
        <Form.Label>
          Bedrijfsnaam <span className="note-text">(niet verplicht)</span>
        </Form.Label>
        <Form.Control
          type="text"
          value={companyName}
          required
          onChange={(e) => setCompanyName(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="name" className="mt-2 mb-4">
        <Form.Label>Voornaam</Form.Label>
        <Form.Control
          type="text"
          value={firstName}
          required
          onChange={(e) => setFirstName(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="name" className="mt-2 mb-4">
        <Form.Label>Achternaam</Form.Label>
        <Form.Control
          type="text"
          value={lastName}
          required
          onChange={(e) => setLastName(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="postalCode" className="mt-2 mb-4">
        <Form.Label>Postcode</Form.Label>
        <Form.Control
          type="text"
          placeholder="Postcode"
          value={postalCode}
          required
          onChange={(e) => setPostalCode(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="address" className="mt-2 mb-4">
        <Form.Label>Adres</Form.Label>
        <Form.Control
          type="text"
          placeholder="Straatnaam en nummer"
          value={address}
          required
          onChange={(e) => setAddress(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="addition" className="mt-2 mb-4">
        <Form.Label>
          Toevoeging <span className="note-text">(niet verplicht)</span>
        </Form.Label>
        <Form.Control
          type="text"
          value={addition}
          onChange={(e) => setAddition(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="city" className="mt-2 mb-4">
        <Form.Label>Plaats</Form.Label>
        <Form.Control
          type="text"
          placeholder="Plaats"
          value={city}
          required
          onChange={(e) => setCity(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="country" className="mt-2 mb-4">
        <Form.Label>Land</Form.Label>
        <Form.Select
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          {countries.map((country) => (
            <option key={country}>{country}</option>
          ))}
        </Form.Select>
      </Form.Group>
      <Button
        className="w-100 mt-4"
        variant="primary"
        disabled={loading}
        type="submit"
      >
        {loading ? (
          <span
            className="spinner-border spinner-border-sm button-spinner"
            role="status"
          ></span>
        ) : (
          'Opslaan'
        )}
      </Button>
    </Form>
  )
}

export default NewAddressForm
