import IdealLogo from './assets/ideal-logo.svg'
import AmexLogo from './assets/amex-logo.svg'
import MastercardLogo from './assets/mastercard-logo.svg'
import PaypalLogo from './assets/paypal-logo.svg'
import VisaLogo from './assets/visa-logo.svg'
import MaestroLogo from './assets/maestro-logo.svg'

export const countries = ['Nederland', 'België']

export const paymentMethods = {
  ideal: {
    displayName: 'iDEAL',
    logo: IdealLogo,
  },
  // visa: {
  //   displayName: 'VISA',
  //   logo: VisaLogo,
  // },
  // mastercard: {
  //   displayName: 'Mastercard',
  //   logo: MastercardLogo,
  // },
  // amex: {
  //   displayName: 'American Express',
  //   logo: AmexLogo,
  // },
  // maestro: {
  //   displayName: 'Maestro',
  //   logo: MaestroLogo,
  // },
  // paypal: {
  //   displayName: 'PayPal',
  //   logo: PaypalLogo,
  // },
}

export const productProfiles = [
  'Rhombus',
  'Mes en groef',
  'Vlak geschaafd',
  'Gegroefd',
  'Fijn geribbeld',
  'Anti-slip',
  'Geen',
]

export const taxRate = 21 // %

export const species = [
  'Angelim Pedra',
  'Cedrinho',
  'Cumaru',
  'Guariuba',
  'Itauba',
  'Louro Gamela',
  'Louro Preto',
  'Mandioqueira',
  'Muiracatiara',
  'Orelha de Macaco',
  'Pequia Marfim',
  'Sucupira Vermelha',
  'Tanibuca',
  'Timborana',
  'Tauari Vermelho',
]
