import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Form,
  Button,
  Breadcrumb,
  Row,
  ListGroup,
  Image,
  Col,
  Container,
  InputGroup,
} from 'react-bootstrap'
import ButtonSpinner from '../components/ButtonSpinner'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { updateCategory, getCategoryDetails } from '../actions/categoryActions'
import {
  CATEGORY_UPDATE_RESET,
  CATEGORY_DETAILS_RESET,
} from '../constants/categoryConstants'
import Meta from '../components/Meta'
import '../css/ProductEditScreen.css'
import TextEditor from '../components/TextEditor'

const ProductEditScreen = ({ match, history }) => {
  const categoryId = match.params.id

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [metaDescription, setMetaDescription] = useState('')
  const [image, setImage] = useState('')
  const [url, setUrl] = useState('')
  const [rank, setRank] = useState(999) // default value

  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  // @ts-ignore
  const categoryDetails = useSelector((state) => state.categoryDetails)
  const { loading, error, category } = categoryDetails

  // @ts-ignore
  const categoryUpdate = useSelector((state) => state.categoryUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = categoryUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: CATEGORY_UPDATE_RESET })
      dispatch({ type: CATEGORY_DETAILS_RESET })
      history.push('/admin/categories')
    } else {
      if (!category.name || category._id !== categoryId) {
        dispatch(getCategoryDetails(categoryId))
      } else {
        setName(category.name)
        setDescription(category.description)
        setMetaDescription(category.metaDescription)
        setImage(category.image)
        setUrl(category.url)
        setRank(category.rank)
      }
    }
  }, [dispatch, category, categoryId, history, successUpdate])

  const uploadFileHandler = async (e) => {
    const files = e.target.files
    const formData = new FormData()

    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i])
    }

    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data[0])
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateCategory({
        _id: categoryId,
        name: name,
        description,
        metaDescription,
        image,
        url,
        rank,
      }),
    )
  }

  return (
    <>
      <Meta title="Admin | Wijzig categorie" />
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} lg={6}>
            <Breadcrumb>
              <LinkContainer to="/admin/categories">
                <Breadcrumb.Item className="active">
                  &lt; Ga terug
                </Breadcrumb.Item>
              </LinkContainer>
            </Breadcrumb>
            <h2>Bewerk categorie</h2>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <Form onSubmit={submitHandler}>
                <Form.Group controlId="name" className="mt-2">
                  <Form.Label>Naam</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Naam"
                    value={name}
                    onChange={(e) => {
                      const name = e.target.value
                      setName(name)

                      // remove capitalization and URL encode
                      const url = '/c/' + encodeURIComponent(name.toLowerCase())
                      setUrl(url)
                    }}
                    className="mb-4"
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="description" className="mt-2">
                  <Form.Label>Omschrijving</Form.Label>
                  <TextEditor
                    description={description}
                    setDescription={setDescription}
                  />
                </Form.Group>

                <Form.Group controlId="metaDescription" className="mt-2">
                  <Form.Label>Meta omschrijving (SEO)</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={5}
                    placeholder="Meta omschrijving"
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                    className="mb-4"
                    style={{ height: 'unset' }}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="image" className="mt-2 mb-4">
                  <Form.Label>Omslagfoto</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Afbeelding URL"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                  ></Form.Control>
                  <Form.Control
                    type="file"
                    onChange={uploadFileHandler}
                    style={{
                      border: 'none',
                      paddingBottom: '0px',
                      height: 'inherit',
                      marginBottom: '0.5rem',
                    }}
                  ></Form.Control>
                  {uploading && <Loader />}
                </Form.Group>

                <Form.Group controlId="width" className="mt-2">
                  <Form.Label>URL</Form.Label>
                  <InputGroup>
                    <InputGroup.Text style={{ height: '60px' }}>
                      https://www.vlonderexpert.nl
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="vlonderplanken"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      className="mb-4"
                    ></Form.Control>
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="rank" className="mt-2">
                  <Form.Label>Rang</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="999"
                    value={rank}
                    onChange={(e) => {
                      const rank = parseInt(e.target.value) || 999
                      setRank(rank)
                    }}
                    className="mb-4"
                  ></Form.Control>
                </Form.Group>

                <Row style={{ marginTop: '48px' }}>
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-50 text-center mx-auto"
                    style={{ marginBottom: '86px' }}
                    disabled={loading}
                  >
                    {loading ? <ButtonSpinner /> : 'Bevestig'}
                  </Button>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProductEditScreen
