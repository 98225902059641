import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button, Row, Breadcrumb } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import Meta from '../components/Meta'
import ValidatePassword from '../components/ValidatePassword'
import ButtonSpinner from '../components/ButtonSpinner'
import '../css/RegisterScreen.css'

const RegisterScreen = ({ location, history }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [validPassword, setValidPassword] = useState(true)

  const dispatch = useDispatch()

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister

  const cartDetails = useSelector((state) => state.cartDetails)
  const { cartItems } = cartDetails

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = async (e) => {
    e.preventDefault()
    await dispatch(register(name, email, password, cartItems ?? []))
  }

  return (
    <>
      <Meta
        title="Registreren"
        description="Registreer op Vlonderexpert.nl om eenvoudig uw bestellingen van hoogwaardige FSC-gecertificeerde hardhouten vlonderplanken, onderregels en palen te plaatsen en te beheren. Profiteer van exclusieve aanbiedingen."
      />
      <FormContainer>
        <Breadcrumb>
          <LinkContainer className="active" to="/login">
            <Breadcrumb.Item>&lt; Al een account? Log in</Breadcrumb.Item>
          </LinkContainer>
        </Breadcrumb>
        <h2>Registreer</h2>
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader />}
        <Form id="register-form" onSubmit={submitHandler}>
          <Form.Group controlId="name" className="mt-2">
            <Form.Label>Naam</Form.Label>
            <Form.Control
              type="text"
              placeholder="Naam"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoCorrect="off"
              spellCheck="false"
              autoCapitalize="off"
              maxLength="64"
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="email" className="mt-2">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email"
              placeholder="E-mailadres"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoCorrect="off"
              spellCheck="false"
              autoCapitalize="off"
              maxLength="100"
            ></Form.Control>
          </Form.Group>

          <ValidatePassword
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            valid={validPassword}
            setValid={setValidPassword}
          />

          <Row style={{ marginTop: '61px', marginBottom: '86px' }}>
            <Button
              type="submit"
              variant="primary"
              className="w-50 text-center mx-auto"
              disabled={loading || !validPassword || password.length === 0}
            >
              {loading ? <ButtonSpinner /> : 'Registreer'}
            </Button>
          </Row>
        </Form>
      </FormContainer>
    </>
  )
}

export default RegisterScreen
