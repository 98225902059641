import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Nav, Navbar, Image } from 'react-bootstrap'
import '../css/Footer.css'

const Footer = () => {
  return (
    <footer className="text-center pt-4">
      <Navbar>
        <Container id="footer-container">
          <Row className="gy-3">
            <Col>
              <h6>CONTACT</h6>
              <Nav className="flex-column" as="nav">
                <div className="py-2 px-3 mb-0">
                  <a href="mailto:info@vlonderexpert.nl">
                    info@vlonderexpert.nl
                  </a>
                </div>
                <div className="py-2 px-3 mb-0">
                  <a href="tel:+31610231501">+31(0)610231501</a>
                </div>
              </Nav>
            </Col>
            <Col className="justify-content-center align-items-center d-none d-lg-flex">
              <Link
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0)
                }}
              >
                <Navbar.Brand>
                  {' '}
                  <Image
                    src="/images/logo.png"
                    alt="logo"
                    className="logo"
                    fluid
                  />
                </Navbar.Brand>
              </Link>
            </Col>

            <Col>
              <h6>LEGAL</h6>
              <Nav className="flex-column" as="nav">
                <Link className="py-2 px-3 mb-0" to="/privacy">
                  Privacy
                </Link>
                <Link className="py-2 px-3 mb-0" to="/terms">
                  Voorwaarden
                </Link>
                <Link className="py-2 px-3 mb-0" to="/delivery">
                  Levering
                </Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <Row className="g-0">
        <Col className="text-center py-3">
          <span>Copyright &copy; 2024 Vlonderexpert</span>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
