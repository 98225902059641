/**
 * Calculate price per piece
 *
 * @param {number} pricePerMeter
 * @param {number} length - length in mm
 * @returns
 */
const calculatePricePerPiece = (pricePerMeter, length) => {
  return +(Math.round(pricePerMeter * (length / 1000) + 'e+2') + 'e-2')
}

export default calculatePricePerPiece
