import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Slider from 'rc-slider'
import '../css/slider.css'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Accordion, Form, Container, Nav } from 'react-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { listProducts } from '../actions/productActions'
import Meta from '../components/Meta'
import * as config from '../config'
import '../css/SearchResultScreen.css'
import { LinkContainer } from 'react-router-bootstrap'

const { createSliderWithTooltip } = Slider
const Range = createSliderWithTooltip(Slider.Range)

const deliveryOptions = new Map()
deliveryOptions.set(3, ' \u2264 3 dagen')
deliveryOptions.set(5, ' \u2264 5 dagen')
deliveryOptions.set(7, ' \u2264 1 week')
deliveryOptions.set(14, ' \u2264 2 weken')

const thicknessOptions = new Map()
thicknessOptions.set('< 2,1 cm', [0, 21])
thicknessOptions.set('2,1 - 2,5 cm', [21, 25])
thicknessOptions.set('2,5 - 2,8 cm', [25, 28])
thicknessOptions.set('2,8 - 5 cm', [28, 50])
thicknessOptions.set('> 5 cm', [50, 1000])

const widthOptions = new Map()
widthOptions.set('< 13 cm (smal)', [0, 130])
widthOptions.set('13 - 15 cm (medium)', [130, 150])
widthOptions.set('15 - 30 cm (extra breed)', [150, 300])

const arrayFromUrl = (text) => {
  if (text === null || text.length === 0) return []
  return text.split(',')
}

const SearchResultScreen = ({ history }) => {
  const location = useLocation()

  const dispatch = useDispatch()

  const parameters = new URLSearchParams(location.search)

  const [sort, setSort] = useState(Number(parameters.get('sort')) || 0)
  const [deliveryTime, setDeliveryTime] = useState(
    Number(parameters.get('delivery')) || 14,
  )
  const [checkedTypes, setCheckedTypes] = useState(
    arrayFromUrl(parameters.get('types')) || [],
  )
  const [checkedProfiles, setCheckedProfiles] = useState(
    arrayFromUrl(parameters.get('profiles')) || [],
  )
  const [checkedSpecies, setCheckedSpecies] = useState(
    arrayFromUrl(parameters.get('species')) || [],
  )
  // lowPrice
  // highPrice
  // lowLength
  // highLength
  const [lowWidth, setLowWidth] = useState(
    Number(parameters.get('lowWidth')) || 0,
  )
  const [highWidth, setHighWidth] = useState(
    Number(parameters.get('highWidth')) || 1000,
  )
  const [lowThickness, setLowThickness] = useState(
    Number(parameters.get('lowThickness')) || 0,
  )
  const [highThickness, setHighThickness] = useState(
    Number(parameters.get('highThickness')) || 1000,
  )

  // @ts-ignore
  const productList = useSelector((state) => state.productList)
  const { loading, error, products, page, pages, count } = productList

  useEffect(() => {
    const parameters = new URLSearchParams(location.search)

    const query = parameters.get('q')
    const pageNumber = Number(parameters.get('page')) || 0
    const sortId = Number(parameters.get('sort')) || 0
    const urlDelivery = Number(parameters.get('delivery')) || 14
    const urlProductTypes = arrayFromUrl(parameters.get('types')) || []
    const urlProfiles = arrayFromUrl(parameters.get('profiles')) || []
    const urlSpecies = arrayFromUrl(parameters.get('species')) || []
    const urlLowPrice = Number(parameters.get('lowPrice')) || 0
    const urlHighPrice = Number(parameters.get('highPrice')) || 1000
    const urlLowLength = Number(parameters.get('lowLength')) || 0
    const urlHighLength = Number(parameters.get('highLength')) || 1000
    const urlLowWidth = Number(parameters.get('lowWidth')) || 0
    const urlHighWidth = Number(parameters.get('highWidth')) || 1000
    const urlLowThickness = Number(parameters.get('lowThickness')) || 0
    const urlHighThickness = Number(parameters.get('highThickness')) || 1000

    setSort(sortId)
    setDeliveryTime(urlDelivery)
    setCheckedTypes(urlProductTypes)
    setCheckedProfiles(urlProfiles)
    setCheckedSpecies(urlSpecies)
    // lowPrice
    // highPrice
    // lowLength
    // highLength
    setLowWidth(urlLowWidth)
    setHighWidth(urlHighWidth)
    setLowThickness(urlLowThickness)
    setHighThickness(urlHighThickness)

    dispatch(
      listProducts(
        query,
        pageNumber,
        sortId,
        urlDelivery,
        urlProductTypes,
        urlProfiles,
        urlSpecies,
        urlLowPrice,
        urlHighPrice,
        undefined, // lowLength
        undefined, // highLength
        urlLowWidth, // lowWidth
        urlHighWidth, // highWidth
        urlLowThickness, // lowThickness
        urlHighThickness, // highThickness
      ),
    )
  }, [dispatch, location.search])

  const sortHandler = (value) => {
    setSort(value)

    const params = new URLSearchParams(location.search)
    params.set('sort', value)

    history.push(`/search?${params.toString()}`)
  }

  const profileHandler = () => {
    const checks = Array.from(
      document.querySelectorAll('#profiles-form input[type=checkbox]:checked'),
    )

    const values = checks.map((check) => check.value)

    setCheckedProfiles(values)

    const params = new URLSearchParams(location.search)
    params.set('profiles', values.join())

    history.push(`/search?${params.toString()}`)
  }

  const speciesHandler = () => {
    const checks = Array.from(
      document.querySelectorAll('#species-form input[type=checkbox]:checked'),
    )

    const values = checks.map((check) => check.value)

    setCheckedSpecies(values)

    const params = new URLSearchParams(location.search)
    params.set('species', values.join())

    history.push(`/search?${params.toString()}`)
  }

  const deliveryHandler = (value) => {
    setDeliveryTime(value)

    const params = new URLSearchParams(location.search)
    params.set('delivery', value)

    history.push(`/search?${params.toString()}`)
  }

  const thicknessHandler = (lowValue, highValue) => {
    setLowThickness(lowValue)
    setHighThickness(highValue)

    const params = new URLSearchParams(location.search)
    params.set('lowThickness', lowValue)
    params.set('highThickness', highValue)

    history.push(`/search?${params.toString()}`)
  }

  const widthHandler = (lowValue, highValue) => {
    setLowWidth(lowValue)
    setHighWidth(highValue)

    const params = new URLSearchParams(location.search)
    params.set('lowWidth', lowValue)
    params.set('highWidth', highValue)

    history.push(`/search?${params.toString()}`)
  }

  const typeHandler = (category) => {
    setCheckedTypes([category])

    const params = new URLSearchParams(location.search)
    params.set('types', category)

    history.push(`/search?${params.toString()}`)
  }

  return (
    <>
      <Meta
        title={`Zoekresultaten voor '${new URLSearchParams(location.search).get(
          'q',
        )}'`}
        description={`Bekijk de zoekresultaten voor ${new URLSearchParams(
          location.search,
        )} op onze website en vind de informatie die je zoekt.`}
      />
      {error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Container>
          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h2 style={{ marginTop: '29px', marginBottom: '1rem' }}>
                Zoek{count === 1 ? 'resultaat' : 'resultaten'} ({count})
              </h2>
            </Col>
          </Row>
          <div className="d-flex justify-content-end align-items-center mb-3">
            <div
              className="text-right text-end"
              style={{
                color: '#884825',
                fontSize: '20px',
                lineHeight: '20px',
                fontWeight: 700,
              }}
            >
              Sortering:{' '}
              <Form.Select
                style={{ display: 'inline', width: 'auto' }}
                aria-label="Selecteer product sortering"
                onChange={(e) => sortHandler(Number(e.target.value))}
                value={sort}
                id="sort-select"
              >
                <option value="0">Nieuwste</option>
                <option value="1">Oudste</option>
                <option value="2">Prijs (laag - hoog)</option>
                <option value="3">Prijs (hoog - laag)</option>
              </Form.Select>
            </div>
          </div>
          <Row style={{ marginBottom: '86px' }}>
            <Col md={3}>
              <Nav
                className="flex-column d-none d-md-flex"
                id="category-selector"
              >
                <div id="nav-title-category">Categorieën</div>
                <Nav.Item>
                  <Nav.Link
                    style={
                      'Vlonderplanken' === checkedTypes[0]
                        ? {
                            fontWeight: 700,
                          }
                        : {}
                    }
                    onClick={() => typeHandler('Vlonderplanken')}
                  >
                    Vlonderplanken
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={
                      'Onderregels' === checkedTypes[0]
                        ? {
                            fontWeight: 700,
                          }
                        : {}
                    }
                    onClick={() => typeHandler('Onderregels')}
                  >
                    Onderregels
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={
                      'Palen' === checkedTypes[0]
                        ? {
                            fontWeight: 700,
                          }
                        : {}
                    }
                    onClick={() => typeHandler('Palen')}
                  >
                    Palen
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Levertijd</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group controlId="deliveryTime" className="mt-2">
                        {Array.from(deliveryOptions.entries()).map(
                          ([days, deliveryOption]) => (
                            <Form.Check
                              key={days}
                              type="radio"
                              label={deliveryOption}
                              name="deliveryTime"
                              value={days}
                              defaultChecked={days === deliveryTime}
                              onChange={(e) =>
                                deliveryHandler(Number(e.target.value))
                              }
                            ></Form.Check>
                          ),
                        )}
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Profiel</Accordion.Header>
                  <Accordion.Body>
                    <Form onChange={profileHandler} id="profiles-form">
                      <Form.Group controlId="productProfile" className="mt-2">
                        {config.productProfiles.map((profile) => (
                          <Form.Check
                            key={profile.toLowerCase()}
                            type="checkbox"
                            label={profile}
                            name="productProfile"
                            value={profile}
                            defaultChecked={checkedProfiles.includes(profile)}
                          ></Form.Check>
                        ))}
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Houtsoort</Accordion.Header>
                  <Accordion.Body>
                    <Form onChange={speciesHandler} id="species-form">
                      <Form.Group controlId="species" className="mt-2">
                        {config.species.map((species_) => (
                          <Form.Check
                            key={species_.toLowerCase()}
                            type="checkbox"
                            label={species_}
                            name="species"
                            value={species_}
                            defaultChecked={checkedSpecies.includes(species_)}
                          ></Form.Check>
                        ))}
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Dikte</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group controlId="thickness" className="mt-2">
                        {Array.from(thicknessOptions.entries()).map(
                          ([label, thicknessOption]) => (
                            <Form.Check
                              key={label.toLowerCase()}
                              type="radio"
                              label={label}
                              name="thickness"
                              value={thicknessOption}
                              defaultChecked={
                                thicknessOption[0] === lowThickness &&
                                thicknessOption[1] === highThickness
                              }
                              onChange={(e) => {
                                const values = e.target.value.split(',')
                                thicknessHandler(values[0], values[1])
                              }}
                            ></Form.Check>
                          ),
                        )}
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Breedte</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group controlId="width" className="mt-2">
                        {Array.from(widthOptions.entries()).map(
                          ([label, widthOption]) => (
                            <Form.Check
                              key={label.toLowerCase()}
                              type="radio"
                              label={label}
                              name="width"
                              value={widthOption}
                              defaultChecked={
                                widthOption[0] === lowWidth &&
                                widthOption[1] === highWidth
                              }
                              onChange={(e) => {
                                const values = e.target.value.split(',')
                                widthHandler(values[0], values[1])
                              }}
                            ></Form.Check>
                          ),
                        )}
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col md={9}>
              <Row className="gy-4">
                {loading ? (
                  <Loader />
                ) : (
                  products.map((product) => (
                    <Col
                      key={product._id}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={4}
                      className="align-items-stretch d-flex"
                    >
                      <Product product={product} />
                    </Col>
                  ))
                )}
              </Row>
              <Paginate pages={pages} page={page} />
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

export default SearchResultScreen
