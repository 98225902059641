import React, { useEffect, useState } from 'react'
import DOMPurify from 'dompurify'
import '../css/Description.css'

const Description = ({ description }) => {
  const [readMore, setReadMore] = useState(false)
  const [truncated, setTruncated] = useState(false)

  useEffect(() => {
    const desc = document.getElementById('product-description')

    if (desc) {
      const descHeight = desc.clientHeight
      if (descHeight > 300) {
        setTruncated(true)
      } else {
        setTruncated(false)
        setReadMore(true)
      }
    }
  }, [description])

  return (
    <>
      <div
        id="product-description-container"
        className={readMore ? 'expanded' : 'collapsed'}
        style={{ marginBottom: truncated ? '0' : '3rem' }}
      >
        <div
          id="product-description"
          dangerouslySetInnerHTML={{
            __html: ((desc) => DOMPurify.sanitize(desc))(description),
          }}
        ></div>
        <div
          id="product-description-blur"
          className={readMore ? 'expanded' : 'collapsed'}
        ></div>
      </div>
      {truncated && (
        <div
          id="product-description-button"
          className="btn-sm py-2 px-4"
          onClick={() => setReadMore(!readMore)}
        >
          {readMore ? (
            <i className="fa-solid fa-chevron-up"></i>
          ) : (
            <i className="fa-solid fa-chevron-down"></i>
          )}
        </div>
      )}
    </>
  )
}

export default Description
