import axios from 'axios'
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_NEWEST_REQUEST,
  PRODUCT_NEWEST_SUCCESS,
  PRODUCT_NEWEST_FAIL,
  PRODUCT_BESTSELLERS_REQUEST,
  PRODUCT_BESTSELLERS_FAIL,
  PRODUCT_BESTSELLERS_SUCCESS,
} from '../constants/productConstants'
import { logout } from './userActions'

export const listProducts =
  (
    query,
    page,
    sort,
    deliveryTime,
    productTypes,
    profiles,
    species,
    lowPrice,
    highPrice,
    lowLength,
    highLength,
    lowWidth,
    highWidth,
    lowThickness,
    highThickness,
    includeAllProducts = false,
  ) =>
  async (dispatch) => {
    const search = new URLSearchParams()

    query && search.append('q', query)
    page && search.append('page', page)
    sort && search.append('sort', sort)
    deliveryTime && search.append('delivery', deliveryTime)
    productTypes && search.append('types', productTypes.join())
    profiles && search.append('profiles', profiles.join())
    species && search.append('species', species.join())
    lowPrice && search.append('lowPrice', lowPrice)
    highPrice && search.append('highPrice', highPrice)
    lowLength && search.append('lowLength', lowLength)
    highLength && search.append('highLength', highLength)
    lowWidth && search.append('lowWidth', lowWidth)
    highWidth && search.append('highWidth', highWidth)
    lowThickness && search.append('lowThickness', lowThickness)
    highThickness && search.append('highThickness', highThickness)
    includeAllProducts && search.append('includeAll', includeAllProducts)

    try {
      dispatch({ type: PRODUCT_LIST_REQUEST })

      const { data } = await axios.get(`/api/products?${search.toString()}`)

      dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/products/${id}`)

    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/products/${id}`, config)

    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }

    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload: message,
    })
  }
}

export const createProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post('/api/products', product, config)

    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }

    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload: message,
    })
  }
}

export const updateProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/products/${product._id}`,
      product,
      config,
    )

    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    })

    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }

    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload: message,
    })
  }
}

export const listNewestArrivals = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_NEWEST_REQUEST })

    const { data } = await axios.get('/api/products/newest')

    dispatch({ type: PRODUCT_NEWEST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_NEWEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listBestsellers = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_BESTSELLERS_REQUEST })

    const { data } = await axios.get('/api/products/bestsellers')

    dispatch({ type: PRODUCT_BESTSELLERS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_BESTSELLERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
