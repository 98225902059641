import React, { useState, useEffect } from 'react'
import Spinner from 'react-loader-spinner'

const Loader = ({ delay = 750 }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), delay)
    return () => clearTimeout(timer)
  })

  return show ? (
    <Spinner
      type="ThreeDots"
      color="#3B2116"
      style={{
        width: '100px',
        height: '100px',
        margin: 'auto',
        display: 'block',
      }}
    >
      <span className="sr-only">Laden...</span>
    </Spinner>
  ) : (
    <></>
  )
}

export default Loader
