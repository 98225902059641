import React from 'react'
import { Offcanvas, Row, Col, Image } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import '../css/CollapsedOffcanvas.css'

const CategoryLink = ({ name, image, url, setShow }) => {
    return (
        <LinkContainer to={url} onClick={() => setShow(false)}>
            <div className="category-link">
                <Row>
                    <Col xs="auto">
                        <Image src={image} width={88} height={66} />
                    </Col>
                    <Col style={{ alignContent: 'center' }}>{name}</Col>
                    <Col
                        xs="auto"
                        style={{ alignContent: 'center' }}
                        className="pe-5"
                    >
                        <i className="fa-solid fa-chevron-right"></i>
                    </Col>
                </Row>
            </div>
        </LinkContainer>
    )
}

const OtherLink = ({ name, url, setShow }) => {
    return (
        <div className="offcanvas-category-link">
            <LinkContainer to={url} onClick={() => setShow(false)}>
                <div id="offcanvas-other-link-container">
                    <Row>
                        <Col style={{ alignContent: 'center' }}>{name}</Col>
                        <Col
                            xs="auto"
                            style={{ alignContent: 'center' }}
                            className="pe-5"
                        >
                            <i className="fa-solid fa-chevron-right"></i>
                        </Col>
                    </Row>
                </div>
            </LinkContainer>
        </div>
    )
}

const CollapsedOffcanvas = ({ show, setShow, categories }) => {
    return (
        <div>
            <Offcanvas show={show} onHide={() => setShow(false)}>
                <Offcanvas.Header id="collapsed-offcanvas-header" closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body id="collapsed-offcanvas-body">
                    <Row>
                        {categories &&
                            categories.map((category, index) => (
                                <div className="offcanvas-category-link">
                                    <CategoryLink
                                        key={index}
                                        name={category.name}
                                        image={category.image}
                                        url={category.url}
                                        setShow={setShow}
                                    />
                                </div>
                            ))}
                    </Row>
                    <Row
                        id="collapsed-offcanvas-border"
                        style={{
                            height: '2rem',
                            backgroundColor: 'hsl(240, 4%, 95%)',
                        }}
                    >
                        <p></p>
                    </Row>
                    <Row>
                        <OtherLink
                            name="Over ons"
                            url="/about-us"
                            setShow={setShow}
                        />
                        <OtherLink name="FAQ" url="/faq" setShow={setShow} />
                        <OtherLink
                            name="Contact"
                            url="/contact"
                            setShow={setShow}
                        />
                    </Row>
                    <Row
                        id="collapsed-offcanvas-border"
                        style={{
                            height: '2rem',
                            backgroundColor: 'hsl(240, 4%, 95%)',
                        }}
                    >
                        <p></p>
                    </Row>
                    <Row>
                        <OtherLink
                            name="Mijn account"
                            url="/profile"
                            setShow={setShow}
                        />
                        <OtherLink
                            name="Winkelwagen"
                            url="/cart"
                            setShow={setShow}
                        />
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default CollapsedOffcanvas
