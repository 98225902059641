import React from 'react'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../actions/userActions'
import { useDispatch } from 'react-redux'

const AccountNav = () => {
  const dispatch = useDispatch()

  return (
    <Nav variant="pills" className="flex-column pb-4">
      <LinkContainer to="/profile">
        <Nav.Link>
          <i className="fas fa-user me-2"></i>Mijn gegevens
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to="/orders">
        <Nav.Link>
          <i className="fa-solid fa-box me-2"></i>Mijn bestellingen
        </Nav.Link>
      </LinkContainer>
      <LinkContainer to="/addresses">
        <Nav.Link>
          <i className="fa-solid fa-address-book me-2"></i>Mijn adressen
        </Nav.Link>
      </LinkContainer>
      <Nav.Link onClick={() => dispatch(logout())}>
        <i className="fas fa-sign-out-alt me-2"></i>Uitloggen
      </Nav.Link>
    </Nav>
  )
}

export default AccountNav
