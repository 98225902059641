import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useLocation } from 'react-router-dom'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { listUsers, deleteUser } from '../actions/userActions'
import Meta from '../components/Meta'
import DeleteModal from '../components/DeleteModal'
import SearchBarList from '../components/SearchBarList'
import '../css/UserListScreen.css'

const UserListScreen = ({ history }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const pageNumber = params.get('page') ?? 0

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [userId, setUserId] = useState('')
  const [userName, setUserName] = useState('')
  const [query, setQuery] = useState('')

  // @ts-ignore
  const userList = useSelector((state) => state.userList)
  const { loading, error, users, pages, page, count } = userList

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // @ts-ignore
  const userDelete = useSelector((state) => state.userDelete)
  const { success: successDelete, loading: loadingDelete } = userDelete

  useEffect(() => {
    const parameters = new URLSearchParams(location.search)
    const queryParameter = parameters.get('q') || ''

    setQuery(queryParameter)

    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers(queryParameter, Number(pageNumber)))
    } else {
      history.push('/login')
    }
  }, [dispatch, userInfo, history, successDelete, pageNumber, location.search])

  const deleteHandler = (id, name) => {
    setUserId(id)
    setUserName(name)

    setShow(true)
  }

  const handleClose = () => setShow(false)
  const handleConfirm = (id) => {
    dispatch(deleteUser(id))
    setShow(false)
  }

  const searchHandler = (e) => {
    e.preventDefault()

    const savedQuery = query.trim()

    if (savedQuery) {
      history.push(`/admin/users?q=${savedQuery}`)
    } else {
      history.push('/admin/users')
    }
  }

  return (
    <>
      <Meta title="Admin | Gebruikers" />
      <Container
        style={{
          paddingTop: '29px',
        }}
      >
        <h2>Gebruikers {count ? `(${count})` : ''}</h2>
      </Container>
      {loading ? (
        <Loader delay={0} />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Container>
          <div id="search-user-container">
            <Row className="align-items-center">
              <Col
                className="d-flex mb-3 align-items-center justify-content-center"
                md={6}
              >
                <SearchBarList
                  query={query}
                  setQuery={setQuery}
                  placeholder="Zoek een gebruiker"
                  searchHandler={searchHandler}
                />
              </Col>
            </Row>
          </div>
          <div style={{ marginBottom: '86px' }}>
            <Table striped bordered hover responsive>
              <thead style={{ borderBottom: '2px solid #3B2116' }}>
                <tr>
                  <th>ID</th>
                  <th>Naam</th>
                  <th>E-mail</th>
                  <th>Admin</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user._id}>
                    <td>{user._id}</td>
                    <td>{user.name}</td>
                    <td>
                      <a href={`mailto:${user.email}`}>{user.email}</a>
                    </td>
                    <td>
                      {user.isAdmin ? (
                        <i
                          className="fas fa-check"
                          style={{ color: 'green' }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-times"
                          style={{ color: 'red' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      <Row>
                        <Col className="d-flex align-items-center justify-content-center">
                          <LinkContainer
                            to={`/admin/user/${user._id}/edit`}
                            style={{
                              backgroundColor: 'inherit',
                              border: 'none',
                              color: '#552E19',
                            }}
                          >
                            <Button variant="light" className="btn-sm">
                              <i className="fas fa-edit"></i>
                            </Button>
                          </LinkContainer>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-center">
                          <Button
                            disabled={userInfo._id === user._id}
                            variant="light"
                            className="btn-sm"
                            style={{
                              color: '#A02323',
                              backgroundColor: 'inherit',
                              border: 'none',
                            }}
                            onClick={() => deleteHandler(user._id, user.name)}
                          >
                            <i className="fas fa-trash"></i>
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Paginate pages={pages} page={page} />
          </div>
          <DeleteModal
            isVisible={show}
            handleClose={handleClose}
            title="Verwijder gebruiker"
            body={`Weet je zeker dat je gebruiker "${userName}" wil verwijderen?`}
            handleConfirm={async () => handleConfirm(userId)}
            loading={loadingDelete}
          />
        </Container>
      )}
    </>
  )
}

export default UserListScreen
