import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { listProducts, deleteProduct } from '../actions/productActions'
import Meta from '../components/Meta'
import DeleteModal from '../components/DeleteModal'
import SearchBarList from '../components/SearchBarList'
import { formatPrice } from '../utils/currency'

const ProductListScreen = ({ history }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const pageNumber = params.get('page') || 0

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [productId, setProductId] = useState('')
  const [productName, setProductName] = useState('')
  const [query, setQuery] = useState('')

  // @ts-ignore
  const productList = useSelector((state) => state.productList)
  const { loading, error, products, pages, page, count } = productList

  // @ts-ignore
  const productDelete = useSelector((state) => state.productDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    const parameters = new URLSearchParams(location.search)
    const queryParameter = parameters.get('q') || ''

    setQuery(queryParameter)

    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }

    dispatch(
      listProducts(
        queryParameter,
        pageNumber,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true, // include all products (even those without lengths)
      ),
    )
  }, [dispatch, userInfo, history, successDelete, pageNumber, location.search])

  const deleteHandler = (id, name) => {
    setProductId(id)
    setProductName(name)

    setShow(true)
  }
  const handleClose = () => setShow(false)

  const handleConfirm = (id) => {
    dispatch(deleteProduct(id))
    setShow(false)
  }

  const searchHandler = (e) => {
    e.preventDefault()

    const savedQuery = query.trim()

    if (savedQuery) {
      history.push('/admin/products?q=' + savedQuery)
    } else {
      history.push('/admin/products')
    }
  }

  return (
    <>
      <Meta title="Admin | Producten" />
      <Container
        style={{
          paddingTop: '29px',
        }}
      >
        <h2>Producten {count ? `(${count})` : ''}</h2>
      </Container>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loading ? (
        <Loader delay={0} />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Container>
          <div id="search-user-container">
            <Row className="align-items-center mb-3 gy-2">
              <Col
                className="d-flex align-items-center justify-content-center"
                md={6}
              >
                <SearchBarList
                  query={query}
                  setQuery={setQuery}
                  placeholder="Zoek een product"
                  searchHandler={searchHandler}
                />
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-end align-items-center"
              >
                <LinkContainer to="/admin/product/add" className="mx-0">
                  <Button className="btn-sm">
                    <i className="fas fa-plus me-3"></i>Nieuw product
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
          </div>
          <div style={{ marginBottom: '86px' }}>
            <Table striped bordered hover responsive>
              <thead style={{ borderBottom: '2px solid #3B2116' }}>
                <tr>
                  <th>ID</th>
                  <th>Naam</th>
                  <th>Prijs</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product._id}>
                    <td>{product.productId}</td>
                    <td>
                      <Link to={`/product/${product._id}`}>
                        {product.title}
                      </Link>
                    </td>
                    <td>
                      {product.lengths.length > 0
                        ? formatPrice(product.lengths[0].price)
                        : ''}
                    </td>
                    <td>{product.productType}</td>
                    <td>
                      <Row>
                        <Col className="d-flex align-items-center justify-content-center">
                          <LinkContainer
                            to={`/admin/product/${product._id}/edit`}
                            style={{
                              backgroundColor: 'inherit',
                              border: 'none',
                              color: '#552E19',
                            }}
                          >
                            <Button variant="light" className="btn-sm">
                              <i className="fas fa-edit"></i>
                            </Button>
                          </LinkContainer>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-center">
                          <Button
                            variant="light"
                            className="btn-sm"
                            style={{
                              color: '#A02323',
                              backgroundColor: 'inherit',
                              border: 'none',
                            }}
                            onClick={() =>
                              deleteHandler(product._id, product.title)
                            }
                          >
                            <i className="fas fa-trash"></i>
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Paginate pages={pages} page={page} />
            <DeleteModal
              isVisible={show}
              handleClose={handleClose}
              title="Verwijder product"
              body={`Weet je zeker dat je product "${productName}" wil verwijderen?`}
              handleConfirm={async () => await handleConfirm(productId)}
              loading={loadingDelete}
            />
          </div>
        </Container>
      )}
    </>
  )
}

export default ProductListScreen
