import React from 'react'
import { Offcanvas, Container, Row, Col, Image } from 'react-bootstrap'
import '../css/CategoryOffcanvas.css'
import { LinkContainer } from 'react-router-bootstrap'

const CategoryLink = ({ name, image, url, setShow }) => {
  return (
    <LinkContainer to={url} onClick={() => setShow(false)}>
      <div className="category-link">
        <Row>
          <Col xs="auto">
            <Image src={image} width={88} height={66} />
          </Col>
          <Col style={{ alignContent: 'center' }}>{name}</Col>
          <Col xs="auto" style={{ alignContent: 'center' }} className="pe-5">
            <i className="fa-solid fa-chevron-right chevron-on-hover"></i>
          </Col>
        </Row>
      </div>
    </LinkContainer>
  )
}

const CategoryOffcanvas = ({ show, setShow, data }) => {
  return (
    <Offcanvas
      show={show}
      placement={'top'}
      onMouseEnter={() => setShow(true)}
      onShow={() => {
        const offcanvas = document.querySelector('.offcanvas')
        const subheader = document.querySelector('#subheader')
        const rect = subheader.getBoundingClientRect()
        offcanvas.style.top = `${rect.bottom}px`
      }}
      onMouseLeave={() => setShow(false)}
    >
      <Offcanvas.Body>
        <Container
          style={{
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
          }}
        >
          <Col>
            <Row className="g-3">
              {data &&
                data.map((item, index) => (
                  <Col key={index} md={12} lg={6}>
                    <CategoryLink
                      name={item.name}
                      image={item.image}
                      url={item.url}
                      setShow={setShow}
                    />
                  </Col>
                ))}
            </Row>
          </Col>
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default CategoryOffcanvas
