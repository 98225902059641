export const LENGTH_LIST_REQUEST = 'LENGTH_LIST_REQUEST'
export const LENGTH_LIST_SUCCESS = 'LENGTH_LIST_SUCCESS'
export const LENGTH_LIST_FAIL = 'LENGTH_LIST_FAIL'
export const LENGTH_LIST_RESET = 'LENGTH_LIST_RESET'

export const LENGTH_DETAILS_REQUEST = 'LENGTH_DETAILS_REQUEST'
export const LENGTH_DETAILS_SUCCESS = 'LENGTH_DETAILS_SUCCESS'
export const LENGTH_DETAILS_FAIL = 'LENGTH_DETAILS_FAIL'
export const LENGTH_DETAILS_RESET = 'LENGTH_DETAILS_RESET'

export const LENGTH_CREATE_REQUEST = 'LENGTH_CREATE_REQUEST'
export const LENGTH_CREATE_SUCCESS = 'LENGTH_CREATE_SUCCESS'
export const LENGTH_CREATE_FAIL = 'LENGTH_CREATE_FAIL'
export const LENGTH_CREATE_RESET = 'LENGTH_CREATE_RESET'

export const LENGTH_DELETE_REQUEST = 'LENGTH_DELETE_REQUEST'
export const LENGTH_DELETE_SUCCESS = 'LENGTH_DELETE_SUCCESS'
export const LENGTH_DELETE_FAIL = 'LENGTH_DELETE_FAIL'
export const LENGTH_DELETE_RESET = 'LENGTH_DELETE_RESET'

export const LENGTH_UPDATE_REQUEST = 'LENGTH_UPDATE_REQUEST'
export const LENGTH_UPDATE_SUCCESS = 'LENGTH_UPDATE_SUCCESS'
export const LENGTH_UPDATE_FAIL = 'LENGTH_UPDATE_FAIL'
export const LENGTH_UPDATE_RESET = 'LENGTH_UPDATE_RESET'
