import React, { useEffect, useState } from 'react'
import { Row, Col, ListGroup, Modal, Button, Container } from 'react-bootstrap'
import AccountNav from '../components/AccountNav'
import { useSelector, useDispatch } from 'react-redux'
import Message from '../components/Message'
import SavedUserAddress from '../components/SavedUserAddress'
import { getUserDetails } from '../actions/userActions'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import { updateUserProfile } from '../actions/userActions'
import DeleteModal from '../components/DeleteModal'
import NewAddressForm from '../components/NewAddressForm'

const AddressScreen = () => {
  const dispatch = useDispatch()

  const [addModalVisible, setAddModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [addressIndex, setAddressIndex] = useState(-1)
  const [edit, setEdit] = useState(false)

  // @ts-ignore
  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  // @ts-ignore
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const {
    success,
    loading: updateLoading,
    error: updateError,
  } = userUpdateProfile

  useEffect(() => {
    if (success) {
      setAddModalVisible(false)

      dispatch({ type: 'USER_UPDATE_PROFILE_RESET' })
      dispatch(getUserDetails('profile'))
    }

    if (!user.addresses) {
      dispatch(getUserDetails('profile'))
    }
  }, [user.addresses, success, updateLoading, dispatch])

  const handleConfirm = async (index) => {
    const addresses = user.addresses.filter((_, i) => i !== index)
    await dispatch(updateUserProfile({ id: user._id, addresses }))

    setDeleteModalVisible(false)
  }

  return (
    <>
      <Meta title="Mijn adressen" />
      <Container>
        <Row>
          <Col lg={3}></Col>
          <Col lg={6}>
            <h2 style={{ marginTop: '29px' }}>Mijn adressen</h2>
          </Col>
        </Row>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Row>
            <Col lg={3}>
              <AccountNav />
            </Col>
            <Col lg={6}>
              {!user ? (
                <></>
              ) : !user.addresses || user.addresses === 0 ? (
                <Message variant="warning">
                  Er zijn nog geen adressen toegevoegd
                </Message>
              ) : (
                <>
                  {updateError && (
                    <Message variant="danger">{updateError}</Message>
                  )}
                  <ListGroup as="ul" variant="flush">
                    {user.addresses.map((address, key) => (
                      <ListGroup.Item as="li" key={key}>
                        <SavedUserAddress
                          address={address}
                          deleteHandler={() => {
                            setAddressIndex(key)
                            setDeleteModalVisible(true)
                          }}
                          editHandler={() => {
                            setEdit(true)

                            setAddressIndex(key)
                            setAddModalVisible(true)
                          }}
                        />
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </>
              )}
              {!user.addresses ||
                (user.addresses && user.addresses.length < 3 && (
                  <Button
                    className="my-3 w-100"
                    onClick={() => setAddModalVisible(true)}
                    variant="outline-primary"
                  >
                    <i className="fas fa-plus me-3"></i>Nieuw adres
                  </Button>
                ))}
            </Col>
          </Row>
        )}
        <DeleteModal
          isVisible={deleteModalVisible}
          handleClose={() => setDeleteModalVisible(false)}
          title="Verwijder adres"
          body="Weet je zeker dat je dit adres wil verwijderen?"
          handleConfirm={async () => await handleConfirm(addressIndex)}
          loading={updateLoading}
        />
        <Modal
          show={addModalVisible}
          onHide={() => {
            setEdit(false)
            setAddModalVisible(false)
          }}
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title style={{ color: '#552E19' }}>
              {edit ? 'Bewerk adres' : 'Voeg nieuw adres toe'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {edit ? (
              <NewAddressForm
                index={addressIndex}
                editCompanyName={user.addresses[addressIndex].companyName}
                editFirstName={user.addresses[addressIndex].firstName}
                editLastName={user.addresses[addressIndex].lastName}
                editPostalCode={user.addresses[addressIndex].postalCode}
                editAddress={user.addresses[addressIndex].address}
                editAddition={user.addresses[addressIndex].addition}
                editCity={user.addresses[addressIndex].city}
                editCountry={user.addresses[addressIndex].country}
              />
            ) : (
              <NewAddressForm />
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </>
  )
}

export default AddressScreen
