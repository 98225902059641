import React, { useEffect, useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useLocation } from 'react-router-dom'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listOrders } from '../actions/orderActions'
import Paginate from '../components/Paginate'
import { numericDate } from '../utils/dates'
import ShippingStatus from '../components/ShippingStatus'
import { formatPrice } from '../utils/currency'
import Meta from '../components/Meta'
import SearchBarList from '../components/SearchBarList'

const OrderListScreen = ({ history }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const [query, setQuery] = useState('')

  const pageNumber = params.get('page') || 0

  const dispatch = useDispatch()

  // @ts-ignore
  const orderList = useSelector((state) => state.orderList)
  const { loading, error, orders, pages, page, count } = orderList

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    const parameters = new URLSearchParams(location.search)
    const queryParameter = parameters.get('q') || ''

    setQuery(queryParameter)

    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders(queryParameter, pageNumber))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, pageNumber, location.search])

  const searchHandler = (e) => {
    e.preventDefault()

    const savedQuery = query.trim()

    if (savedQuery) {
      history.push(`/admin/orders?q=${savedQuery}`)
    } else {
      history.push('/admin/orders')
    }
  }

  return (
    <>
      <Meta title="Admin | Bestellingen" />
      <Container
        style={{
          paddingTop: '29px',
        }}
      >
        <h2>Bestellingen {count ? `(${count})` : ''}</h2>
      </Container>
      {loading ? (
        <Loader delay={0} />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Container>
          <div id="search-user-container">
            <Row className="h-100 align-items-center g-0">
              <Col
                className="d-flex mb-3 align-items-center justify-content-center"
                md={6}
              >
                <SearchBarList
                  query={query}
                  setQuery={setQuery}
                  placeholder="Zoek een bestelling"
                  searchHandler={searchHandler}
                />
              </Col>
            </Row>
          </div>
          <div style={{ marginBottom: '86px' }}>
            <Table striped bordered hover responsive>
              <thead style={{ borderBottom: '2px solid #3B2116' }}>
                <tr>
                  <th>Bestelnummer</th>
                  <th>Gebruiker</th>
                  <th>Datum</th>
                  <th>Totaalprijs</th>
                  <th>Betaald</th>
                  <th>Verzonden</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order._id}>
                    <td>{order.orderId}</td>
                    <td>{order.user && order.user.name}</td>
                    <td>{numericDate(order.createdAt)}</td>
                    <td>{formatPrice(order.totalPrice)}</td>
                    <td>
                      {order.isPaid ? (
                        <i
                          className="fas fa-check"
                          style={{ color: 'green' }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-times"
                          style={{ color: 'red' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      <ShippingStatus order={order} />
                    </td>
                    <td>
                      <LinkContainer
                        to={`/order/${order._id}`}
                        style={{ backgroundColor: 'inherit', border: 'none' }}
                      >
                        <Button variant="light" className="btn-sm">
                          Details
                        </Button>
                      </LinkContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Paginate pages={pages} page={page} />
          </div>
        </Container>
      )}
    </>
  )
}

export default OrderListScreen
