import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Table, Spinner, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import ShippingStatus from '../components/ShippingStatus'
import { listUserOrders } from '../actions/orderActions'
import { numericDate } from '../utils/dates'
import { formatPrice } from '../utils/currency'
import AccountNav from '../components/AccountNav'
import Meta from '../components/Meta'

const OrderListUserScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderListUser = useSelector((state) => state.orderListUser)
  const { orders, page, pages, error, loading } = orderListUser

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      dispatch(listUserOrders())
    }
  }, [dispatch, history, userInfo])

  const showMoreOrdersHandler = (e) => {
    e.preventDefault()

    dispatch(listUserOrders(page + 1))
  }

  return (
    <>
      <Meta title="Mijn bestellingen" />
      <Container>
        <Row>
          <Col lg={3}></Col>
          <Col lg={6}>
            <h2 style={{ marginTop: '29px' }}>Mijn bestellingen</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <AccountNav />
          </Col>
          <Col lg={9}>
            {loading ? (
              <Loader delay={0} />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <>
                <Table striped bordered hover responsive>
                  <thead style={{ borderBottom: '2px solid #3B2116' }}>
                    <tr>
                      <th>Bestelnummer</th>
                      <th>Datum</th>
                      <th>Totaal</th>
                      <th>Betaald</th>
                      <th>Verzonden</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => (
                      <tr key={order._id}>
                        <td>{order.orderId}</td>
                        <td>{numericDate(order.createdAt)}</td>
                        <td>{formatPrice(order.totalPrice)}</td>
                        <td>
                          {order.isPaid ? (
                            numericDate(order.paidAt)
                          ) : (
                            <i
                              className="fas fa-times"
                              style={{ color: 'red' }}
                            ></i>
                          )}
                        </td>
                        <td>
                          <ShippingStatus order={order} />
                        </td>
                        <td>
                          <LinkContainer
                            to={`/order/${order._id}`}
                            style={{
                              backgroundColor: 'inherit',
                              border: 'none',
                            }}
                          >
                            <Button className="btn-sm" variant="light">
                              Details
                            </Button>
                          </LinkContainer>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {page < pages && (
                  <Row>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="mx-auto"
                      style={{ width: 'auto' }}
                      onClick={showMoreOrdersHandler}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            animation="border"
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-1"
                          />
                          Laad meer
                        </>
                      ) : (
                        <>
                          <i className="fas fa-angle-double-down me-3"></i>Laad
                          meer
                        </>
                      )}
                    </Button>
                  </Row>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default OrderListUserScreen
