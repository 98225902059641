import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Row,
  Col,
  Image,
  Breadcrumb,
  ListGroup,
  Table,
  Button,
  Container,
  Form,
  Carousel,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import { listProductDetails } from '../actions/productActions'
import { isEmpty, roundToTwoDecimals, addDecimals } from '../utils/utils'
import '../css/ProductScreen.css'
import { formatPrice } from '../utils/currency'
import Product from '../components/Product'
import { formatCM } from '../utils/utils'
import axios from 'axios'
import { bulkLocalAddToCart } from '../actions/cartActions'
import { CART_DETAILS_SUCCESS } from '../constants/cartConstants'
import ButtonSpinner from '../components/ButtonSpinner'
import calculatePricePerMeter from '../utils/calculatePricePerMeter'
import Description from '../components/Description'

const ProductScreen = ({ history, match, location }) => {
  const dispatch = useDispatch()

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [cartLoading, setCartLoading] = useState(false)
  const [cartError, setCartError] = useState('')

  // @ts-ignore
  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(listProductDetails(match.params.id))
  }, [dispatch, match])

  const initialInputValues = Array.from({ length: 25 }, () => 0)
  const [inputValues, setInputValues] = useState(initialInputValues)

  const initialSurfaceAreaValues = Array.from({ length: 25 }, () => 0)
  const [surfaceAreaValues, setSurfaceAreaValues] = useState(
    initialSurfaceAreaValues,
  )

  const [carouselIndex, setCarouselIndex] = useState(0)

  const handleSelect = (selectedIndex) => {
    setCarouselIndex(selectedIndex)
  }

  const addToCartHandler = async () => {
    const items = []

    for (let i = 0; i < 25; i++) {
      const quantity = inputValues[i]

      if (inputValues[i] > 0) {
        let item
        if (userInfo) {
          item = {
            product: product._id,
            productLength: product.lengths[i]._id,
            qty: quantity,
          }
        } else {
          item = {
            product: product,
            productLength: product.lengths[i],
            qty: quantity,
          }
        }

        items.push(item)
      }
    }

    if (items.length === 0) return

    if (userInfo) {
      // logged in
      setCartLoading(true)

      try {
        const { data } = await axios.put(
          `/api/carts/${userInfo.cart}/addItems`,
          items,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          },
        )

        setCartLoading(false)

        dispatch({
          type: CART_DETAILS_SUCCESS,
          payload: data,
        })
      } catch (error) {
        setCartLoading(false)

        setCartError(error)
      }
    } else {
      // not logged in
      dispatch(bulkLocalAddToCart(items))
    }

    history.push('/cart')
  }

  const calculatePlankCount = (surfaceArea, plankWidth, plankLength) => {
    return Math.ceil(surfaceArea / ((plankWidth / 1000) * (plankLength / 1000)))
  }

  const calculateSurfaceArea = (width, length, quantity) => {
    return roundToTwoDecimals((width / 1000) * (length / 1000) * quantity)
  }

  return (
    <>
      <Meta
        title={product?.title}
        description={`${product?.title} * Veel lengtes beschikbaar * Profiteer van snelle en voordelige levering in Nederland en België * Al ons hout is FSC-gecertificeerd en duurzaam * Bestel nu en creëer uw droomvlonder!`}
      />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : !isEmpty(product) ? (
        <>
          <Container id="main-product-content">
            <Breadcrumb>
              <LinkContainer to="/">
                <Breadcrumb.Item className="active">
                  <i className="fas fa-home"></i>
                </Breadcrumb.Item>
              </LinkContainer>
              <LinkContainer to={`/c/${product.productType.toLowerCase()}`}>
                <Breadcrumb.Item className="active">
                  {product.productType}
                </Breadcrumb.Item>
              </LinkContainer>
              <LinkContainer to={location.pathname}>
                <Breadcrumb.Item active>{product.title}</Breadcrumb.Item>
              </LinkContainer>
            </Breadcrumb>
            <Row className="gy-5" style={{ marginBottom: '100px' }}>
              <Col lg={6}>
                <Row>
                  <Carousel activeIndex={carouselIndex} onSelect={handleSelect}>
                    {product.images.map((image, index) => (
                      <Carousel.Item key={index}>
                        <Image
                          style={{ width: '100%', height: 'auto' }}
                          src={image}
                          alt={product.title}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </Row>
                <Row className="mt-1 g-3 d-none d-md-flex">
                  {product.images.slice(1).map((image, index) => (
                    <Col md={4}>
                      <Image
                        onClick={() => {
                          handleSelect(index + 1)
                        }}
                        src={image}
                        alt={product.title}
                        fluid
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={6}>
                <h2 className="mb-0">{product.title}</h2>
                <div id="product-subtitle">
                  {product.thickness} x {product.width} x{' '}
                  {product.lengths.length > 0 ? product.lengths[0].value : 0} |
                  Productnummer {product.productId}
                </div>
                <Description description={product.description} />
                <div id="product-variants">
                  {
                    product.lengths.filter((length) => length.countInStock > 0)
                      .length
                  }{' '}
                  lengtes op voorraad
                </div>
                <div>
                  <Row className="gy-4">
                    <Col md={6}>
                      <div id="product-price-container">Per meter, vanaf</div>
                      <div>
                        <h2 id="product-price">
                          {formatPrice(
                            product.lengths.length > 0
                              ? calculatePricePerMeter(
                                product.lengths[0].price,
                                product.lengths[0].value,
                              )
                              : 0,
                          )}
                        </h2>
                        <span id="product-price-note-text"> incl. BTW</span>
                      </div>
                    </Col>
                    <Col
                      md={6}
                      className="justify-content-end align-items-end d-flex"
                    >
                      <Button
                        className="w-100"
                        variant="primary"
                        onClick={() => {
                          const targetElement = document.getElementById(
                            'product-lengths-container',
                          )
                          targetElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                          })
                        }}
                      >
                        Zie afmetingen
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>

          <Row className="g-0" style={{ marginBottom: '74px' }}>
            <div id="product-lengths-container">
              <Container>
                {product.lengths.length > 0 ? (
                  <Row>
                    <div id="product-lengths-title">Kies afmetingen</div>
                    <Table id="product-lengths-table" className="table-sm">
                      <thead>
                        <tr>
                          <th>lengte</th>
                          <th className="d-none d-md-table-cell">
                            prijs / stuk
                          </th>
                          <th className="d-table-cell d-md-none">p/s</th>
                          <th className="d-none d-md-table-cell">voorraad</th>
                          <th className="d-none d-md-table-cell">
                            oppervlakte (m2)
                          </th>
                          <th>aantal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {product.lengths.map((length, index) => (
                          <tr key={length._id}>
                            <td>{formatCM(length.value)} cm</td>
                            <td>{formatPrice(length.price)}</td>
                            <td className="d-none d-md-table-cell">
                              {length.countInStock === 0 ? (
                                <span style={{ color: '#EF6E1E' }}>Geen</span>
                              ) : (
                                <span>
                                  {length.countInStock}{' '}
                                  <span className="d-none d-md-inline-block">
                                    stuks
                                  </span>
                                </span>
                              )}
                            </td>
                            <td className="d-none d-md-table-cell">
                              <div className="d-flex align-items-center justify-content-center">
                                {length.countInStock !== 0 && (
                                  <Form.Control
                                    type="number"
                                    value={surfaceAreaValues[index]}
                                    min={0}
                                    step={0.1}
                                    max={calculateSurfaceArea(
                                      product.width,
                                      length.value,
                                      length.countInStock,
                                    )}
                                    onChange={(e) => {
                                      let value = parseFloat(e.target.value)

                                      const maxSurfaceArea =
                                        calculateSurfaceArea(
                                          product.width,
                                          length.value,
                                          length.countInStock,
                                        )

                                      if (value > maxSurfaceArea)
                                        value = maxSurfaceArea

                                      const newSurfaceAreaValues = [
                                        ...surfaceAreaValues,
                                      ]

                                      const finalValue = addDecimals(value)

                                      newSurfaceAreaValues[index] = finalValue
                                      setSurfaceAreaValues(newSurfaceAreaValues)

                                      const finalPlankCount = Math.min(
                                        calculatePlankCount(
                                          finalValue,
                                          product.width,
                                          length.value,
                                        ),
                                        length.countInStock,
                                      )

                                      const newInputValues = [...inputValues]
                                      newInputValues[index] = finalPlankCount
                                      setInputValues(newInputValues)
                                    }}
                                    disabled={length.countInStock === 0}
                                    style={{
                                      width: '7.5rem',
                                    }}
                                  ></Form.Control>
                                )}
                              </div>
                            </td>
                            <td className="d-flex align-items-center justify-content-center text-center">
                              {length.countInStock !== 0 && (
                                <Form.Control
                                  type="number"
                                  value={inputValues[index]}
                                  min={0}
                                  step={1}
                                  max={length.countInStock}
                                  onChange={(e) => {
                                    let value = parseInt(e.target.value, 10)

                                    if (value > length.countInStock)
                                      value = length.countInStock

                                    const newInputValues = [...inputValues]
                                    newInputValues[index] = value
                                    setInputValues(newInputValues)

                                    const surfaceArea = calculateSurfaceArea(
                                      product.width,
                                      length.value,
                                      value,
                                    )

                                    const newSurfaceAreaValues = [
                                      ...surfaceAreaValues,
                                    ]
                                    newSurfaceAreaValues[index] = surfaceArea
                                    setSurfaceAreaValues(newSurfaceAreaValues)
                                  }}
                                  disabled={length.countInStock === 0}
                                  style={{
                                    paddingLeft: '1.25rem',
                                  }}
                                ></Form.Control>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Row className="text-end d-flex justify-content-end align-items-end">
                      <Button
                        variant="primary"
                        onClick={() => addToCartHandler()}
                        disabled={cartLoading || product.lengths === 0}
                      >
                        {cartLoading ? <ButtonSpinner /> : 'In winkelwagen'}
                      </Button>
                    </Row>
                  </Row>
                ) : (
                  <></>
                )}
                <Row>
                  <div id="product-lengths-title">Specificaties</div>

                  <ListGroup className="px-0" id="product-specifications-list">
                    <ListGroup.Item>
                      <Row className="gy-5">
                        <Col className="spec-descriptor-text">Materiaal</Col>
                        <Col className="spec-value-text">
                          {product.material}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row className="gy-5">
                        <Col className="spec-descriptor-text">Houtsoort</Col>
                        <Col className="spec-value-text">{product.species}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row className="gy-5">
                        <Col className="spec-descriptor-text">Categorie</Col>
                        <Col className="spec-value-text">
                          {product.productType}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row className="gy-5">
                        <Col className="spec-descriptor-text">Kleur</Col>
                        <Col className="spec-value-text">{product.color}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row className="gy-5">
                        <Col className="spec-descriptor-text">Breedte</Col>
                        <Col className="spec-value-text">
                          {formatCM(product.width)} cm
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row className="gy-5">
                        <Col className="spec-descriptor-text">Dikte</Col>
                        <Col className="spec-value-text">
                          {formatCM(product.thickness)} cm
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Row>
              </Container>
            </div>
          </Row>

          {product.relatedProducts.length > 0 && (
            <Container>
              <div id="product-related-products-title">
                Mogelijk ook interessant voor u
              </div>
              <Row
                style={{ marginBottom: '65px' }}
                className="d-none d-md-flex gy-4"
              >
                {product.relatedProducts
                  .filter((product) => product.lengths.length > 0)
                  .map((product) => (
                    <Col
                      key={product._id}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                      className="align-items-stretch d-flex"
                    >
                      <Product product={product} />
                    </Col>
                  ))}
              </Row>
              {product.relatedProducts.length === 1 ? (
                <Row
                  style={{ marginBottom: '65px' }}
                  className="d-flex d-md-none"
                >
                  <Col>
                    <Product product={product.relatedProducts[0]} />
                  </Col>
                </Row>
              ) : (
                <Carousel
                  className="d-flex d-md-none"
                  style={{ marginBottom: '65px' }}
                >
                  {product.relatedProducts.map((product) => (
                    <Carousel.Item key={product._id}>
                      <Product product={product} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
            </Container>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default ProductScreen
