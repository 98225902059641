import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import SavedAddress from './SavedAddress'

const SavedUserAddress = ({ address, deleteHandler, editHandler }) => {
  return (
    <>
      <Row>
        <Col>
          <SavedAddress address={address} />
        </Col>
        <Col>
          <Button
            style={{
              color: '#A02323',
              backgroundColor: 'inherit',
              border: 'none',
            }}
            variant="light"
            className="float-end"
            onClick={deleteHandler}
          >
            <i className="fas fa-trash" />
          </Button>
          <Button
            style={{
              backgroundColor: 'inherit',
              border: 'none',
              color: '#552E19',
            }}
            variant="light"
            className="float-end"
            onClick={editHandler}
          >
            <i className="fas fa-edit" />
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default SavedUserAddress
