import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_RESET,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_LIST_USER_REQUEST,
  ORDER_LIST_USER_SUCCESS,
  ORDER_LIST_USER_FAIL,
  ORDER_LIST_USER_RESET,
  ORDER_LIST_SELLER_REQUEST,
  ORDER_LIST_SELLER_SUCCESS,
  ORDER_LIST_SELLER_FAIL,
  ORDER_LIST_SELLER_RESET,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_SHIP_REQUEST,
  ORDER_SHIP_SUCCESS,
  ORDER_SHIP_FAIL,
  ORDER_SHIP_RESET,
  ORDER_CANCEL_REQUEST,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_CANCEL_RESET,
} from '../constants/orderConstants'

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return {
        loading: true,
      }

    case ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      }
    case ORDER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const orderDetailsReducer = (
  state = { loading: true, orderItems: [], shippingAddress: {} }, // FIXME
  action,
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      }
    case ORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const orderListUserReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_USER_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ORDER_LIST_USER_SUCCESS:
      return {
        loading: false,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
        count: action.payload.count,
      }
    case ORDER_LIST_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_LIST_USER_RESET:
      return { orders: [] }
    default:
      return state
  }
}

export const orderListSellerReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_SELLER_REQUEST:
      return {
        loading: true,
      }

    case ORDER_LIST_SELLER_SUCCESS:
      return {
        loading: false,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
        count: action.payload.count,
      }
    case ORDER_LIST_SELLER_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_LIST_SELLER_RESET:
      return { orders: [] }
    default:
      return state
  }
}

export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return {
        loading: true,
      }

    case ORDER_LIST_SUCCESS:
      return {
        loading: false,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
        count: action.payload.count,
      }
    case ORDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const orderShipReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_SHIP_REQUEST:
      return {
        loading: true,
      }

    case ORDER_SHIP_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case ORDER_SHIP_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_SHIP_RESET:
      return {}
    default:
      return state
  }
}

export const orderCancelReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CANCEL_REQUEST:
      return {
        loading: true,
      }

    case ORDER_CANCEL_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case ORDER_CANCEL_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case ORDER_CANCEL_RESET:
      return {}
    default:
      return state
  }
}
