import React, { useEffect } from 'react'
import { Container, Breadcrumb, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import '../css/PrivacyPolicyScreen.css'

const PrivacyScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Meta
        title="Privacy"
        description="Lees hoe Vlonderexpert.nl persoonsgegevens beschermt en gebruikt voor het verlenen van diensten, het verbeteren van de website en het voldoen aan wettelijke verplichtingen. Neem contact op voor meer informatie."
      />
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} xl={7}>
            <Breadcrumb>
              <LinkContainer to="/">
                <Breadcrumb.Item>&lt; Verder winkelen</Breadcrumb.Item>
              </LinkContainer>
            </Breadcrumb>
            <h1
              id="privacy-policy-title"
              style={{
                color: '#3B2116',
                textAlign: 'left',
                marginBottom: '3rem',
              }}
              className="fs-md-3"
            >
              Privacyverklaring ZXK Trading B.V. (Vlonderexpert.nl)
            </h1>
            <div id="privacy-policy-text-content">
              <p>
                <i>Laatst bijgewerkt: 22 maart 2024</i>
              </p>
              <h5>1. Inleiding</h5>
              <p>
                ZXK Trading B.V., gevestigd te Nieuwegein, Nederland, hecht veel
                waarde aan de bescherming van persoonsgegevens en privacy. Deze
                privacyverklaring beschrijft hoe wij omgaan met persoonlijke
                informatie die we verzamelen via onze website Vlonderexpert.nl
                en andere zakelijke activiteiten.
              </p>
              <h5>2. Verzamelde gegevens</h5>
              <p>
                Wij verzamelen bepaalde persoonsgegevens wanneer u onze website
                bezoekt, contact met ons opneemt of gebruikmaakt van onze
                diensten. Deze gegevens kunnen omvatten:
                <ul>
                  <br />
                  <li>Naam, adres, e-mailadres en telefoonnummer</li>
                  <li>IP-adres en locatiegegevens</li>
                  <li>
                    Informatie over uw interacties met onze website en diensten
                  </li>
                </ul>
              </p>
              <h5>3. Doeleinden van gegevensverwerking</h5>
              <p>
                Wij verwerken persoonsgegevens voor de volgende doeleinden:
                <ul>
                  <br />
                  <li>
                    Het verlenen van onze diensten en het afhandelen van
                    bestellingen
                  </li>
                  <li>Het verbeteren van onze website en diensten</li>
                  <li>Het voldoen aan wettelijke verplichtingen</li>
                </ul>
              </p>
              <h5>4. Delen van gegevens</h5>
              <p>
                Wij delen uw persoonsgegevens alleen met derden wanneer dit
                noodzakelijk is voor de uitvoering van onze diensten of om te
                voldoen aan wettelijke verplichtingen.
              </p>
              <h5>5. Beveiliging</h5>
              <p>
                Wij nemen passende technische en organisatorische maatregelen om
                uw persoonsgegevens te beschermen tegen ongeoorloofde toegang,
                verlies, diefstal of misbruik.
              </p>
              <h5>6. Uw rechten</h5>
              <p>
                U heeft het recht om uw persoonsgegevens in te zien, te
                corrigeren of te verwijderen. Neem hiervoor contact met ons op
                via de contactgegevens op onze website.
              </p>
              <h5>7. Contactgegevens</h5>
              <p>
                Voor vragen over deze privacyverklaring kunt u contact met ons
                opnemen:
                <br />
                <br />
                ZXK Trading B.V.
                <br />
                Doornseveste 9<br />
                3432 RM, Nieuwegein
                <br />
                Nederland
                <br />
                E-mail:{' '}
                <a
                  style={{ fontSize: '1.25rem' }}
                  href="mailto:info@vlonderexpert.nl"
                >
                  info@vlonderexpert.nl
                </a>
              </p>
              <p>
                <i>
                  Deze privacyverklaring kan worden bijgewerkt. Controleer
                  regelmatig voor de meest recente versie.
                </i>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PrivacyScreen
