import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'

const SubSubHeader = () => {
  return (
    <div id="subsubheader" className="d-lg-block d-none">
      <Container>
        <Row className="text-center">
          <Col>
            <i className="fa-solid fa-location-pin"></i>
            &nbsp;&nbsp;Levering in Nederland en België
          </Col>
          <Col>
            <i className="fa-solid fa-stamp"></i>
            &nbsp;&nbsp;FSC&copy;-gecertificeerd hardhout
          </Col>
          <Col>
            <i className="fa-solid fa-truck"></i>&nbsp;&nbsp;Snelle levering
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SubSubHeader
