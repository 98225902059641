const fromJsonTimestamp = (timestamp, options, locale = 'nl-NL') => {
  const date = new Date(timestamp)
  return new Intl.DateTimeFormat(locale, options).format(date)
}

export const writtenDate = (timestamp, locale = 'nl-NL') => {
  return fromJsonTimestamp(timestamp, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

export const numericDate = (timestamp, locale = 'nl-NL') => {
  return fromJsonTimestamp(timestamp, undefined, locale)
}
