import React, { useState, useEffect } from 'react'
import validatePassword from '../utils/validatePassword'
import PasswordInput from './PasswordInput'

const ValidatePassword = ({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  valid,
  setValid,
}) => {
  const [messages, setMessages] = useState([])

  useEffect(() => {
    setMessages([])

    const errors = validatePassword(password, confirmPassword)

    if (errors.length === 0) {
      setValid(true)
      return
    }

    setMessages(errors)

    setValid(false)
  }, [password, confirmPassword, setValid])

  return (
    <>
      <PasswordInput
        password={password}
        setPassword={setPassword}
        label="Wachtwoord"
        placeholder="Wachtwoord"
        style={!valid ? invalidStyle : {}}
      />

      {messages.map((message) => (
        <InvalidMessage message={message} />
      ))}

      <PasswordInput
        password={confirmPassword}
        setPassword={setConfirmPassword}
        label="Bevestig wachtwoord"
        placeholder="Wachtwoord"
        style={!valid ? invalidStyle : {}}
      />
    </>
  )
}

const InvalidMessage = ({ message }) => {
  return (
    <div className="mt-2" style={{ color: '#f91906' }}>
      <i className="fas fa-times-circle"></i> {message}
    </div>
  )
}

const invalidStyle = {
  borderLeft: '4px solid #f91906',
  boxShadow: 'none',
}

export default ValidatePassword
