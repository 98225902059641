export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'

export const ORDER_LIST_USER_REQUEST = 'ORDER_LIST_USER_REQUEST'
export const ORDER_LIST_USER_SUCCESS = 'ORDER_LIST_USER_SUCCESS'
export const ORDER_LIST_USER_FAIL = 'ORDER_LIST_USER_FAIL'
export const ORDER_LIST_USER_RESET = 'ORDER_LIST_USER_RESET'

export const ORDER_LIST_SELLER_REQUEST = 'ORDER_LIST_SELLER_REQUEST'
export const ORDER_LIST_SELLER_SUCCESS = 'ORDER_LIST_SELLER_SUCCESS'
export const ORDER_LIST_SELLER_FAIL = 'ORDER_LIST_SELLER_FAIL'
export const ORDER_LIST_SELLER_RESET = 'ORDER_LIST_SELLER_RESET'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'

export const ORDER_SHIP_REQUEST = 'ORDER_SHIP_REQUEST'
export const ORDER_SHIP_SUCCESS = 'ORDER_SHIP_SUCCESS'
export const ORDER_SHIP_FAIL = 'ORDER_SHIP_FAIL'
export const ORDER_SHIP_RESET = 'ORDER_SHIP_RESET'

export const ORDER_CANCEL_REQUEST = 'ORDER_CANCEL_REQUEST'
export const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS'
export const ORDER_CANCEL_FAIL = 'ORDER_CANCEL_FAIL'
export const ORDER_CANCEL_RESET = 'ORDER_CANCEL_RESET'
