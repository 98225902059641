import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

const PasswordInput = ({
  password,
  setPassword,
  label = 'Wachtwoord',
  placeholder = 'Wachtwoord',
  style = {},
}) => {
  const [textVisible, setTextVisible] = useState(false)

  return (
    <Form.Group controlId="confirmPassword" className="mt-2 mb-4">
      <Form.Label>{label}</Form.Label>
      <div style={{ position: 'relative' }}>
        <Form.Control
          style={{
            ...style,
            paddingRight: '4.2rem',
          }}
          type={textVisible ? 'text' : 'password'}
          placeholder={placeholder}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></Form.Control>
        <i
          className="fas fa-eye"
          style={{
            ...toggleStyling,
            opacity: textVisible ? 1 : 0.65,
          }}
          onClick={() => setTextVisible(!textVisible)}
        ></i>
      </div>
    </Form.Group>
  )
}

const toggleStyling = {
  display: 'inline-block',
  position: 'absolute',
  right: '1.5rem',
  top: '1.41rem',
  cursor: 'pointer',
}

export default PasswordInput
