export const isEmpty = (obj) =>
  Object.keys(obj).length === 0 &&
  Object.getPrototypeOf(obj) === Object.prototype

export const addDecimals = (num) => {
  return Number((Math.round(num * 100) / 100).toFixed(2))
}

/*
 * @param   {Array} Array of arrays
 * @returns {Array} Array of unique arrays (string equivalence)
 */
export const uniqueArrays = (array) => {
  const stringifiedSet = new Set(array.map((item) => JSON.stringify(item)))
  return Array.from(stringifiedSet).map((item) => JSON.parse(item))
}

export const formatCM = (value) => {
  const cm = value / 10

  const formattedCm = cm.toLocaleString('nl-NL', { maximumFractionDigits: 1 })
  return formattedCm
}

export const roundToTwoDecimals = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100
}
