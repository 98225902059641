import React, { useEffect } from 'react'
import { Container, Breadcrumb, Row, Col, Card } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import '../css/ContactScreen.css'
import { Link } from 'react-router-dom'
import GoogleMap from '../components/GoogleMap'

const ContactScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Meta
        title="Contact"
        description="Neem contact op met de experts van Vlonderexpert.nl via telefoon, e-mail of WhatsApp. Vul ons contactformulier in voor al uw vragen over vlonders en houtproducten."
      />
      <Container style={{ marginBottom: '86px' }}>
        <Row className="justify-content-md-center">
          <Col xs={12} xl={7}>
            <Breadcrumb>
              <LinkContainer to="/">
                <Breadcrumb.Item>&lt; Verder winkelen</Breadcrumb.Item>
              </LinkContainer>
            </Breadcrumb>
            <h1
              id="faq-title"
              style={{
                color: '#3B2116',
                textAlign: 'left',
                marginBottom: '2rem',
              }}
              className="fs-md-3"
            >
              Contact
            </h1>
            <div id="contact-text-container">
              <p>
                Neem contact op met de experts van Vlonderexpert.nl via telefoon
                of e-mail. Of zie onze{' '}
                <Link
                  style={{ fontSize: '1.25rem', textDecoration: 'underline' }}
                  to="/faq"
                >
                  FAQ
                </Link>{' '}
                voor veelgestelde vragen.
              </p>
              <p>
                <b>Email:&nbsp;</b>
                <a
                  style={{ fontSize: '1.25rem' }}
                  href="mailto:info@vlonderexpert.nl"
                >
                  info@vlonderexpert.nl
                </a>
              </p>
              <p>
                <b>Telefoon:&nbsp;</b>
                <a style={{ fontSize: '1.25rem' }} href="tel:+31610231501">
                  +31(0)610231501
                </a>
              </p>
              <p>
                <address>
                  <b>Adres</b>
                  <br />
                  5e Garnizoensdok 1<br />
                  3439 JE Nieuwegein
                  <br />
                  Nederland
                </address>
              </p>
              <p>
                <GoogleMap />
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ContactScreen
