import React, { useEffect } from 'react'
import { Container, Breadcrumb, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import '../css/PrivacyPolicyScreen.css'
import { Link } from 'react-router-dom'

const TermsScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Meta
        title="Voorwaarden"
        description="Lees hier over de algemene voorwaarden van Vlonderexpert.nl: definities, toepasselijkheid, gebruik van website, privacy, aansprakelijkheid, bestellingen en levering, herroepingsrecht, intellectuele eigendomsrechten."
      />
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} xl={7}>
            <Breadcrumb>
              <LinkContainer to="/">
                <Breadcrumb.Item>&lt; Verder winkelen</Breadcrumb.Item>
              </LinkContainer>
            </Breadcrumb>
            <h1
              id="privacy-policy-title"
              style={{
                color: '#3B2116',
                textAlign: 'left',
                marginBottom: '3rem',
              }}
              className="fs-md-3"
            >
              Algemene Voorwaarden ZXK Trading B.V. (Vlonderexpert.nl)
            </h1>
            <div id="privacy-policy-text-content">
              <p>
                <i>Laatst bijgewerkt: 22 maart 2024</i>
              </p>
              <h5>1. Definities</h5>
              <p>
                In deze algemene voorwaarden hebben de volgende termen de
                volgende betekenis:
                <ul>
                  <br />
                  <li>
                    <b>ZXK Trading B.V.</b>: De besloten vennootschap met
                    beperkte aansprakelijkheid, gevestigd te Nieuwegein,
                    Nederland, ingeschreven bij de Kamer van Koophandel onder
                    nummer 62137239.
                  </li>
                  <li>
                    <b>Vlonderexpert.nl</b>: De website en zakelijke
                    activiteiten van ZXK Trading B.V.
                  </li>
                </ul>
              </p>
              <h5>2. Toepasselijkheid</h5>
              <p>
                Deze algemene voorwaarden zijn van toepassing op alle
                overeenkomsten tussen ZXK Trading B.V. en haar klanten,
                gebruikers en bezoekers van Vlonderexpert.nl.
              </p>
              <h5>3. Gebruik van de website</h5>
              <p>
                Door gebruik te maken van Vlonderexpert.nl stemt u in met deze
                algemene voorwaarden. U gaat akkoord met het feit dat u de
                website alleen gebruikt voor legitieme doeleinden en in
                overeenstemming met de geldende wet- en regelgeving.
              </p>
              <h5>4. Privacy en gegevensverwerking</h5>
              <p>
                ZXK Trading B.V. respecteert uw privacy en verwerkt
                persoonsgegevens conform de geldende privacywetgeving. Lees onze{' '}
                <Link style={{ fontSize: '1.25rem' }} to="/privacy">
                  privacyverklaring
                </Link>{' '}
                voor meer informatie over hoe wij met uw gegevens omgaan.
              </p>
              <h5>5. Aansprakelijkheid</h5>
              <p>
                ZXK Trading B.V. is niet aansprakelijk voor enige directe,
                indirecte of gevolgschade die voortvloeit uit het gebruik van
                Vlonderexpert.nl. Wij streven naar een foutloze en veilige
                website, maar kunnen niet garanderen dat de website te allen
                tijde beschikbaar is. Onze aansprakelijkheid is beperkt tot het
                bedrag dat door onze aansprakelijkheidsverzekering wordt gedekt.
              </p>
              <h5>6. Bestellingen en levering</h5>
              <p>
                Wanneer u een bestelling plaatst via Vlonderexpert.nl, gaat u
                akkoord met de leveringsvoorwaarden en betalingsverplichtingen
                zoals vermeld op onze website. Wij streven ernaar om
                bestellingen tijdig en correct af te handelen. Mocht er
                onverhoopt een probleem optreden, neem dan contact met ons op
                via de verstrekte contactgegevens.
              </p>
              <h5>7. Herroepingsrecht en retourneren</h5>
              <p>
                Als consument heeft u het recht om binnen 14 dagen na ontvangst
                van uw bestelling de overeenkomst te ontbinden. Raadpleeg onze
                website voor meer informatie over het herroepingsrecht en het
                retourproces.
              </p>
              <h5>8. Intellectuele eigendomsrechten</h5>
              <p>
                Alle intellectuele eigendomsrechten met betrekking tot
                Vlonderexpert.nl, inclusief teksten, afbeeldingen, logo's en
                software, berusten bij ZXK Trading B.V. Het is niet toegestaan
                om zonder schriftelijke toestemming van ZXK Trading B.V.
                materiaal van de website te kopiëren, verspreiden of anderszins
                te gebruiken.
              </p>
              <h5>9. Contactgegevens</h5>
              <p>
                Voor vragen over deze privacyverklaring kunt u contact met ons
                opnemen:
                <br />
                <br />
                ZXK Trading B.V.
                <br />
                Doornseveste 9<br />
                3432 RM, Nieuwegein
                <br />
                Nederland
                <br />
                E-mail:{' '}
                <a
                  style={{ fontSize: '1.25rem' }}
                  href="mailto:info@vlonderexpert.nl"
                >
                  info@vlonderexpert.nl
                </a>
                <br />
                <br />
                Meer informatie over ons bedrijf vindt u op onze website.
              </p>
              <p>
                <i>
                  Deze algemene voorwaarden kunnen worden bijgewerkt. Controleer
                  regelmatig voor de meest recente versie.
                </i>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default TermsScreen
