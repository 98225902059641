import React, { useEffect, useState } from 'react'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Container,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import CheckoutSteps from '../components/CheckoutSteps'
import { createOrder } from '../actions/orderActions'
import { ORDER_CREATE_RESET } from '../constants/orderConstants'
import { USER_DETAILS_RESET } from '../constants/userConstants'
import SavedAddress from '../components/SavedAddress'
import Address from '../utils/Address'
import PaymentMethod from '../components/PaymentMethod'
import ButtonSpinner from '../components/ButtonSpinner'
import RedirectLoader from '../components/RedirectLoader'
import { getCartDetails } from '../actions/cartActions'
import Loader from '../components/Loader'
import { formatPrice } from '../utils/currency'
import Meta from '../components/Meta'

const PlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch()

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // @ts-ignore
  const cart = useSelector((state) => state.cartDetails)

  // @ts-ignore
  const orderDetails = useSelector((state) => state.orderDetails)
  const { error: orderDetailsError } = orderDetails

  // @ts-ignore
  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success, loading, error } = orderCreate

  // redirect if no cart items, payment method or address
  if (
    cart.cartItems === null ||
    cart.cartItems === undefined ||
    cart.cartItems.length === 0
  ) {
    history.push('/cart')
  } else if (!cart.shippingAddress) {
    history.push('/shipping')
  } else if (!cart.paymentMethod) {
    history.push('/payment')
  }

  const [redirecting, setRedirecting] = useState(false)

  // calculate prices
  const itemsPrice = cart.cartItems
    ? cart.cartItems.reduce(
        (acc, item) => acc + item.productLength.price * item.qty,
        0,
      )
    : 0

  const taxItemsPrice = (itemsPrice / 121) * 21
  const exclItemsPrice = itemsPrice - taxItemsPrice

  let shippingPrice = 0

  if (itemsPrice <= 2500) {
    if (cart.shippingAddress && cart.shippingAddress.country === 'Nederland') {
      shippingPrice = 100
    } else {
      shippingPrice = 200
    }
  }

  const totalPrice = itemsPrice + shippingPrice
  const taxPrice = (totalPrice / 121) * 21
  const excTotalPrice = totalPrice - taxPrice

  const itemCount = cart.cartItems
    ? cart.cartItems.reduce((acc, item) => acc + item.qty, 0)
    : 0

  useEffect(() => {
    if (!userInfo) {
      history.push('/login?redirect=placeorder')
    } else {
      // TODO: find more robust solution
      if (error && error.startsWith('Not enough')) {
        dispatch(getCartDetails(userInfo.cart))
        dispatch({ type: ORDER_CREATE_RESET })
      }

      if (!cart.cartItems) {
        dispatch(getCartDetails(userInfo.cart))
      }

      if (success) {
        setRedirecting(true)
        // TODO: resets Redux state
        window.location.href = order.checkoutUrl

        dispatch({ type: USER_DETAILS_RESET })
        dispatch({ type: ORDER_CREATE_RESET })
      }
    }
  }, [history, success, error, order, dispatch, userInfo, cart.cartItems])

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems.map((item) => {
          return {
            product: item.product._id,
            productLength: item.productLength._id,
            qty: item.qty,
          }
        }),
        shippingAddress: cart.shippingAddress,
        invoiceAddress: cart.invoiceAddress,
        paymentMethod: cart.paymentMethod,
      }),
    )
  }

  return (
    <>
      <Meta title="Bestellen" />
      {redirecting ? (
        <div className="mt-5">
          <RedirectLoader />
        </div>
      ) : cart.loading ? (
        <Loader delay={0} />
      ) : cart.error ? (
        <Message variant="danger">{cart.error}</Message>
      ) : (
        <div id="cart-container">
          <CheckoutSteps step1 step2 step3 step4 />
          <Row className="py-0 gx-0 gx-xl-3">
            <Col xl={8}>
              <ListGroup variant="flush">
                {cart.shippingAddress &&
                  cart.invoiceAddress &&
                  cart.paymentMethod && (
                    <>
                      <ListGroup.Item className="py-3">
                        <h3>Bezorgadres</h3>
                        <SavedAddress address={cart.shippingAddress} />
                      </ListGroup.Item>

                      <ListGroup.Item className="py-3">
                        <h3>Factuuradres</h3>
                        {new Address(cart.invoiceAddress).equals(
                          new Address(cart.shippingAddress),
                        ) ? (
                          <span style={{ color: '#3B2116' }}>
                            Zelfde als bezorgadres
                          </span>
                        ) : (
                          <SavedAddress address={cart.invoiceAddress} />
                        )}
                      </ListGroup.Item>

                      <ListGroup.Item className="py-3">
                        <h3>Betaalmethode</h3>
                        <span style={{ color: '#3B2116' }}>Methode: </span>
                        <PaymentMethod method={cart.paymentMethod} />
                      </ListGroup.Item>
                    </>
                  )}

                <ListGroup.Item className="py-3">
                  <h3>Artikelen</h3>
                  {!cart.cartItems || cart.cartItems.length === 0 ? (
                    <Message>Je winkelwagen is leeg</Message>
                  ) : (
                    <ListGroup id="cart-item-list" variant="flush">
                      {cart.cartItems.map((cartItem) => {
                        return (
                          <ListGroup.Item key={cartItem.productLength._id}>
                            <Row>
                              <Col md={3} className="d-flex align-items-center">
                                <Image
                                  className="cart-item-image"
                                  src={cartItem.product.images[0]}
                                />
                              </Col>
                              <Col md={5} className="d-flex align-items-center">
                                <span className="cart-item-title">
                                  {`${cartItem.productLength.value}mm - `}
                                  <Link to={`/product/${cartItem.product._id}`}>
                                    {cartItem.product.title}
                                  </Link>
                                </span>
                              </Col>
                              <Col
                                md={4}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <span className="cart-item-price">
                                  {cartItem.qty} x{' '}
                                  {formatPrice(cartItem.productLength.price)} ={' '}
                                  {formatPrice(
                                    cartItem.productLength.price * cartItem.qty,
                                  )}
                                </span>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )
                      })}
                    </ListGroup>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col xl={4}>
              <Card id="cart-summary">
                <div id="cart-summary-text">
                  <h3
                    style={{
                      textAlign: 'left',
                      marginBottom: '40px',
                      color: '#fff',
                    }}
                  >
                    Subtotaal ({itemCount}) artikelen
                  </h3>
                  <Row className="mb-3" style={{ fontWeight: 400 }}>
                    <Col>
                      <span>Artikelen</span>
                      <span id="cart-note-text">(Incl. BTW)</span>
                    </Col>
                    <Col className="text-end">
                      <span>{formatPrice(itemsPrice)}</span>
                    </Col>
                  </Row>
                  <Row className="mb-3" style={{ fontWeight: 400 }}>
                    <Col>
                      <span>Bijdrage bezorgkosten</span>
                    </Col>
                    <Col className="text-end">
                      <span>{formatPrice(shippingPrice)}</span>
                    </Col>
                  </Row>
                  <Row className="mb-3" style={{ fontWeight: 400 }}>
                    <Col>
                      <span>Subtotaal</span>
                      <span id="cart-note-text">(Excl. BTW)</span>
                    </Col>
                    <Col className="text-end">
                      <span>{formatPrice(excTotalPrice)}</span>
                    </Col>
                  </Row>
                  <Row className="mb-5">
                    <Col>
                      <span>Totaalbedrag</span>
                      <span id="cart-note-text">(Incl. BTW)</span>
                    </Col>
                    <Col className="text-end">
                      <span>{formatPrice(totalPrice)}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <Button type="button" onClick={placeOrderHandler}>
                        {loading ? <ButtonSpinner /> : 'Bevestig'}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
              {orderDetailsError === 'Order not paid' ? (
                <Message
                  variant="danger"
                  noBottomMargin={true}
                  topMargin={true}
                >
                  Betaling mislukt
                </Message>
              ) : (
                error && (
                  <Message
                    variant="danger"
                    noBottomMargin={true}
                    topMargin={true}
                  >
                    {error}
                  </Message>
                )
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default PlaceOrderScreen
