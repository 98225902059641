import React from 'react'
import Message from './Message'
import { writtenDate } from '../utils/dates'

const PaymentStatus = ({ order }) => {
  if (order.isShipped) {
    return (
      <Message variant="success" topMargin={true} noBottomMargin={true}>
        Verzonden op {writtenDate(order.shippedAt)}
      </Message>
    )
  } else {
    return (
      <Message variant="warning" topMargin={true} noBottomMargin={true}>
        Nog niet verzonden
      </Message>
    )
  }
}

export default PaymentStatus
