import { Router, Route, Switch } from 'react-router-dom'
import history from './history.js'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import SearchResultScreen from './screens/SearchResultScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ProfileChangeName from './screens/ProfileChangeName.js'
import ProfileChangePassword from './screens/ProfileChangePassword.js'
import OrderListUserScreen from './screens/OrderListUserScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductAddScreen from './screens/ProductAddScreen.js'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import AboutScreen from './screens/AboutScreen'
import PrivacyScreen from './screens/PrivacyScreen'
import TermsScreen from './screens/TermsScreen'
import AddressScreen from './screens/AddressScreen.js'
import NotFoundScreen from './screens/NotFoundScreen.js'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen.js'
import ResetPasswordScreen from './screens/ResetPasswordScreen.js'
import DeliveryScreen from './screens/DeliveryScreen.js'
import CategoryListScreen from './screens/CategoryListScreen.js'
import CategoryEditScreen from './screens/CategoryEditScreen.js'
import { useEffect } from 'react'
import CategoryAddScreen from './screens/CategoryAddScreen.js'
import CategoryScreen from './screens/CategoryScreen.js'
import FAQScreen from './screens/FAQScreen.js'
import ContactScreen from './screens/ContactScreen.js'
import SubSubHeader from './components/SubSubHeader'

const preloadImage = (url) => {
  const img = new Image()
  img.src = url
}

const App = () => {
  useEffect(() => {
    const images = [
      // all images About Us page
      'images/about-us-1.webp',
      'images/tree1.webp',
      'images/inspiratie-1.webp',
      'images/inspiratie-2.webp',
      'images/inspiratie-3.webp',
      'images/inspiratie-4.webp',

      // all images Home page
      'images/banner.jpg',
      'images/vlonderplanken.webp',
      'images/onderregel.webp',
      'images/palen.webp',
    ]

    images.forEach((image) => preloadImage(image))
  }, [])

  return (
    <Router history={history}>
      <Header history={history} />
      <SubSubHeader />
      <main>
        {/* <Container> */}
        <Switch>
          <Route path="/" component={HomeScreen} exact />
          <Route path="/shipping" component={ShippingScreen} />
          <Route path="/payment" component={PaymentScreen} />
          <Route path="/placeorder" component={PlaceOrderScreen} />
          <Route path="/login" component={LoginScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/forgotpassword" component={ForgotPasswordScreen} />
          <Route path="/resetpassword" component={ResetPasswordScreen} />
          <Route path="/profile" component={ProfileScreen} exact />
          <Route path="/profile/changename" component={ProfileChangeName} />
          <Route
            path="/profile/changepassword"
            component={ProfileChangePassword}
          />
          <Route path="/orders" component={OrderListUserScreen} />
          <Route path="/addresses" component={AddressScreen} />
          <Route path="/cart" component={CartScreen} />
          <Route path="/about-us" component={AboutScreen} />
          <Route path="/privacy" component={PrivacyScreen} />
          <Route path="/terms" component={TermsScreen} />
          <Route path="/delivery" component={DeliveryScreen} />
          <Route path="/faq" component={FAQScreen} />
          <Route path="/contact" component={ContactScreen} />

          <Route path="/order/:id" component={OrderScreen} />
          <Route path="/product/:id" component={ProductScreen} />
          <Route path="/c/:category" component={CategoryScreen} />

          <Route path="/admin/users" component={UserListScreen} />
          <Route path="/admin/user/:id/edit" component={UserEditScreen} />

          <Route path="/admin/products" component={ProductListScreen} />
          <Route path="/admin/product/add" component={ProductAddScreen} />
          <Route path="/admin/product/:id/edit" component={ProductEditScreen} />

          <Route path="/admin/orders" component={OrderListScreen} />

          <Route path="/admin/categories" component={CategoryListScreen} />
          <Route
            path="/admin/category/:id/edit"
            component={CategoryEditScreen}
          />
          <Route path="/admin/category/add" component={CategoryAddScreen} />

          <Route path="/search" component={SearchResultScreen} />
          <Route path="*" component={NotFoundScreen} />
        </Switch>
        {/* </Container> */}
      </main>
      <Footer />
    </Router>
  )
}

export default App
