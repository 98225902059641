import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Form,
  Button,
  Breadcrumb,
  Row,
  Table,
  ListGroup,
  Image,
  Col,
  Container,
} from 'react-bootstrap'
import ButtonSpinner from '../components/ButtonSpinner'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Length from '../components/Length'
import { listProductDetails, updateProduct } from '../actions/productActions'
import {
  PRODUCT_DETAILS_RESET,
  PRODUCT_UPDATE_RESET,
} from '../constants/productConstants'
import Meta from '../components/Meta'
import * as config from '../config'
import NewLength from '../components/NewLength'
import '../css/ProductEditScreen.css'
import RelatedProducts from '../components/RelatedProducts'
import { addDecimals } from '../utils/utils'
import TextEditor from '../components/TextEditor'

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [productType, setProductType] = useState('')
  const [images, setImages] = useState([])
  const [color, setColor] = useState('Bruin')
  const [material, setMaterial] = useState('Hardhout')
  const [profile, setProfile] = useState('')
  const [species, setSpecies] = useState('')
  const [width, setWidth] = useState('')
  const [thickness, setThickness] = useState('')
  const [relatedProductIds, setRelatedProductIds] = useState([])
  const [pricePerMeter, setPricePerMeter] = useState()
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  // @ts-ignore
  const categoryList = useSelector((state) => state.categoryList)
  const { categories } = categoryList

  // @ts-ignore
  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  // @ts-ignore
  const productUpdate = useSelector((state) => state.productUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET })
      dispatch({ type: PRODUCT_DETAILS_RESET })
      history.push('/admin/products')
    } else {
      if (!product.title || product._id !== productId) {
        dispatch(listProductDetails(productId))
      } else {
        setName(product.title)
        setDescription(product.description)
        setProductType(product.productType)
        setImages(product.images)
        setColor(product.color)
        setMaterial(product.material)
        setProfile(product.profile)
        setSpecies(product.species)
        setWidth(product.width)
        setThickness(product.thickness)
        setRelatedProductIds(
          product.relatedProducts.map((product) => product._id),
        )
        setPricePerMeter(product.pricePerMeter)
      }
    }
  }, [dispatch, product, productId, history, successUpdate])

  const uploadFileHandler = async (e) => {
    const files = e.target.files
    const formData = new FormData()

    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i])
    }

    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImages(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateProduct({
        _id: productId,
        title: name,
        description,
        productType,
        images,
        color,
        material,
        profile,
        species,
        width,
        thickness,
        relatedProducts: relatedProductIds,
        pricePerMeter,
      }),
    )
  }

  return (
    <>
      <Meta title="Admin | Wijzig product" />
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} lg={6}>
            <Breadcrumb>
              <LinkContainer to="/admin/products">
                <Breadcrumb.Item className="active">
                  &lt; Ga terug
                </Breadcrumb.Item>
              </LinkContainer>
            </Breadcrumb>
            <h2>Bewerk product</h2>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <Form onSubmit={submitHandler}>
                <Form.Group controlId="name" className="mt-2">
                  <Form.Label>Naam</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Naam"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mb-4"
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="description" className="mt-2">
                  <Form.Label>Omschrijving</Form.Label>
                  <TextEditor
                    description={description}
                    setDescription={setDescription}
                  />
                </Form.Group>

                <Form.Group controlId="productType" className="mt-2">
                  <Form.Label>Categorie</Form.Label>
                  <Form.Select
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                    className="mb-4"
                  >
                    {categories
                      ? categories.map((category) => (
                          <option value={category.name}>{category.name}</option>
                        ))
                      : []}
                    <option value="Geen">Geen</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="images" className="mt-2">
                  <Form.Label>Foto's</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Afbeelding URL's"
                    value={images}
                    onChange={(e) => setImages(e.target.value)}
                  ></Form.Control>
                  <Form.Control
                    type="file"
                    onChange={uploadFileHandler}
                    multiple
                    style={{
                      border: 'none',
                      paddingBottom: '0px',
                      height: 'inherit',
                      marginBottom: '0.5rem',
                    }}
                  ></Form.Control>
                  {uploading && <Loader />}
                  <ListGroup variant="flush" className="mb-4">
                    {images.map((image, index) => (
                      <ListGroup.Item key={index}>
                        <Row>
                          <Col xs={2}>
                            <Image
                              src={image}
                              alt="product image preview"
                              style={{
                                width: '60px',
                                height: '45px',
                                objectFit: 'cover',
                              }}
                            />
                          </Col>
                          <Col xs={8}>{image}</Col>
                          <Col xs={2}>
                            <Row>
                              <Col xs={6}>
                                {index !== 0 && (
                                  <Button
                                    className="move-up-button"
                                    variant="sm"
                                    onClick={() => {
                                      const newImages = [...images]
                                      const currentImage = newImages[index]
                                      const replaceImage = newImages[index - 1]
                                      newImages[index] = replaceImage
                                      newImages[index - 1] = currentImage
                                      // @ts-ignore
                                      setImages(newImages)
                                    }}
                                  >
                                    <i
                                      style={{ color: '#9ddc5f' }}
                                      className="fas fa-arrow-up"
                                    ></i>
                                  </Button>
                                )}
                              </Col>
                              <Col xs={6}>
                                {index !== images.length - 1 && (
                                  <Button
                                    className="move-down-button"
                                    variant="sm"
                                    onClick={() => {
                                      const newImages = [...images]
                                      const currentImage = newImages[index]
                                      const replaceImage = newImages[index + 1]
                                      newImages[index] = replaceImage
                                      newImages[index + 1] = currentImage
                                      // @ts-ignore
                                      setImages(newImages)
                                    }}
                                  >
                                    <i
                                      style={{ color: '#A02323' }}
                                      className="fas fa-arrow-down"
                                    ></i>
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Form.Group>

                <Form.Group controlId="color" className="mt-2">
                  <Form.Label>Kleur</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Kleur"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    className="mb-4"
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="material" className="mt-2">
                  <Form.Label>Materiaal</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Materiaal"
                    value={material}
                    onChange={(e) => setMaterial(e.target.value)}
                    className="mb-4"
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="profile" className="mt-2">
                  <Form.Label>Profiel</Form.Label>
                  <Form.Select
                    value={profile}
                    onChange={(e) => setProfile(e.target.value)}
                    className="mb-4"
                  >
                    {config.productProfiles.map((profile) => (
                      <option value={profile}>{profile}</option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="species" className="mt-2">
                  <Form.Label>Houtsoort</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Houtsoort"
                    value={species}
                    onChange={(e) => setSpecies(e.target.value)}
                    className="mb-4"
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="width" className="mt-2">
                  <Form.Label>Breedte (in mm)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Breedte"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                    className="mb-4"
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="thickness" className="mt-2">
                  <Form.Label>Dikte (in mm)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Dikte"
                    value={thickness}
                    onChange={(e) => setThickness(e.target.value)}
                    className="mb-4"
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="thickness" className="mt-2 mb-4">
                  <Form.Label>Gerelateerde producten</Form.Label>
                  <RelatedProducts
                    productId={product._id}
                    relatedProductsInitial={product.relatedProducts}
                    setRelatedProductIds={setRelatedProductIds}
                  />
                </Form.Group>

                <Form.Group controlId="thickness" className="mt-2">
                  <Form.Label>Prijs per meter</Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    step={0.01}
                    value={pricePerMeter}
                    onChange={(e) => {
                      let priceValue = parseFloat(e.target.value) || undefined
                      if (priceValue === undefined) {
                        setPricePerMeter(undefined)
                        return
                      }

                      priceValue = addDecimals(priceValue)

                      setPricePerMeter(priceValue)
                    }}
                    className="mb-4"
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="lengths" className="mt-2">
                  <Form.Label>
                    Afmetingen ({product.lengths && product.lengths.length})
                  </Form.Label>
                  <Table responsive className="table-sm">
                    <thead>
                      <tr>
                        <th>lengte</th>
                        <th>prijs / stuk</th>
                        <th>voorraad</th>
                        <th>levertijd</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.lengths &&
                        product.lengths.map((length, index) => (
                          <Length length={length} key={index} />
                        ))}
                      <NewLength
                        productId={product._id}
                        pricePerMeter={product.pricePerMeter}
                      />
                    </tbody>
                  </Table>
                </Form.Group>

                <Row style={{ marginTop: '48px' }}>
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-50 text-center mx-auto"
                    style={{ marginBottom: '86px' }}
                    disabled={loading}
                  >
                    {loading ? <ButtonSpinner /> : 'Bevestig'}
                  </Button>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProductEditScreen
