/*
  Very basic set implementation that assumes small itemset and that items implement equality method
*/
class BasicSet {
  #values = []

  constructor(...args) {
    for (const a of args) {
      this.add(a)
    }
  }

  add(value) {
    for (const v of this.#values) {
      if (value.equals(v)) return false
    }

    this.#values.push(value)
    return true
  }

  has(value) {
    for (const v of this.#values) {
      if (value.equals(v)) return true
    }

    return false
  }

  index(value) {
    for (const [i, v] of this.#values.entries()) {
      if (value.equals(v)) return i
    }

    return -1
  }

  delete(value) {
    this.#values = this.#values.filter((v) => !v.equals(value))
  }

  [Symbol.iterator] = function* () {
    for (const v of this.#values) {
      yield v
    }
  }

  get values() {
    return this.#values
  }

  clear() {
    this.#values = []
  }
}

export default BasicSet
