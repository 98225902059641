import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { useSelector } from 'react-redux'
import {
  Row,
  Col,
  ListGroup,
  Image,
  Button,
  Card,
  Breadcrumb,
  Container,
} from 'react-bootstrap'
import Message from '../components/Message'
import Meta from '../components/Meta'
import { getCartDetails } from '../actions/cartActions'
import Loader from '../components/Loader'
import { formatPrice } from '../utils/currency'
import '../css/CartScreen.css'
import ChangeQuantitySelect from '../components/ChangeQuantitySelect'
import RemoveFromCartButton from '../components/RemoveFromCartButton'
import { taxRate } from '../config'

const CartScreen = ({ history }) => {
  const dispatch = useDispatch()

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // @ts-ignore
  const cartDetails = useSelector((state) => state.cartDetails)
  const {
    loading,
    error,
    cartItems,
    paymentMethod,
    invoiceAddress,
    shippingAddress,
  } = cartDetails

  const checkoutHandler = () => {
    let url = '/login?redirect=shipping'
    history.push(url)
  }

  useEffect(() => {
    if (userInfo) {
      if (!cartItems) {
        dispatch(getCartDetails(userInfo.cart))
      }
    }
  }, [userInfo, dispatch, cartItems])

  const itemCount = cartItems
    ? cartItems.reduce((acc, item) => acc + item.qty, 0)
    : 0

  const totalCost = cartItems
    ? cartItems.reduce(
        (acc, item) => acc + item.qty * item.productLength.price,
        0,
      )
    : 0

  const BTW = totalCost * (taxRate / (100 + taxRate))

  const itemCostExcl = totalCost - BTW

  return (
    <>
      <Meta
        title="Mandje"
        description="Ontdek en beheer uw geselecteerde items in de winkelwagen op Vlonderexpert.nl. Voeg hoogwaardige FSC-gecertificeerde vlonderplanken, onderregels en palen toe aan uw winkelwagen en voltooi uw bestelling veilig en gemakkelijk."
      />
      <Row className="mx-0">
        <Container style={{ paddingLeft: '51px' }}>
          <Breadcrumb>
            <LinkContainer to="/">
              <Breadcrumb.Item>&lt; Verder winkelen</Breadcrumb.Item>
            </LinkContainer>
          </Breadcrumb>
        </Container>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : cartItems === null || cartItems.length === 0 ? (
        <>
          <Row className="mx-0" style={{ paddingLeft: '51px' }}>
            <h2 style={{ paddingLeft: '0px' }}>Winkelwagen</h2>
          </Row>
          <Row className="mx-0">
            <div id="empty-cart-element">
              <div
                id="empty-cart-text"
                className="d-flex justify-content-center align-items-center h-100"
              >
                <div className="d-flex flex-column h-50">
                  <div className="row flex-grow-1">
                    <div id="empty-cart-title">Uw winkelwagen is leeg</div>
                  </div>
                  <div className="row flex-grow-1">
                    <div id="empty-cart-description">
                      Ontdek onze producten en voeg ze toe aan uw winkelwagen
                    </div>
                  </div>
                  <div className="row flex-grow-1 d-flex align-items-end">
                    <div>
                      <LinkContainer to="/">
                        <Button className="d-none d-sm-flex">
                          Verder winkelen
                        </Button>
                      </LinkContainer>
                      <LinkContainer to="/">
                        <Button className="btn-sm d-sm-none">
                          Verder winkelen
                        </Button>
                      </LinkContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </>
      ) : (
        <Row id="cart-container">
          <Col id="cart-item-list-container" xl={8}>
            <h2>Winkelwagen</h2>
            <ListGroup id="cart-item-list" variant="flush">
              {cartItems.map((cartItem) => {
                return (
                  <ListGroup.Item key={cartItem.productLength._id}>
                    <Row className="gy-3">
                      <Col md={3} className="d-flex align-items-center">
                        <Image
                          className="cart-item-image"
                          src={cartItem.product.images[0]}
                        />
                      </Col>
                      <Col md={4} className="d-flex align-items-center">
                        <span className="cart-item-title">
                          {`${cartItem.productLength.value}mm - `}
                          <Link to={`/product/${cartItem.product._id}`}>
                            {cartItem.product.title}
                          </Link>
                        </span>
                      </Col>
                      <Col
                        md={3}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <Row className="w-100 gx-0">
                          <Col
                            xs={6}
                            md={8}
                            className="d-flex align-items-center"
                          >
                            <ChangeQuantitySelect item={cartItem} />
                          </Col>
                          <Col
                            xs={6}
                            md={4}
                            className="d-flex align-items-center"
                          >
                            <RemoveFromCartButton item={cartItem} />
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        md={2}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="cart-item-price">
                          {formatPrice(
                            cartItem.productLength.price * cartItem.qty,
                          )}
                        </span>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </Col>
          <Col xl={4} className="px-0">
            <Card id="cart-summary">
              <div id="cart-summary-text">
                <h3
                  style={{
                    textAlign: 'left',
                    marginBottom: '40px',
                    color: '#fff',
                  }}
                >
                  Subtotaal ({itemCount}) artikelen
                </h3>
                <Row className="mb-3" style={{ fontWeight: 400 }}>
                  <Col>
                    <span>Artikelen</span>
                    <span id="cart-note-text">(Excl. BTW)</span>
                  </Col>
                  <Col className="text-end">
                    <span>{formatPrice(itemCostExcl)}</span>
                  </Col>
                </Row>
                <Row className="mb-3" style={{ fontWeight: 400 }}>
                  <Col>
                    <span>BTW</span>
                  </Col>
                  <Col className="text-end">
                    <span>{formatPrice(BTW)}</span>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col>
                    <span>Totaalbedrag</span>
                    <span id="cart-note-text">(Incl. BTW)</span>
                  </Col>
                  <Col className="text-end">
                    <span>{formatPrice(totalCost)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button
                      type="button"
                      disabled={cartItems ? cartItems.length === 0 : true}
                      onClick={checkoutHandler}
                      className="d-none d-sm-inline-block"
                    >
                      Volgende stap &gt;
                    </Button>
                    <Button
                      type="button"
                      disabled={cartItems ? cartItems.length === 0 : true}
                      onClick={checkoutHandler}
                      className="btn-sm d-sm-none"
                    >
                      Volgende stap &gt;
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}

export default CartScreen
