import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Spinner } from 'react-bootstrap'
import '../css/Length.css'
import { addDecimals } from '../utils/utils'
import axios from 'axios'
import { PRODUCT_DETAILS_SUCCESS } from '../constants/productConstants'
import calculatePricePerPiece from '../utils/calculatePricePerPiece'

const NewLength = ({ productId, pricePerMeter }) => {
  const dispatch = useDispatch()

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [value, setValue] = useState()
  const [price, setPrice] = useState(pricePerMeter)
  const [countInStock, setCountInStock] = useState()
  const [deliveryTime, setDeliveryTime] = useState()

  const [loading, setLoading] = useState(false)

  const saveLengthHandler = async () => {
    // logged in
    setLoading(true)

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    try {
      const { data } = await axios.post(
        '/api/lengths',
        {
          value,
          price,
          countInStock,
          deliveryTime,
          product: productId,
        },
        config,
      )

      dispatch({
        type: PRODUCT_DETAILS_SUCCESS,
        payload: data,
      })

      // reset state
      setValue('')
      setPrice('')
      setCountInStock('')
      setDeliveryTime('')
    } catch (error) {
      // TODO: handle error
    }

    setLoading(false)
  }

  return (
    <tr>
      <td>
        <Form.Control
          type="number"
          value={value}
          min={0}
          step={1}
          disabled={loading}
          onChange={(e) => {
            const value = parseInt(e.target.value) || 0
            setValue(value)

            if (pricePerMeter !== undefined)
              setPrice(calculatePricePerPiece(pricePerMeter, value))
          }}
          style={{
            width: '5.5rem',
            height: '2rem',
          }}
        ></Form.Control>
      </td>
      <td>
        <Form.Control
          className="length-price-input"
          type="number"
          value={price}
          min={0}
          step={0.01}
          disabled={loading}
          onChange={(e) => {
            let priceValue = parseFloat(e.target.value) || 0
            priceValue = addDecimals(priceValue)
            setPrice(priceValue)
          }}
          style={{
            width: '5.5rem',
            height: '2rem',
          }}
        ></Form.Control>
      </td>
      <td>
        <Form.Control
          className="length-stock-input form-select"
          as="select"
          value={countInStock}
          onChange={(e) => setCountInStock(parseInt(e.target.value))}
          disabled={loading}
          style={{
            width: '5.5rem',
            height: '2rem',
          }}
        >
          {[...Array(1001).keys()].map((x) => (
            <option key={x} value={x}>
              {x}
            </option>
          ))}
        </Form.Control>
      </td>
      <td>
        <Form.Control
          className="length-delivery-input form-select"
          as="select"
          value={deliveryTime}
          onChange={(e) => setDeliveryTime(parseInt(e.target.value))}
          disabled={loading}
          style={{
            width: '5.5rem',
            height: '2rem',
          }}
        >
          {[...Array(14).keys()].map((x) => (
            <option key={x + 1} value={x + 1}>
              {x + 1}
            </option>
          ))}
        </Form.Control>
      </td>
      <td>
        {!loading ? (
          <Button
            onClick={saveLengthHandler}
            type="button"
            variant="light"
            style={{
              color: '#5D5D5D',
              backgroundColor: 'inherit',
              border: 'none',
              margin: 'auto',
            }}
          >
            <i className="fas fa-save"></i>
          </Button>
        ) : (
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          ></Spinner>
        )}
      </td>
    </tr>
  )
}

export default NewLength
