import axios from 'axios'
import {
  CART_DETAILS_REQUEST,
  CART_DETAILS_SUCCESS,
  CART_DETAILS_FAIL,
  CART_DETAILS_RESET,
} from '../constants/cartConstants'
import { logout } from './userActions'

export const getCartDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CART_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/carts/${id}`, config)

    dispatch({
      type: CART_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }

    dispatch({
      type: CART_DETAILS_FAIL,
      payload: message,
    })
  }
}

export const localAddToCart = (item) => async (dispatch, getState) => {
  const cartItems = getState().cartDetails.cartItems ?? []

  dispatch({
    type: CART_DETAILS_SUCCESS,
    payload: {
      ...getState().cartDetails,
      cartItems: put(cartItems, item),
    },
  })

  localStorage.setItem(
    'cartItems',
    JSON.stringify(getState().cartDetails.cartItems),
  )
}

export const bulkLocalAddToCart = (items) => async (dispatch, getState) => {
  let cartItems = getState().cartDetails.cartItems ?? []

  for (const item of items) cartItems = put(cartItems, item)

  dispatch({
    type: CART_DETAILS_SUCCESS,
    payload: {
      ...getState().cartDetails,
      cartItems,
    },
  })

  localStorage.setItem(
    'cartItems',
    JSON.stringify(getState().cartDetails.cartItems),
  )
}

const cartItemEquals = (a, b) => a.productLength._id === b.productLength._id

const put = (cartItems, newItem) => {
  const index = cartItems.findIndex((item) => cartItemEquals(item, newItem))

  if (index !== -1) {
    cartItems[index] = newItem
  } else {
    cartItems.push(newItem)
  }

  return cartItems
}

export const localRemoveFromCart =
  (productLengthId) => async (dispatch, getState) => {
    const cartItems = getState().cartDetails.cartItems ?? []

    dispatch({
      type: CART_DETAILS_SUCCESS,
      payload: {
        ...getState().cartDetails,
        cartItems: cartItems.filter(
          (item) => item.productLength._id !== productLengthId,
        ),
      },
    })

    localStorage.setItem(
      'cartItems',
      JSON.stringify(getState().cartDetails.cartItems),
    )
  }

export const resetCartItems = () => (dispatch) => {
  dispatch({ type: CART_DETAILS_RESET })

  localStorage.setItem('cartItems', JSON.stringify(null))
}
