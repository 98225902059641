import React, { useEffect } from 'react'
import { Container, Breadcrumb, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import '../css/PrivacyPolicyScreen.css'

const DeliveryScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Meta
        title="Leveringsvoorwaarden"
        description="Lees op deze pagina over de leveringsvoorwaarden van Vlonderexpert.nl. Verzendkosten op basis van bestelling en locatie. Levering binnen 30 dagen in Nederland en België. Track & Trace beschikbaar."
      />
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} xl={7}>
            <Breadcrumb>
              <LinkContainer to="/">
                <Breadcrumb.Item>&lt; Verder winkelen</Breadcrumb.Item>
              </LinkContainer>
            </Breadcrumb>
            <h1
              id="privacy-policy-title"
              style={{
                color: '#3B2116',
                textAlign: 'left',
                marginBottom: '3rem',
              }}
              className="fs-md-3"
            >
              Levering en Verzending bij Vlonderexpert.nl
            </h1>
            <div id="privacy-policy-text-content">
              <p>
                <i>Laatst bijgewerkt: 22 maart 2024</i>
              </p>
              <h5>1. Verzendkosten en Levertijd</h5>
              <p>
                <ul>
                  <li>
                    De verzendkosten worden berekend op basis van uw bestelling
                    en de afleverlocatie.
                  </li>
                  <li>
                    We streven ernaar om uw bestelling binnen 30 dagen na
                    aankoop te leveren, tenzij anders overeengekomen.
                  </li>
                  <li>U kunt uw bestelling volgen via Track & Trace.</li>
                </ul>
              </p>
              <h5>2. Bezorginformatie</h5>
              <p>
                <ul>
                  <li>
                    Onze producten worden bezorgd door een professioneel
                    transportbedrijf.
                  </li>
                  <li>
                    Uw bestelling kan worden gelost met een kooiaap
                    (meeneemheftruck). E.e.a. afhankelijk van bereikbaarheid.
                  </li>
                  <li>
                    U kunt kiezen tussen levering op uw persoonlijke of
                    professionele adres.
                  </li>
                  <li>
                    Houd er rekening mee dat levertijden onderhevig zijn aan
                    overmacht en voorraadtekorten.
                  </li>
                </ul>
              </p>
              <h5>3. Levergebied</h5>
              <p>
                <ul>
                  <li>We leveren in heel Nederland en ook in België.</li>
                  <li>
                    Als u specifieke vragen heeft over het levergebied, neem dan
                    gerust contact met ons op.
                  </li>
                </ul>
              </p>
              <h5>4. Aanwezigheid bij Levering</h5>
              <p>
                <ul>
                  <li>
                    Zorg ervoor dat er iemand aanwezig is op het afleveradres
                    wanneer uw bestelling wordt geleverd.
                  </li>
                  <li>
                    Als u niet aanwezig kunt zijn, kunnen we mogelijk een
                    alternatieve regeling treffen.
                  </li>
                </ul>
              </p>
              <h5>5. Problemen met Bestellingen</h5>
              <p>
                <ul>
                  <li>
                    Als uw bestelling vertraagd is, neem dan contact met ons op
                    voor assistentie.
                  </li>
                  <li>
                    Als uw bestelling beschadigd is geleverd, zullen we dit zo
                    snel mogelijk oplossen.
                  </li>
                  <li>
                    Ontbreekt er iets in uw bestelling? Laat het ons weten,
                    zodat we het kunnen aanvullen.
                  </li>
                </ul>
              </p>
              <h5>6. Contactgegevens</h5>
              <p>
                Voor vragen over levering en verzending kunt u ons bereiken via:
                <ul>
                  <li>
                    E-mail:{' '}
                    <a
                      style={{ fontSize: '1.25rem' }}
                      href="mailto:info@vlonderexpert.nl"
                    >
                      info@vlonderexpert.nl
                    </a>
                  </li>
                  <li>
                    Telefoon:{' '}
                    <a style={{ fontSize: '1.25rem' }} href="tel:+31610231501">
                      06-10231501
                    </a>
                  </li>
                </ul>
                <br />
                Meer informatie over ons bedrijf vindt u op onze website.
              </p>
              <p>
                <i>
                  Let op: Deze informatie kan worden bijgewerkt. Controleer
                  regelmatig voor de meest recente versie.
                </i>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default DeliveryScreen
