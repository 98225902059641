import {
  LENGTH_DETAILS_FAIL,
  LENGTH_DETAILS_SUCCESS,
  LENGTH_DETAILS_REQUEST,
  LENGTH_DETAILS_RESET,
  LENGTH_CREATE_FAIL,
  LENGTH_CREATE_SUCCESS,
  LENGTH_CREATE_REQUEST,
  LENGTH_UPDATE_FAIL,
  LENGTH_UPDATE_SUCCESS,
  LENGTH_UPDATE_REQUEST,
  LENGTH_DELETE_REQUEST,
  LENGTH_DELETE_SUCCESS,
  LENGTH_DELETE_FAIL,
  LENGTH_UPDATE_RESET,
  LENGTH_CREATE_RESET,
} from '../constants/lengthConstants'

export const lengthDetailsReducer = (state = { length: {} }, action) => {
  switch (action.type) {
    case LENGTH_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LENGTH_DETAILS_SUCCESS:
      return {
        loading: false,
        length: action.payload,
      }
    case LENGTH_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case LENGTH_DETAILS_RESET:
      return { length: {} }
    default:
      return state
  }
}

export const lengthCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LENGTH_CREATE_REQUEST:
      return {
        loading: true,
      }
    case LENGTH_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        length: action.payload,
      }
    case LENGTH_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case LENGTH_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const lengthUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case LENGTH_UPDATE_REQUEST:
      return {
        loading: true,
      }
    case LENGTH_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        length: action.payload,
      }
    case LENGTH_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case LENGTH_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const lengthDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LENGTH_DELETE_REQUEST:
      return {
        loading: true,
      }

    case LENGTH_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case LENGTH_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
