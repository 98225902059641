import React from 'react'
import { Modal, Button, Col, Row } from 'react-bootstrap'
import ButtonSpinner from './ButtonSpinner'

const DeleteModal = ({
  isVisible,
  handleClose,
  title,
  body,
  handleConfirm,
  loading,
}) => {
  return (
    <Modal
      show={isVisible}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer className="px-0 py-1">
        <Row className="w-100">
          <Col>
            <Button
              variant="secondary"
              className="m-0 w-100"
              size="sm"
              onClick={handleClose}
            >
              Sluit
            </Button>
          </Col>
          <Col>
            <Button
              variant="danger"
              className="m-0 w-100"
              size="sm"
              onClick={handleConfirm}
              disabled={loading}
            >
              {loading ? <ButtonSpinner /> : 'Bevestig'}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteModal
