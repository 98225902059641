import React, { useEffect } from 'react'
import Meta from '../components/Meta'
import { Row, Col, Container, Breadcrumb, Image } from 'react-bootstrap'
import '../css/AboutScreen.css'
import { LinkContainer } from 'react-router-bootstrap'

const AboutScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Meta
        title="Over ons"
        description="Leer meer over Vlonderexpert.nl, dé specialist in tuinhout en hardhout. Ontdek onze toewijding aan duurzaamheid, waarbij al ons hout afkomstig is uit duurzaam beheerde bossen. Bij Vlonderexpert.nl staan kwaliteit, service en vakmanschap voorop. Laat onze specialisten je inspireren bij het realiseren van jouw tuinprojecten."
      />
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} xl={7}>
            <Breadcrumb>
              <LinkContainer to="/">
                <Breadcrumb.Item>&lt; Verder winkelen</Breadcrumb.Item>
              </LinkContainer>
            </Breadcrumb>
            <h1 id="about-title" className="fs-md-3">
              Over ons
            </h1>
            <div id="about-us-text-content">
              <p>
                Vlonderexpert.nl is dé specialist in tuinhout en hardhout. Of je
                nu op zoek bent naar een prachtige vlonder, een stevige
                schutting of duurzame terrasplanken, bij ons vind je het
                allemaal!
              </p>
              <div id="about-us-1-image-container">
                <Image
                  className="about-us-image"
                  src="images/about-us-1.webp"
                  alt="about"
                />
              </div>
              <h5>Duurzaamheid</h5>
              <p>
                <b>Al ons hout FSC&copy;-gecertificeerd</b>. Wij zijn
                FSC&copy;-gecertificeerd onder het nummer SCS-COC-000652-QM
                (FSC&copy; C016391)
              </p>
              <p>
                <Image
                  src="fsc.png"
                  alt="FSC logo (FSC&copy; C016391)"
                  style={{
                    maxWidth: '40%',
                    marginTop: '0.75rem',
                    marginBottom: '1.25rem',
                  }}
                />
              </p>
              <p>
                Bij ons vindt u niet alleen het beste hout, maar ons hout komt
                uitsluitend uit duurzaam beheerd bos. Duurzaam hardhout is
                afkomstig van bomen die afkomstig zijn van duurzaam beheerde
                bossen en die op een ecologisch verantwoorde manier zijn gekapt
                en verwerkt. Duurzaam hardhout is daardoor een milieuvriendelijk
                alternatief voor hardhout dat uit ongereguleerde of illegale
                houtkap komt.
              </p>
              <p>
                Duurzaam beheerde tropische regenwouden worden op een manier
                geëxploiteerd die hun biodiversiteit, hulpbronnen en
                leefomgeving beschermt, terwijl tegelijkertijd economische
                voordelen worden behaald. Dit type bosbeheer is gericht op het
                minimaliseren van milieu-impact, het bevorderen van sociale
                verantwoordelijkheid en het waarborgen van economische
                levensvatbaarheid op lange termijn.
              </p>
              <div id="about-us-2-image-container">
                <Image
                  className="about-us-image"
                  src="images/tree1.webp"
                  alt="about"
                />
              </div>
              <h5>Inspiratie</h5>
              <p>
                Bij Vlonderexpert.nl staan we voor kwaliteit, service en
                vakmanschap. Onze specialisten helpen je graag bij het maken van
                de juiste keuze voor jouw tuin. Heb je vragen of wil je advies?
                Neem dan gerust contact met ons op.
              </p>
              <Row id="inspiration-grid" className="d-none d-md-flex g-3">
                <Col md={6}>
                  <Image src="images/inspiratie-1.webp" alt="inspiratie" />
                </Col>
                <Col md={6}>
                  <Image src="images/inspiratie-2.webp" alt="inspiratie" />
                </Col>
                <Col md={6}>
                  <Image src="images/inspiratie-3.webp" alt="inspiratie" />
                </Col>
                <Col md={6}>
                  <Image src="images/inspiratie-4.webp" alt="inspiratie" />
                </Col>
              </Row>
              <Row className="d-flex d-md-none">
                <Image src="images/inspiratie-1.webp" alt="inspiratie" />
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AboutScreen
