import {
  CART_DETAILS_REQUEST,
  CART_DETAILS_SUCCESS,
  CART_DETAILS_FAIL,
  CART_DETAILS_RESET,
} from '../constants/cartConstants'

export const cartDetailsReducer = (
  state = {
    cartItems: null,
    paymentMethod: '',
  },
  action,
) => {
  switch (action.type) {
    case CART_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CART_DETAILS_SUCCESS:
      return {
        ...action.payload,
        loading: false,
      }
    case CART_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case CART_DETAILS_RESET:
      return {
        ...state,
        cartItems: null,
      }
    default:
      return state
  }
}
