import React, { useState } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import { CART_DETAILS_SUCCESS } from '../constants/cartConstants'
import { localRemoveFromCart } from '../actions/cartActions'
import { Spinner } from 'react-bootstrap'

const RemoveFromCartButton = ({ item }) => {
  const dispatch = useDispatch()

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const removeFromCartHandler = async () => {
    if (userInfo) {
      // logged in
      setLoading(true)

      try {
        const { data } = await axios.put(
          `/api/carts/${userInfo.cart}/removeItem`,
          {
            product: item.product._id,
            productLength: item.productLength._id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          },
        )

        dispatch({
          type: CART_DETAILS_SUCCESS,
          payload: data,
        })

        setLoading(false)
      } catch (error) {
        setLoading(false)

        setError(error)
      }
    } else {
      // not logged in
      dispatch(localRemoveFromCart(item.productLength._id))
    }
  }

  return !loading ? (
    <Button
      onClick={removeFromCartHandler}
      type="button"
      variant="light"
      disabled={loading}
      style={{
        color: '#A02323',
        backgroundColor: 'white',
        border: 'none',
        margin: 'auto',
      }}
    >
      <i className="fas fa-trash"></i>
    </Button>
  ) : (
    <Spinner
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    ></Spinner>
  )
}

export default RemoveFromCartButton
