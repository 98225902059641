import React, { useState } from 'react'
import CategoryOffcanvas from './CategoryOffcanvas'
import { Nav, Container, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const SubHeader = ({ categories }) => {
  const [show, setShow] = useState(false)

  return (
    <div id="subheader" className="d-lg-block d-none">
      <CategoryOffcanvas show={show} setShow={setShow} data={categories} />
      <Nav>
        <Container>
          <Row>
            <Col md={8}>
              <Nav.Link
                style={{
                  display: 'inline-block',
                }}
                className={
                  show ? 'd-inline-block category-border' : 'd-inline-block'
                }
                id="category-button"
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
              >
                Categorieën&nbsp;
                <i className="fa-solid fa-chevron-down"></i>
              </Nav.Link>
            </Col>
            <Col md={4} className="text-center">
              <Row>
                <Col>
                  <LinkContainer to="/contact">
                    <Nav.Link>Contact</Nav.Link>
                  </LinkContainer>
                </Col>
                <Col>
                  <LinkContainer to="/faq">
                    <Nav.Link>FAQ</Nav.Link>
                  </LinkContainer>
                </Col>
                <Col>
                  <LinkContainer to="/about-us">
                    <Nav.Link>Over ons</Nav.Link>
                  </LinkContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Nav>
    </div>
  )
}

export default SubHeader
