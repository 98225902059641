import React, { useState, useEffect } from 'react'
import { Form, Button, Breadcrumb, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import FormContainer from '../components/FormContainer'
import Meta from '../components/Meta'
import { Link, useLocation, useHistory } from 'react-router-dom'
import axios from 'axios'
import Message from '../components/Message'
import ButtonSpinner from '../components/ButtonSpinner'
import ValidatePassword from '../components/ValidatePassword'
import '../css/ResetPasswordScreen.css'

const ResetPasswordScreen = () => {
  const location = useLocation()
  const history = useHistory()

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [validPassword, setValidPassword] = useState(true)

  const submitHandler = async (e) => {
    e.preventDefault()

    setError('')
    setSuccess(false)

    const params = new URLSearchParams(location.search)

    const token = params.get('token')

    if (newPassword !== confirmPassword) {
      setError('Wachtwoorden komen niet overeen')
      return
    }

    setLoading(true)

    try {
      await axios.post(
        '/api/users/resetpassword',
        { token, password: newPassword },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      setSuccess(true)
    } catch (error) {
      if (error.response.data.message === 'No reset token found') {
        setError('Link is verlopen of ongeldig. Vraag een nieuwe link aan.')
      } else {
        setError(error.response.data.message)
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    const token = params.get('token')
    if (!token) {
      history.push('/forgotpassword')
    }
  }, [location.search, history])

  return (
    <>
      <Meta title="Wijzig wachtwoord" />
      <FormContainer>
        <Breadcrumb>
          <LinkContainer className="active" to="/login">
            <Breadcrumb.Item>&lt; Ga terug</Breadcrumb.Item>
          </LinkContainer>
        </Breadcrumb>
        <h2>Wijzig wachtwoord</h2>
        {error && <Message variant="danger">{error}</Message>}
        {success && (
          <Message variant="success">
            Wachtwoord gewijzigd. <Link to="/login">Log in</Link>.
          </Message>
        )}
        {!success && (
          <Form id="resetpassword-form" onSubmit={submitHandler}>
            <ValidatePassword
              password={newPassword}
              setPassword={setNewPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              valid={validPassword}
              setValid={setValidPassword}
            />

            <Row style={{ marginTop: '61px', marginBottom: '86px' }}>
              <Button
                type="submit"
                variant="primary"
                className="w-50 text-center mx-auto"
                disabled={loading || !validPassword || newPassword.length === 0}
              >
                {loading ? <ButtonSpinner /> : 'Bevestigen'}
              </Button>
            </Row>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default ResetPasswordScreen
