import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import axios from 'axios'
import '../css/SearchRelatedProduct.css'

const RelatedProducts = ({
  productId,
  relatedProductsInitial = [],
  setRelatedProductIds,
}) => {
  const relatedProductsInitialSelected = relatedProductsInitial.map(
    (product) => {
      return {
        id: product._id,
        name: product.title,
        disabled: false,
      }
    },
  )

  const [loading, setLoading] = useState(false)
  const [relatedProducts, setRelatedProducts] = useState(
    relatedProductsInitialSelected,
  )
  const [searchOptions, setSearchOptions] = useState([])

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  }

  const search = (query) => {
    setLoading(true)

    axios
      .get(`/api/products/search?q=${query}`, config)
      .then((response) => {
        const products = response.data

        const options = products.map((product) => ({
          id: product._id,
          name: product.title,
          disabled: false,
        }))

        setSearchOptions(options)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  return (
    <AsyncTypeahead
      id="productSearch"
      filterBy={(option) => {
        const selectedIds = relatedProducts.map((product) => product.id)

        return !selectedIds.includes(option.id) && option.id !== productId
      }}
      multiple
      isLoading={loading}
      labelKey="name"
      minLength={3}
      onSearch={search}
      onChange={(selected) => {
        setRelatedProducts(selected)
        setRelatedProductIds(selected.map((product) => product.id))
      }}
      options={searchOptions}
      placeholder="Zoek producten..."
      selected={relatedProducts}
    />
  )
}

export default RelatedProducts
