import React from 'react'
import { Spinner } from 'react-bootstrap'

const ButtonSpinner = () => {
  return (
    <Spinner
      className="button-spinner"
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  )
}

export default ButtonSpinner
