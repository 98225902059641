import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button, Breadcrumb } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import Meta from '../components/Meta'
import {
  USER_UPDATE_PROFILE_RESET,
  USER_DETAILS_RESET,
} from '../constants/userConstants'
import ButtonSpinner from '../components/ButtonSpinner'

const ProfileChangeName = ({ match, history }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState('')

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success,
  } = userUpdateProfile

  useEffect(() => {
    if (success) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET })
      dispatch({ type: USER_DETAILS_RESET })
      history.push('/profile')
    } else {
      if (!user) {
        dispatch(getUserDetails('profile'))
      } else {
        setName(user.name)
      }
    }
  }, [user.name, history, success, dispatch, user])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      updateUserProfile({
        _id: user._id,
        name,
      }),
    )
  }

  return (
    <>
      <Meta title="Wijzig gegevens" />
      <FormContainer>
        <Breadcrumb>
          <LinkContainer to="/profile">
            <Breadcrumb.Item className="active">&lt; Ga terug</Breadcrumb.Item>
          </LinkContainer>
        </Breadcrumb>
        <h2>Wijzig gegevens</h2>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name" className="mt-2 mb-4">
              <Form.Label>Naam</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoCorrect="off"
                spellCheck="false"
                autoCapitalize="off"
                maxLength="64"
              ></Form.Control>
            </Form.Group>

            <Button
              type="submit"
              variant="primary"
              className="my-3 w-100"
              disabled={loadingUpdate}
            >
              {loadingUpdate ? <ButtonSpinner /> : 'Bevestig'}
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default ProfileChangeName
