const validatePassword = (password, confirmPassword) => {
  const emptyPasswords = password.length === 0 && confirmPassword.length === 0

  // password length requirement
  const passwordLength = password.length >= 8 && password.length <= 100

  // password equal to confirmation password
  const equalPassword = password === confirmPassword

  // at least one uppercase letter
  const uppercase = /.*[A-Z].*/.test(password)

  // at least one digit
  const digit = /.*\d.*/.test(password)

  if (
    emptyPasswords ||
    (passwordLength && equalPassword && uppercase && digit)
  ) {
    return []
  }

  const errors = []

  if (!equalPassword) {
    errors.push('Wachtwoorden komen niet overeen')
  }

  if (!passwordLength) {
    errors.push('Wachtwoord moet tussen de 8 en 100 tekens lang zijn')
  }

  if (!uppercase) {
    errors.push('Wachtwoord moet minstens 1 hoofdletter bevatten')
  }

  if (!digit) {
    errors.push('Wachtwoord moet minstens 1 cijfer bevatten')
  }

  return errors
}

export default validatePassword
