import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import '../css/Product.css'
import { formatPrice } from '../utils/currency'
import calculatePricePerMeter from '../utils/calculatePricePerMeter'

const Product = ({ product }) => {
  const lengthCount = product.lengths.length

  return (
    <Card className="w-100 product">
      <Link to={`/product/${product._id}`}>
        <Card.Img src={product.images[0]} variant="top" />
      </Link>

      <div className="d-flex h-100">
        <Card.Body className="d-flex flex-column">
          <Link to={`/product/${product._id}`}>
            <Card.Title as="h4">{product.title}</Card.Title>
          </Link>

          {lengthCount ? (
            <Card.Text className="mt-auto" as="div">
              <div className="mb-1">Per meter, vanaf</div>
              <span className="price">
                {formatPrice(
                  calculatePricePerMeter(
                    product.lengths[0].price,
                    product.lengths[0].value,
                  ),
                )}
              </span>
              <span>incl. BTW</span>
            </Card.Text>
          ) : (
            <></>
          )}
        </Card.Body>
      </div>
    </Card>
  )
}

export default Product
