import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Badge from 'react-bootstrap/Badge'
import { getCartDetails } from '../actions/cartActions'

const CartContentBadge = () => {
  const dispatch = useDispatch()

  // @ts-ignore
  const cartDetails = useSelector((state) => state.cartDetails)
  const { cartItems } = cartDetails

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const itemCount =
    cartItems && cartItems.length !== 0
      ? cartItems.reduce((acc, item) => acc + item.qty, 0)
      : 0

  useEffect(() => {
    if (!cartItems && userInfo) {
      dispatch(getCartDetails(userInfo.cart))
    }
  }, [cartItems, userInfo, dispatch])

  return cartItems && cartItems.length !== 0 ? (
    <Badge
      style={{ background: 'linear-gradient(#9A4F27, #EF6E1E)' }}
      className="cart-size-badge d-block"
    >
      {itemCount > 99 ? '99+' : itemCount}
    </Badge>
  ) : (
    <></>
  )
}

export default CartContentBadge
