import React from 'react'
import { useLocation } from 'react-router-dom'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Paginate = ({ pages, page }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  return pages > 1 ? (
    <Pagination className="justify-content-center my-4">
      {[...Array(pages).keys()].map((x) => {
        params.set('page', String(x + 1))
        return (
          <LinkContainer
            key={x + 1}
            to={`${location.pathname}?${params.toString()}`}
          >
            <Pagination.Item active={x + 1 === page} activeLabel="">
              {x + 1}
            </Pagination.Item>
          </LinkContainer>
        )
      })}
    </Pagination>
  ) : (
    <></>
  )
}

export default Paginate
