import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const TextEditor = ({ description, setDescription }) => {
  return (
    <div className="mb-4">
      <ReactQuill theme="snow" value={description} onChange={setDescription} />
    </div>
  )
}

export default TextEditor
