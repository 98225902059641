import React from 'react'
import { Card } from 'react-bootstrap'

const SavedAddress = ({ address }) => {
  return (
    <Card style={{ border: 'none' }}>
      <Card.Body style={{ padding: 0 }}>
        <address style={{ marginBottom: 0 }}>
          <Card.Text>
            {address.companyName && (
              <>
                <span>{address.companyName}</span>
                <br />
              </>
            )}
            <span>
              {address.firstName} {address.lastName}
            </span>
            <br />
            {address.address}
            {address.addition}
            <br />
            {address.postalCode} {address.city}
            <br />
            {address.country}
            <br />
          </Card.Text>
        </address>
      </Card.Body>
    </Card>
  )
}

export default SavedAddress
