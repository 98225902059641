import React, { useEffect } from 'react'
import { Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getOrderDetails } from '../actions/orderActions'
import {
  ORDER_SHIP_RESET,
  ORDER_CANCEL_RESET,
} from '../constants/orderConstants'
import PaymentMethod from '../components/PaymentMethod'
import SavedAddress from '../components/SavedAddress'
import Address from '../utils/Address'
import PaymentStatus from '../components/PaymentStatus'
import ShipmentStatus from '../components/ShipmentStatus'
import { formatPrice } from '../utils/currency'
import Meta from '../components/Meta'
import CancelAction from '../components/OrderActions/CancelAction'
import ShippingAction from '../components/OrderActions/ShippingAction'
import '../css/OrderScreen.css'

const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id

  const dispatch = useDispatch()

  // @ts-ignore
  const orderLineShip = useSelector((state) => state.orderShip)
  const { success: successShip } = orderLineShip

  // @ts-ignore
  const orderLineCancel = useSelector((state) => state.orderCancel)
  const { success: successCancel } = orderLineCancel

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // @ts-ignore
  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  if (error === 'Order not paid') {
    history.push('/placeorder')
  }

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }

    if (!order || order._id !== orderId || successShip || successCancel) {
      dispatch({ type: ORDER_SHIP_RESET })
      dispatch({ type: ORDER_CANCEL_RESET })

      dispatch(getOrderDetails(orderId))
    }
  }, [dispatch, order, orderId, successShip, successCancel, history, userInfo])

  const itemCount = order
    ? order.orderItems.reduce((acc, item) => acc + item.qty, 0)
    : 0

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div id="cart-container">
      <Meta title={`Bestelling ${orderId}`} />
      {order.status === 'canceled' && (
        <Message variant="danger" topMargin={true}>
          Deze bestelling is geannuleerd
        </Message>
      )}
      <h2 id="order-title" style={{ marginTop: '29px' }}>
        Bestelling {order.orderId}
      </h2>
      <Row className="py-0 gx-0 gx-xl-3">
        <Col xl={8}>
          <ListGroup variant="flush">
            {order.shippingAddress &&
              order.invoiceAddress &&
              order.paymentMethod && (
                <>
                  <ListGroup.Item className="py-3">
                    <h3>Bezorgadres</h3>
                    <SavedAddress address={order.shippingAddress} />
                    <ShipmentStatus order={order} />
                  </ListGroup.Item>

                  <ListGroup.Item className="py-3">
                    <h3>Factuuradres</h3>
                    {new Address(order.invoiceAddress).equals(
                      new Address(order.shippingAddress),
                    ) ? (
                      <span style={{ color: '#3B2116' }}>
                        Zelfde als bezorgadres
                      </span>
                    ) : (
                      <SavedAddress address={order.invoiceAddress} />
                    )}
                  </ListGroup.Item>

                  <ListGroup.Item className="py-3">
                    <h3>Betaalmethode</h3>
                    <span style={{ color: '#3B2116' }}>Methode: </span>
                    <PaymentMethod method={order.paymentMethod} />
                    <PaymentStatus order={order} />
                  </ListGroup.Item>
                </>
              )}

            <ListGroup.Item className="py-3">
              <h3>Artikelen</h3>
              <ListGroup id="cart-item-list" variant="flush">
                {order.orderItems.map((orderItem) => {
                  return (
                    <ListGroup.Item key={orderItem.productLength._id}>
                      <Row className="gy-2">
                        <Col md={3} className="d-flex align-items-center">
                          <Image
                            className="cart-item-image"
                            src={orderItem.product.images[0]}
                          />
                        </Col>
                        <Col md={5} className="d-flex align-items-center">
                          <span className="cart-item-title">
                            {`${orderItem.productLength.value}mm - `}
                            <Link to={`/product/${orderItem.product._id}`}>
                              {orderItem.product.title}
                            </Link>
                          </span>
                        </Col>
                        <Col
                          md={4}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <span className="cart-item-price">
                            {orderItem.qty} x{' '}
                            {formatPrice(orderItem.productLength.price)} ={' '}
                            {formatPrice(
                              orderItem.productLength.price * orderItem.qty,
                            )}
                          </span>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )
                })}
              </ListGroup>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xl={4}>
          <Card id="cart-summary">
            <div id="cart-summary-text">
              <h3
                style={{
                  textAlign: 'left',
                  marginBottom: '40px',
                  color: '#fff',
                }}
              >
                Subtotaal ({itemCount}) artikelen
              </h3>
              <Row className="mb-3" style={{ fontWeight: 400 }}>
                <Col>
                  <span>Artikelen</span>
                  <span id="cart-note-text">(Incl. BTW)</span>
                </Col>
                <Col className="text-end">
                  <span>{formatPrice(order.itemsPrice)}</span>
                </Col>
              </Row>
              <Row className="mb-3" style={{ fontWeight: 400 }}>
                <Col>
                  <span>Bijdrage bezorgkosten</span>
                </Col>
                <Col className="text-end">
                  <span>{formatPrice(order.shippingPrice)}</span>
                </Col>
              </Row>
              <Row className="mb-3" style={{ fontWeight: 400 }}>
                <Col>
                  <span>Subtotaal</span>
                  <span id="cart-note-text">(Excl. BTW)</span>
                </Col>
                <Col className="text-end">
                  <span>{formatPrice(order.totalPrice - order.taxPrice)}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span>Totaalbedrag</span>
                  <span id="cart-note-text">(Incl. BTW)</span>
                </Col>
                <Col className="text-end">
                  <span>{formatPrice(order.totalPrice)}</span>
                </Col>
              </Row>
            </div>
          </Card>
          {userInfo.isAdmin && (
            <ListGroup id="vertical-order-actions">
              {order.status !== 'canceled' && (
                <ListGroup.Item>
                  <CancelAction order={order} />
                </ListGroup.Item>
              )}
              {!order.isShipped && (
                <ListGroup.Item>
                  <ShippingAction order={order} />
                </ListGroup.Item>
              )}
            </ListGroup>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default OrderScreen
