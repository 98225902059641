import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import '../css/SearchBox.css'

const SearchBox = ({ style = {} }) => {
  const [query, setQuery] = useState('')

  const history = useHistory()
  const location = useLocation()

  const submitHandler = (e) => {
    e.preventDefault()
    if (query.trim()) {
      history.push(`/search?q=${query}`)

      const overlays = document.getElementsByClassName('overlay')
      for (const overlay of overlays) overlay.style.display = 'none'

      const searchInputs = document.getElementsByClassName('search-input')
      for (const searchInput of searchInputs) searchInput.blur()
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const query = params.get('q')

    if (query && location.pathname === '/search') {
      setQuery(query)
    } else {
      setQuery('')
    }
  }, [location.search, location.pathname])

  return (
    // note: inline property does not work because of version mismatch
    <>
      <div className="d-flex" style={style}>
        <div id="left-border" className="search-element"></div>
        <div id="middle-border" className="search-element">
          <Form onSubmit={submitHandler} className="searchbox">
            <Form.Control
              type="text"
              name="q"
              onChange={(e) => setQuery(e.target.value)}
              onFocus={(e) => {
                const overlays = document.getElementsByClassName('overlay')
                for (const overlay of overlays) overlay.style.display = 'block'

                const subHeader = document.getElementById('subheader')

                if (subHeader) {
                  subHeader.style.zIndex = '899'
                }
              }}
              onBlur={(e) => {
                const overlays = document.getElementsByClassName('overlay')
                for (const overlay of overlays) overlay.style.display = 'none'

                const subHeader = document.getElementById('subheader')

                if (subHeader) {
                  subHeader.style.zIndex = '999'
                }
              }}
              placeholder="Waar bent u naar op zoek?"
              value={query || ''}
              className="search-input mr-sm-2 ml-sm-5"
              autoCorrect="off"
              spellCheck="false"
              autoComplete="off"
              autoCapitalize="off"
              maxLength={100}
            ></Form.Control>
          </Form>
        </div>
        <div id="right-border" className="search-element"></div>
      </div>
      <div className="overlay"></div>
    </>
  )
}

export default SearchBox
