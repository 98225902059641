import React, { useEffect } from 'react'
import {
  Container,
  Breadcrumb,
  Row,
  Col,
  Accordion,
  Image,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Meta from '../components/Meta'
import '../css/FAQScreen.css'
import { Link } from 'react-router-dom'

const FAQScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Meta
        title="FAQ"
        description="Ontdek de antwoorden op veelgestelde vragen over vlonders bij Vlonderexpert.nl. Leer alles over houtverzorging, levering, FSC-certificering en het aanleggen van vlonders."
      />
      <Container style={{ marginBottom: '86px' }}>
        <Row className="justify-content-md-center">
          <Col xs={12} xl={7}>
            <Breadcrumb>
              <LinkContainer to="/">
                <Breadcrumb.Item>&lt; Verder winkelen</Breadcrumb.Item>
              </LinkContainer>
            </Breadcrumb>
            <h1
              id="faq-title"
              style={{
                color: '#3B2116',
                textAlign: 'left',
                marginBottom: '3rem',
              }}
              className="fs-md-3"
            >
              Veelgestelde vragen
            </h1>
            <Row>
              <div id="faq-question-container">
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Is jullie hout duurzaam?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Wij werken uitsluitend met hout afkomstig uit duurzaam
                        beheerde bossen. Door slechts enkele bomen per hectare
                        te kappen op een manier die zo min mogelijk schade
                        aanricht aan omringende bomen (low impact logging) en
                        het bos vervolgens met rust te laten (30 jaar), kan dit
                        vanzelf weer dicht groeien, zonder dat er (in principe)
                        aanplant nodig is. Op deze manier blijft het bos en zijn
                        functie in stand.
                      </p>
                      <p>
                        <b>Al ons hout FSC&copy;-gecertificeerd</b>. Wij zijn
                        FSC&copy;-gecertificeerd onder het nummer
                        SCS-COC-000652-QM (FSC&copy; C016391)
                      </p>
                      <p>
                        <Image
                          src="fsc.png"
                          alt="FSC logo (FSC&copy; C016391)"
                          style={{ maxWidth: '40%', marginTop: '0.75rem' }}
                        />
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Hoe wordt het hout bezorgd?
                    </Accordion.Header>
                    <Accordion.Body>
                      Om de kosten laag te houden hebben wij geen eigen
                      bezorgdienst. Wij besteden de bezorging uit aan een
                      partner, waar wij al jaren mee samen werken. Kosten zijn
                      €100 voor bezorging in Nederland (vaste land) en €200 voor
                      bezorging buiten Nederland. Voor bestellingen boven 2500
                      euro vervallen deze kosten.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Moet ik het hout behandelen?
                    </Accordion.Header>
                    <Accordion.Body>
                      Vanwege de natuurlijke duurzaamheid van onze houtsoorten
                      hoeft het hout niet behandeld te worden om het te
                      beschermen tegen rot, etc. U kunt er wel voor kiezen om
                      het hout te behandelen met bankirai- of vlonderolie met
                      UV-filter om de oorspronkelijke kleur beter te behouden.
                      Dit dient dan wel regelmatig (1 a 2 keer per jaar,
                      afhankelijk van hoeveel zon er op staat) herhaald te
                      worden. Zonder behandeling zal het hout grijs worden, dit
                      geldt voor alle houtsoorten.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Ik wil het hout graag zien voordat ik bestel. Kan dat?
                    </Accordion.Header>
                    <Accordion.Body>
                      Wij begrijpen dat u het hout graag wilt zien voordat u een
                      bestelling plaatst. Om onze scherpe prijzen te kunnen
                      blijven aanbieden en de kosten zo laag mogelijk te houden
                      voor onze klanten, hebben wij ervoor gekozen om ons
                      verkoopproces volledig online te houden. We streven er
                      echter naar om zo veel mogelijk informatie en duidelijke
                      foto's van onze producten op de website te tonen, zodat u
                      een goed beeld krijgt van het hout dat u bestelt. Mocht u
                      nog vragen hebben of meer details willen, aarzel dan niet
                      om contact met ons op te nemen. Wij helpen u graag verder!
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Wordt het hout zonder ribbels/groeven niet heel glad?
                    </Accordion.Header>
                    <Accordion.Body>
                      Planken zonder groeven/ribbels worden minder glad dan mét.
                      Het wrijvingsoppervlak is groter, waardoor de plank meer
                      grip heeft. Belangrijker is echter dat de planken beter
                      schoon te houden zijn en dat er daardoor minder water,
                      vuil en algen op/in de planken blijven staan. Daardoor
                      worden ze ook niet zo glad.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Mijn plank is krom. Wat nu?
                    </Accordion.Header>
                    <Accordion.Body>
                      Hoe langer een plank, hoe krommer (gemiddeld). Een kromme
                      plank is gelukkig prima te verwerken tot een mooi recht en
                      regelmatig vlonder. Belangrijk hierbij is dat er gewerkt
                      wordt met afstandshoudertjes tussen de planken en dat er
                      van het ene uiteinde naar het andere wordt gewerkt. De
                      lengte van de plank werkt hierbij als hefboom.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Welke afstand moet ik aanhouden tussen de planken?
                    </Accordion.Header>
                    <Accordion.Body>
                      Voor goed droog hout raden wij 8-10mm aan, omdat het hout
                      weer wat zal uitzetten als het vocht op neemt. Voor hout
                      wat niet kuntstmatig gedroogd is, kunt u 5-8mm aanhouden.
                      Dit hout zal in eerste instantie nog wat krimpen. Hout
                      werkt altijd en kan afhankelijk van de tijd van het jaar
                      een paar mm krimpen of uitzetten. Houd daarom altijd
                      voldoende afstand om te voorkomen dat planken tegen elkaar
                      aankomen en elkaar losdrukken.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Row>
            <Row>
              <div id="faq-more-questions">
                Ziet u uw vraag er niet tussen staan? Neem dan gerust contact
                met ons op via de{' '}
                <Link style={{ fontSize: '1.25rem' }} to="/contact">
                  contactpagina
                </Link>
                .
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default FAQScreen
