export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'
export const PRODUCT_DETAILS_RESET = 'PRODUCT_DETAILS_RESET'

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_NEWEST_REQUEST = 'PRODUCT_NEWEST_REQUEST'
export const PRODUCT_NEWEST_SUCCESS = 'PRODUCT_NEWEST_SUCCESS'
export const PRODUCT_NEWEST_FAIL = 'PRODUCT_NEWEST_FAIL'

export const PRODUCT_BESTSELLERS_REQUEST = 'PRODUCT_BESTSELLERS_REQUEST'
export const PRODUCT_BESTSELLERS_SUCCESS = 'PRODUCT_BESTSELLERS_SUCCESS'
export const PRODUCT_BESTSELLERS_FAIL = 'PRODUCT_BESTSELLERS_FAIL'
