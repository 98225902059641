import React from 'react'
import { Form } from 'react-bootstrap'

const CheckItem = ({
  type = 'radio',
  name,
  index = -1,
  checked,
  onSelect,
  children,
}) => {
  return (
    <Form.Check style={{ display: 'flex', alignItems: 'center' }}>
      <Form.Check.Input
        type={type}
        defaultChecked={checked}
        name={name}
        style={{ marginRight: '36px', width: '1.5em', height: '1.5em' }}
        onChange={() => onSelect(index)}
      />
      <Form.Check.Label>{children}</Form.Check.Label>
    </Form.Check>
  )
}

export default CheckItem
