import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button, Breadcrumb } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import Meta from '../components/Meta'
import {
  USER_UPDATE_PROFILE_RESET,
  USER_DETAILS_RESET,
} from '../constants/userConstants'
import ButtonSpinner from '../components/ButtonSpinner'
import ValidatePassword from '../components/ValidatePassword'

const ProfileChangeName = ({ match, history }) => {
  const dispatch = useDispatch()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [validPassword, setValidPassword] = useState(true)

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success,
  } = userUpdateProfile

  useEffect(() => {
    if (success) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET })
      dispatch({ type: USER_DETAILS_RESET })
      history.push('/profile')
    } else {
      if (!user) {
        dispatch(getUserDetails('profile'))
      }
    }
  }, [user.name, history, success, dispatch, user])

  const submitHandler = async (e) => {
    e.preventDefault()
    await dispatch(updateUserProfile({ id: user._id, password }))
  }

  return (
    <>
      <Meta title="Wijzig wachtwoord" />
      <FormContainer>
        <Breadcrumb>
          <LinkContainer to="/profile">
            <Breadcrumb.Item className="active">&lt; Ga terug</Breadcrumb.Item>
          </LinkContainer>
        </Breadcrumb>
        <h2>Wijzig wachtwoord</h2>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <ValidatePassword
              password={password}
              setPassword={setPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              valid={validPassword}
              setValid={setValidPassword}
            />

            <Button
              type="submit"
              variant="primary"
              className="my-3 w-100"
              disabled={
                loadingUpdate || !validPassword || password.length === 0
              }
            >
              {loadingUpdate ? <ButtonSpinner /> : 'Bevestig'}
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default ProfileChangeName
