import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Breadcrumb, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getUserDetails, updateUser } from '../actions/userActions'
import { USER_UPDATE_RESET } from '../constants/userConstants'
import Meta from '../components/Meta'

const UserEditScreen = ({ match, history }) => {
  const userId = match.params.id

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  const dispatch = useDispatch()

  // @ts-ignore
  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  // @ts-ignore
  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET })
      history.push('/admin/users')
    } else {
      if (!user.name || user._id !== userId) {
        dispatch(getUserDetails(userId))
      } else {
        setName(user.name)
        setEmail(user.email)
        setIsAdmin(user.isAdmin)
      }
    }
  }, [user, userId, dispatch, successUpdate, history])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      updateUser({
        _id: userId,
        name,
        email,
        isAdmin,
      }),
    )
  }

  return (
    <>
      <Meta title="Admin | Wijzig gebruiker" />
      <FormContainer>
        <Breadcrumb>
          <LinkContainer to="/admin/users">
            <Breadcrumb.Item className="active">&lt; Ga terug</Breadcrumb.Item>
          </LinkContainer>
        </Breadcrumb>
        <h2>Bewerk gebruiker</h2>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="userId" className="mt-2">
              <Form.Label>ID</Form.Label>
              <Form.Control
                type="text"
                placeholder={user._id}
                readOnly
                className="mb-4"
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Naam</Form.Label>
              <Form.Control
                type="text"
                placeholder="Naam"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mb-4"
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="email" className="mt-2">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mb-4"
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="isAdmin" className="mt-2">
              <Form.Check
                type="checkbox"
                label="Admin"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
                className="mb-4"
              ></Form.Check>
            </Form.Group>

            <Row style={{ marginTop: '48px' }}>
              <Button
                type="submit"
                variant="primary"
                className="w-50 text-center mx-auto"
                style={{ marginBottom: '86px' }}
              >
                Bevestig
              </Button>
            </Row>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default UserEditScreen
