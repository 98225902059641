import React, { useEffect } from 'react'
import { Row, Col, ListGroup, Card, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import AccountNav from '../components/AccountNav'
import Meta from '../components/Meta'
import { LinkContainer } from 'react-router-bootstrap'
import { numericDate } from '../utils/dates'

const ProfileScreen = ({ location, history }) => {
  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserDetails('profile'))
      }
    }
  }, [dispatch, history, userInfo, user, success])

  return (
    <>
      <Meta title="Mijn gegevens" />
      <Container>
        <Row>
          <Col lg={3}></Col>
          <Col lg={9}>
            <h2 style={{ marginTop: '29px' }}>Mijn gegevens</h2>
          </Col>
        </Row>
        {error && <Message variant="danger">{error}</Message>}
        {success && <Message variant="success">Account bijgewerkt</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Row>
            <Col lg={3}>
              <AccountNav />
            </Col>
            <Col lg={9}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Card style={{ border: 'none' }}>
                    <Card.Body style={{ padding: 0 }}>
                      <Card.Text>
                        <Row>
                          <Col>
                            <span style={{ fontWeight: 700 }}>Naam</span>
                          </Col>
                          <Col>{user.name}</Col>
                        </Row>
                        <Row>
                          <Col>
                            <span style={{ fontWeight: 700 }}>E-mail</span>
                          </Col>
                          <Col>{user.email}</Col>
                        </Row>
                        <Row className="mt-4">
                          <Col>
                            Gebruiker sinds{' '}
                            {user.createdAt && numericDate(user.createdAt)}
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row style={{ '--bs-gutter-y': '0.5rem' }}>
                    <Col md={6}>
                      <LinkContainer to="/profile/changename">
                        <Button
                          variant="outline-primary"
                          className="btn-sm w-100"
                        >
                          <i className="fas fa-edit me-2"></i>Wijzig gegevens
                        </Button>
                      </LinkContainer>
                    </Col>
                    <Col md={6}>
                      <LinkContainer to="/profile/changepassword">
                        <Button
                          variant="outline-primary"
                          className="btn-sm w-100"
                        >
                          <i className="fas fa-edit me-2"></i>Wijzig wachtwoord
                        </Button>
                      </LinkContainer>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        )}
      </Container>
    </>
  )
}

export default ProfileScreen
