import React from 'react'
import { Alert } from 'react-bootstrap'

const Message = ({
  variant,
  children,
  noBottomMargin = false,
  topMargin = false,
}) => {
  const style = {
    marginBottom: noBottomMargin ? '0' : '1rem',
    marginTop: topMargin ? '1rem' : '0',
  }

  return (
    <Alert style={style} variant={variant}>
      {children}
    </Alert>
  )
}

Message.defaultProps = {
  variant: 'info',
}

export default Message
