import React from 'react'

const ShippingStatus = ({ order }) => {
  if (order.isShipped) {
    return <i className="fas fa-check" style={{ color: 'green' }}></i>
  } else {
    return <i className="fas fa-times" style={{ color: 'red' }}></i>
  }
}

export default ShippingStatus
