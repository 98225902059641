import React from 'react'
import Message from './Message'
import { writtenDate } from '../utils/dates'

const PaymentStatus = ({ order }) => {
  if (order.isPaid) {
    return (
      <Message variant="success" topMargin={true} noBottomMargin={true}>
        Betaald op {writtenDate(order.paidAt)}
      </Message>
    )
  } else {
    return (
      <Message variant="warning" topMargin={true} noBottomMargin={true}>
        Nog niet betaald
      </Message>
    )
  }
}

export default PaymentStatus
