import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Spinner, Row, Col } from 'react-bootstrap'
import { CART_DETAILS_SUCCESS } from '../constants/cartConstants'
import { localAddToCart } from '../actions/cartActions'

const ChangeQuantitySelect = ({ item }) => {
  const dispatch = useDispatch()

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const changeQuantityHandler = async (e, item) => {
    if (userInfo) {
      // logged in
      setLoading(true)

      try {
        const { data } = await axios.put(
          `/api/carts/${userInfo.cart}/addItem`,
          {
            product: item.product._id,
            productLength: item.productLength._id,
            qty: Number(e.target.value),
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          },
        )

        setLoading(false)

        dispatch({
          type: CART_DETAILS_SUCCESS,
          payload: data,
        })
      } catch (error) {
        setLoading(false)

        setError(error)
      }
    } else {
      // not logged in
      dispatch(localAddToCart({ ...item, qty: Number(e.target.value) }))
    }
  }

  return (
    <Row className="w-100 gx-0">
      <Col md={8}>
        <Form.Control
          className="form-select"
          as="select"
          value={item.qty}
          onChange={(e) => changeQuantityHandler(e, item)}
          disabled={loading}
          style={{ color: '#5D5D5D', textAlign: 'center' }}
        >
          {[...Array(item.productLength.countInStock).keys()].map((x) => (
            <option key={x + 1} value={x + 1}>
              {x + 1}
            </option>
          ))}
        </Form.Control>
      </Col>
      <Col md={4} className="d-flex align-items-center">
        {loading && (
          <Spinner
            size="sm"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        )}
      </Col>
    </Row>
  )
}

export default ChangeQuantitySelect
