import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Spinner } from 'react-bootstrap'
import '../css/Length.css'
import { addDecimals } from '../utils/utils'
import axios from 'axios'
import { PRODUCT_DETAILS_SUCCESS } from '../constants/productConstants'

const Length = ({ length }) => {
  const dispatch = useDispatch()

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [price, setPrice] = useState(length.price)
  const [countInStock, setCountInStock] = useState(length.countInStock)
  const [deliveryTime, setDeliveryTime] = useState(length.deliveryTime)

  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)

  const removeLengthHandler = async () => {
    // logged in
    setLoadingDelete(true)

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    try {
      const { data } = await axios.delete(`/api/lengths/${length._id}`, config)

      dispatch({
        type: PRODUCT_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      // TODO: handle error
    }

    setLoadingDelete(false)
  }

  const saveLengthHandler = async () => {
    // logged in
    setLoadingSave(true)

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    try {
      const { data } = await axios.put(
        `/api/lengths/${length._id}`,
        {
          price,
          countInStock,
          deliveryTime,
        },
        config,
      )

      dispatch({
        type: PRODUCT_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      // TODO: handle error
    }

    setLoadingSave(false)
  }

  const resetLengthHandler = () => {
    setPrice(length.price)
    setCountInStock(length.countInStock)
    setDeliveryTime(length.deliveryTime)
  }

  const change =
    price !== length.price ||
    countInStock !== length.countInStock ||
    deliveryTime !== length.deliveryTime

  return (
    <tr key={length._id}>
      <td>{length.value}</td>
      <td>
        <Form.Control
          className="length-price-input"
          type="number"
          value={price}
          min={0}
          step={0.01}
          disabled={loadingSave}
          onChange={(e) => {
            let priceValue = parseFloat(e.target.value) || 0
            priceValue = addDecimals(priceValue)
            setPrice(priceValue)
          }}
          style={{
            width: '5.5rem',
            height: '2rem',
          }}
        ></Form.Control>
      </td>
      <td>
        <Form.Control
          className="length-stock-input form-select"
          as="select"
          value={countInStock}
          onChange={(e) => setCountInStock(parseInt(e.target.value))}
          disabled={loadingSave}
          style={{
            width: '5.5rem',
            height: '2rem',
          }}
        >
          {[...Array(1001).keys()].map((x) => (
            <option key={x} value={x}>
              {x}
            </option>
          ))}
        </Form.Control>
      </td>
      <td>
        <Form.Control
          className="length-delivery-input form-select"
          as="select"
          value={deliveryTime}
          onChange={(e) => setDeliveryTime(parseInt(e.target.value))}
          disabled={loadingSave}
          style={{
            width: '5.5rem',
            height: '2rem',
          }}
        >
          {[...Array(14).keys()].map((x) => (
            <option key={x + 1} value={x + 1}>
              {x + 1}
            </option>
          ))}
        </Form.Control>
      </td>
      <td>
        {!loadingDelete ? (
          <Button
            onClick={removeLengthHandler}
            type="button"
            variant="light"
            style={{
              color: '#A02323',
              backgroundColor: 'inherit',
              border: 'none',
              margin: 'auto',
            }}
          >
            <i className="fas fa-trash"></i>
          </Button>
        ) : (
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          ></Spinner>
        )}
        <Button
          onClick={resetLengthHandler}
          type="button"
          variant="light"
          style={{
            color: '#552E19',
            backgroundColor: 'inherit',
            border: 'none',
            margin: 'auto',
          }}
        >
          <i className="fas fa-undo"></i>
        </Button>
        {change &&
          (!loadingSave ? (
            <Button
              onClick={saveLengthHandler}
              type="button"
              variant="light"
              style={{
                color: '#5D5D5D',
                backgroundColor: 'inherit',
                border: 'none',
                margin: 'auto',
              }}
            >
              <i className="fas fa-save"></i>
            </Button>
          ) : (
            <Spinner
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ))}
      </td>
    </tr>
  )
}

export default Length
