import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title = '', description = '', keyword = '' }) => {
  return (
    <Helmet>
      <title>
        {title ? `Vlonderexpert.nl | ${title}` : 'Vlonderexpert.nl'}
      </title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keyword} />
    </Helmet>
  )
}

export default Meta
