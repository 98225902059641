import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Message from '../components/Message'
import Meta from '../components/Meta'

const NotFoundScreen = () => {
  return (
    <>
      <Meta
        title="Pagina niet gevonden"
        description="Ontdek ons assortiment hoogwaardige vlonderplanken op Vlonderexpert.nl. Als je op een verkeerde pagina bent beland, keer dan terug naar de startpagina of neem contact met ons op voor hulp."
      />
      <div className="text-center">
        <div style={{ fontSize: '9rem', color: '#552E19' }}>404</div>
        <Row className="gx-0">
          <Col md={6} className="mx-auto">
            <Message variant="info">
              Oeps, niet gevonden. <Link to="/">Ga terug</Link>
            </Message>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default NotFoundScreen
