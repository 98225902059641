import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, Container, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listNewestArrivals, listBestsellers } from '../actions/productActions'
import Meta from '../components/Meta'
import '../css/HomeScreen.css'

const HomeScreen = () => {
  const dispatch = useDispatch()

  // @ts-ignore
  const productNewest = useSelector((state) => state.productNewest)
  const { loading, error, products } = productNewest

  // @ts-ignore
  const productBestsellers_ = useSelector((state) => state.productBestsellers)
  const {
    loading: loadingBestsellers,
    error: errorBestsellers,
    products: productBestsellers,
  } = productBestsellers_

  // @ts-ignore
  const categoryList = useSelector((state) => state.categoryList)
  const { categories } = categoryList

  useEffect(() => {
    dispatch(listNewestArrivals())
    dispatch(listBestsellers())
  }, [dispatch])

  return (
    <>
      <Meta description="Vlonderexpert.nl is dé specialist in tuinhout en hardhout. Of je nu op zoek bent naar een prachtige vlonder, een stevige schutting of duurzame terrasplanken, bij ons vind je het allemaal. Ons FSC-gecertificeerd hout komt uit duurzaam beheerde bossen. Bij Vlonderexpert.nl staan kwaliteit, service en vakmanschap voorop. Profiteer van snelle en voordelige levering." />
      <Row id="banner" className="mx-0 g-0">
        <Col md={6} className="d-none d-lg-flex">
          <div id="banner-text">
            <div id="banner-title">Welkom bij Vlonderexpert.nl!</div>
            <div id="banner-description">
              Wij zijn dé specialist in tuinhout en hardhout. Of je nu op zoek
              bent naar een prachtige vlonder, een stevige schutting of duurzame
              terrasplanken, bij ons vind je het allemaal!
            </div>
            <div>
              <Button
                onClick={() => {
                  const categoryOverview =
                    document.getElementById('category-overview')
                  categoryOverview?.scrollIntoView({ behavior: 'smooth' })
                }}
              >
                Zie hardhout
              </Button>
            </div>
          </div>
        </Col>
        <Col md={6} className="d-none d-lg-flex">
          <Image id="banner-image-container" src="images/banner.jpg"></Image>
        </Col>
        <Col className="d-none d-sm-flex d-lg-none">
          <div id="banner-text">
            <div id="banner-description">Welkom bij Vlonderexpert.nl!</div>
            <div>
              <Button
                size="sm"
                onClick={() => {
                  const categoryOverview =
                    document.getElementById('category-overview')
                  categoryOverview?.scrollIntoView({ behavior: 'smooth' })
                }}
              >
                Zie hardhout
              </Button>
            </div>
          </div>
        </Col>
        <Col className="d-none d-sm-flex d-lg-none">
          <Image id="banner-image-container" src="images/banner.jpg"></Image>
        </Col>
        <Col className="d-flex d-sm-none">
          <div className="position-relative w-100">
            <Image id="banner-image-container" src="images/banner.jpg"></Image>

            <div className="position-absolute top-50 start-50 translate-middle text-center">
              <div id="banner-description">Welkom bij Vlonderexpert.nl!</div>
              <div>
                <Button
                  size="sm"
                  onClick={() => {
                    const categoryOverview =
                      document.getElementById('category-overview')
                    categoryOverview?.scrollIntoView({ behavior: 'smooth' })
                  }}
                >
                  Zie hardhout
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Container>
        <div id="category-overview">
          <h1 className="mb-5">Uitgelichte categorieën</h1>
          <Row className="d-flex d-lg-none" id="category-list-small">
            {
              // only map over first 3 categories
              categories && categories.slice(0,3).map((category, index) => (
                <LinkContainer to={category.url}>
                <div className="category-link">
                    <Row>
                        <Col xs="auto">
                            <Image src={category.image} width={88} height={66} />
                        </Col>
                        <Col style={{ alignContent: 'center' }}>{category.name}</Col>
                        <Col
                            xs="auto"
                            style={{ alignContent: 'center' }}
                            className="pe-5"
                        >
                            <i className="fa-solid fa-chevron-right"></i>
                        </Col>
                    </Row>
                </div>
            </LinkContainer>
              ))
            }
          </Row>
          <Row className="d-none d-lg-flex" id="category-list">
            <Col md={4}>
              <LinkContainer to="/c/vlonderplanken">
                <div className="category-image" id="category-plank">
                  <h3 style={{ textAlign: 'center', color: '#fff' }}>
                    Vlonderplanken
                  </h3>
                </div>
              </LinkContainer>
            </Col>
            <Col md={4}>
              <LinkContainer to="/c/palen">
                <div className="category-image" id="category-pole">
                  <h3 style={{ textAlign: 'center', color: '#fff' }}>Palen</h3>
                </div>
              </LinkContainer>
            </Col>
            <Col md={4}>
              <LinkContainer to="/c/onderregels">
                <div className="category-image" id="category-under">
                  <h3 style={{ textAlign: 'center', color: '#fff' }}>
                    Onderregels
                  </h3>
                </div>
              </LinkContainer>
            </Col>
          </Row>
        </div>
        <div id="bestsellers">
          <h1>Bestsellers</h1>
          {loadingBestsellers ? (
            <Loader />
          ) : errorBestsellers ? (
            <Message variant="danger">{errorBestsellers}</Message>
          ) : (
            <>
              <Row id="productlist">
                {productBestsellers.map((product) => (
                  <Col
                    key={product._id}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    className="align-items-stretch d-flex"
                  >
                    <Product product={product} />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
        <div id="latest-arrivals">
          <h1>Latest arrivals</h1>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <>
              <Row id="productlist">
                {products.map((product) => (
                  <Col
                    key={product._id}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    className="align-items-stretch d-flex"
                  >
                    <Product product={product} />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
      </Container>
    </>
  )
}

export default HomeScreen
