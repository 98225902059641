import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listCategories, deleteCategory } from '../actions/categoryActions'
import Meta from '../components/Meta'
import DeleteModal from '../components/DeleteModal'

const CategoryListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [categoryId, setCategoryId] = useState('')
  const [categoryName, setCategoryName] = useState('')

  // @ts-ignore
  const categoryList = useSelector((state) => state.categoryList)
  const { loading, error, categories } = categoryList

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // @ts-ignore
  const categoryDelete = useSelector((state) => state.categoryDelete)
  const { success: successDelete, loading: loadingDelete } = categoryDelete

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listCategories())
    } else {
      history.push('/login')
    }
  }, [dispatch, userInfo, history, successDelete])

  const deleteHandler = (id, name) => {
    setCategoryId(id)
    setCategoryName(name)

    setShow(true)
  }

  const handleClose = () => setShow(false)
  const handleConfirm = (id) => {
    dispatch(deleteCategory(id))
    setShow(false)
  }

  return (
    <>
      <Meta title="Admin | Categorieën" />
      <Container
        style={{
          paddingTop: '29px',
        }}
      >
        <h2>Categorieën {categories ? `(${categories.length})` : ''}</h2>
      </Container>
      {loading ? (
        <Loader delay={0} />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Container>
          <Row className="align-items-center mb-3 gy-2">
            <Col></Col>
            <Col
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              <LinkContainer to="/admin/category/add" className="mx-0">
                <Button className="btn-sm">
                  <i className="fas fa-plus me-3"></i>Nieuwe categorie
                </Button>
              </LinkContainer>
            </Col>
          </Row>
          <div style={{ marginBottom: '86px' }}>
            <Table striped bordered hover responsive>
              <thead style={{ borderBottom: '2px solid #3B2116' }}>
                <tr>
                  <th>Naam</th>
                  <th>URL</th>
                  <th>Rang</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {categories &&
                  categories.map((category) => (
                    <tr key={category.name}>
                      <td>{category.name}</td>
                      <td>{category.url}</td>
                      <td>{category.rank}</td>
                      <td>
                        <Row>
                          <Col className="d-flex align-items-center justify-content-center">
                            <LinkContainer
                              to={`/admin/category/${category._id}/edit`}
                              style={{
                                backgroundColor: 'inherit',
                                border: 'none',
                                color: '#552E19',
                              }}
                            >
                              <Button variant="light" className="btn-sm">
                                <i className="fas fa-edit"></i>
                              </Button>
                            </LinkContainer>
                          </Col>
                          <Col className="d-flex align-items-center justify-content-center">
                            <Button
                              variant="light"
                              className="btn-sm"
                              style={{
                                color: '#A02323',
                                backgroundColor: 'inherit',
                                border: 'none',
                              }}
                              onClick={() =>
                                deleteHandler(category._id, category.name)
                              }
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <DeleteModal
            isVisible={show}
            handleClose={handleClose}
            title="Verwijder categorie"
            body={`Weet je zeker dat je categorie "${categoryName}" wil verwijderen?`}
            handleConfirm={async () => handleConfirm(categoryId)}
            loading={loadingDelete}
          />
        </Container>
      )}
    </>
  )
}

export default CategoryListScreen
