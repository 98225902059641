import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { countries } from '../config'

const AddressForm = ({
  setCompanyName,
  setFirstName,
  setLastName,
  setPostalCode,
  setAddress,
  setAddition,
  setCity,
  setCountry,
  companyName,
  firstName,
  lastName,
  postalCode,
  address,
  addition,
  city,
  country,
  submitHandler,
  loading,
}) => {
  const [validated, setValidated] = useState(false)

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={async (e) => {
        e.preventDefault()

        const form = e.currentTarget
        if (form.checkValidity() === true) await submitHandler()

        setValidated(true)
      }}
    >
      <Form.Group controlId="companyName" className="mt-2">
        <Form.Label>
          Bedrijfsnaam <span className="note-text">(niet verplicht)</span>
        </Form.Label>
        <Form.Control
          type="text"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          className="mb-4"
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="name" className="mt-2">
        <Form.Label>Voornaam</Form.Label>
        <Form.Control
          type="text"
          value={firstName}
          required
          onChange={(e) => setFirstName(e.target.value)}
          className="mb-4"
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="name" className="mt-2">
        <Form.Label>Achternaam</Form.Label>
        <Form.Control
          type="text"
          value={lastName}
          required
          onChange={(e) => setLastName(e.target.value)}
          className="mb-4"
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="postalCode" className="mt-2">
        <Form.Label>Postcode</Form.Label>
        <Form.Control
          type="text"
          placeholder="Postcode"
          value={postalCode}
          required
          onChange={(e) => setPostalCode(e.target.value)}
          className="mb-4"
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="address" className="mt-2">
        <Form.Label>Adres</Form.Label>
        <Form.Control
          type="text"
          placeholder="Straatnaam en nummer"
          value={address}
          required
          onChange={(e) => setAddress(e.target.value)}
          className="mb-4"
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="addition" className="mt-2">
        <Form.Label>
          Toevoeging <span className="note-text">(niet verplicht)</span>
        </Form.Label>
        <Form.Control
          type="text"
          value={addition}
          onChange={(e) => setAddition(e.target.value)}
          className="mb-4"
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="city" className="mt-2">
        <Form.Label>Plaats</Form.Label>
        <Form.Control
          type="text"
          placeholder="Plaats"
          value={city}
          required
          onChange={(e) => setCity(e.target.value)}
          className="mb-4"
        ></Form.Control>
      </Form.Group>

      <Form.Group controlId="country" className="mt-2">
        <Form.Label>Land</Form.Label>
        <Form.Select
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          className="mb-4"
        >
          {countries.map((country) => (
            <option key={country}>{country}</option>
          ))}
        </Form.Select>
      </Form.Group>
      <Button
        className="w-100 mt-4"
        variant="primary"
        disabled={loading}
        type="submit"
      >
        {loading ? (
          <span
            className="spinner-border spinner-border-sm button-spinner"
            role="status"
          ></span>
        ) : (
          'Opslaan'
        )}
      </Button>
    </Form>
  )
}

export default AddressForm
