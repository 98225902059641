import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Button, Container, Modal, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import Address from '../utils/Address'
import BasicSet from '../utils/BasicSet'
import { isEmpty } from '../utils/utils'
import { updateUserProfile } from '../actions/userActions'
import SavedAddress from '../components/SavedAddress'
import AddressForm from '../components/AddressForm'
import { countries } from '../config'
import SavedAddressList from '../components/SavedAddressList'
import { CART_DETAILS_SUCCESS } from '../constants/cartConstants'
import ButtonSpinner from '../components/ButtonSpinner'
import { getCartDetails } from '../actions/cartActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Meta from '../components/Meta'
import '../css/ShippingScreen.css'

const ShippingScreen = ({ history }) => {
  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // @ts-ignore
  const cartDetails = useSelector((state) => state.cartDetails)
  const { cartItems, loading, error, shippingAddress, invoiceAddress } =
    cartDetails

  // local state
  const [loadingPersist, setLoadingPersist] = useState(false)
  const [errorPersist, setErrorPersist] = useState('')

  const [editShippingAddress, setEditShippingAddress] = useState(false)
  const [editInvoiceAddress, setEditInvoiceAddress] = useState(false)

  const [selectedShippingAddress, setSelectedShippingAddress] = useState(0)
  const [selectedInvoiceAddress, setSelectedInvoiceAddress] = useState(0)

  // modal state
  const [showModal, setShowModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [editIndexModal, setEditIndexModal] = useState(-1)

  const [modalCompanyName, setModalCompanyName] = useState('')
  const [modalFirstName, setModalFirstName] = useState('')
  const [modalLastName, setModalLastName] = useState('')
  const [modalPostalCode, setModalPostalCode] = useState('')
  const [modalAddress, setModalAddress] = useState('')
  const [modalAddition, setModalAddition] = useState('')
  const [modalCity, setModalCity] = useState('')
  const [modalCountry, setModalCountry] = useState(countries[0])

  // shipping address state
  const [shippingAddressCompanyName, setShippingAddressCompanyName] =
    useState('')
  const [shippingAddressFirstName, setShippingAddressFirstName] = useState('')
  const [shippingAddressLastName, setShippingAddressLastName] = useState('')
  const [shippingAddressPostalCode, setShippingAddressPostalCode] = useState('')
  const [shippingAddressAddress, setShippingAddressAddress] = useState('')
  const [shippingAddressAddition, setShippingAddressAddition] = useState('')
  const [shippingAddressCity, setShippingAddressCity] = useState('')
  const [shippingAddressCountry, setShippingAddressCountry] = useState(
    countries[0],
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!userInfo) {
      history.push('/login?redirect=shipping')
    } else {
      if (cartItems?.length === 0) {
        history.push('/cart')
      }

      if (userInfo.addresses.length !== 0) {
        const addresses = new BasicSet(
          ...userInfo.addresses.map((address) => new Address(address)),
        )

        if (shippingAddress && !isEmpty(shippingAddress)) {
          const shippingIndex = addresses.index(new Address(shippingAddress))
          if (shippingIndex > -1) setSelectedShippingAddress(shippingIndex)
        }

        if (invoiceAddress && !isEmpty(invoiceAddress)) {
          const invoiceIndex = addresses.index(new Address(invoiceAddress))
          if (invoiceIndex > -1) setSelectedInvoiceAddress(invoiceIndex)
        }
      }

      if (!cartItems) {
        dispatch(getCartDetails(userInfo.cart))
      }
    }
  }, [dispatch, userInfo, shippingAddress, invoiceAddress, cartItems, history])

  const onSubmitShippingAddress = async () => {
    setLoadingPersist(true)

    await persistAddress(
      userInfo.addresses[selectedShippingAddress],
      userInfo.addresses[selectedInvoiceAddress],
    )

    setLoadingPersist(false)

    setEditShippingAddress(false)
  }

  const onSubmitInvoiceAddress = async () => {
    // TODO: check if update necessary
    // TODO: update of selected invoice address occurs even if persist fails
    setLoadingPersist(true)

    await persistAddress(
      userInfo.addresses[selectedShippingAddress],
      userInfo.addresses[selectedInvoiceAddress],
    )

    setLoadingPersist(false)

    setEditInvoiceAddress(false)
  }

  const editAddressHandler = (address, index) => {
    setShowModal(true)
    setEditModal(true)
    setEditIndexModal(index)

    setModalCompanyName(address.companyName)
    setModalFirstName(address.firstName)
    setModalLastName(address.lastName)
    setModalPostalCode(address.postalCode)
    setModalAddress(address.address)
    setModalAddition(address.addition)
    setModalCity(address.city)
    setModalCountry(address.country)
  }

  const addAddressHandler = () => {
    setShowModal(true)
    setEditModal(false)

    setModalCompanyName('')
    setModalFirstName('')
    setModalLastName('')
    setModalPostalCode('')
    setModalAddress('')
    setModalAddition('')
    setModalCity('')
    setModalCountry(countries[0])
  }

  const submitHandler = async () => {
    if (!shippingAddress && !invoiceAddress) {
      setLoadingPersist(true)

      await persistAddress(newShippingAddress, newInvoiceAddress)

      setLoadingPersist(false)
    }

    history.push('/payment')
  }

  const persistAddress = async (shippingAddress, invoiceAddress) => {
    try {
      const { data } = await axios.put(
        `/api/carts/${userInfo.cart}`,
        {
          shippingAddress,
          invoiceAddress,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        },
      )

      dispatch({
        type: CART_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      setErrorPersist(error)
    }
  }

  const newShippingAddress =
    shippingAddress || userInfo.addresses[selectedShippingAddress]
  const newInvoiceAddress =
    invoiceAddress || userInfo.addresses[selectedInvoiceAddress]

  return (
    <>
      <Meta title="Verzenden" />
      <FormContainer>
        <CheckoutSteps step1 step2 step3={undefined} step4={undefined} />
        <h2>Verzenden</h2>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : userInfo.addresses.length === 0 ? (
          <div style={{ marginBottom: '86px' }}>
            <AddressForm
              setCompanyName={setShippingAddressCompanyName}
              setFirstName={setShippingAddressFirstName}
              setLastName={setShippingAddressLastName}
              setPostalCode={setShippingAddressPostalCode}
              setAddress={setShippingAddressAddress}
              setAddition={setShippingAddressAddition}
              setCity={setShippingAddressCity}
              setCountry={setShippingAddressCountry}
              companyName={shippingAddressCompanyName}
              firstName={shippingAddressFirstName}
              lastName={shippingAddressLastName}
              postalCode={shippingAddressPostalCode}
              address={shippingAddressAddress}
              addition={shippingAddressAddition}
              city={shippingAddressCity}
              country={shippingAddressCountry}
              loading={loadingPersist}
              submitHandler={async (e) => {
                setLoadingPersist(true)

                const address = new Address({
                  companyName: shippingAddressCompanyName,
                  firstName: shippingAddressFirstName,
                  lastName: shippingAddressLastName,
                  postalCode: shippingAddressPostalCode,
                  address: shippingAddressAddress,
                  addition: shippingAddressAddition,
                  city: shippingAddressCity,
                  country: shippingAddressCountry,
                }).toObjectLiteral()

                await persistAddress(address, address)

                await dispatch(
                  updateUserProfile({
                    id: userInfo._id,
                    addresses: [address],
                  }),
                )

                setLoadingPersist(false)
              }}
            />
          </div>
        ) : editShippingAddress ? (
          <>
            <h5>Bezorgadres</h5>
            <SavedAddressList
              addresses={userInfo.addresses}
              groupName="shippingUserAddress"
              selectedAddress={selectedShippingAddress}
              setSelectedAddress={setSelectedShippingAddress}
              editAddressHandler={editAddressHandler}
              addAddressHandler={addAddressHandler}
            />
            <Button
              variant="primary"
              className="my-3 w-100"
              onClick={onSubmitShippingAddress}
              disabled={loadingPersist}
            >
              {loadingPersist ? <ButtonSpinner /> : 'Doorgaan > '}
            </Button>
          </>
        ) : editInvoiceAddress ? (
          <>
            <h5>Factuuradres</h5>
            <SavedAddressList
              addresses={userInfo.addresses}
              groupName="invoiceUserAddress"
              selectedAddress={selectedInvoiceAddress}
              setSelectedAddress={setSelectedInvoiceAddress}
              editAddressHandler={editAddressHandler}
              addAddressHandler={addAddressHandler}
            />
            <Button
              variant="primary"
              className="my-3 w-100"
              onClick={onSubmitInvoiceAddress}
              disabled={loadingPersist}
            >
              {loadingPersist ? <ButtonSpinner /> : 'Doorgaan > '}
            </Button>
          </>
        ) : (
          <Row>
            {/* Shipping address configuration */}
            {newShippingAddress && (
              <Col xl={6}>
                <h5 className="d-flex justify-content-between align-items-center">
                  <div>Bezorgadres</div>
                  <span style={{ display: 'inline-block' }}>
                    <Button
                      variant="light"
                      className="btn-sm"
                      onClick={() => setEditShippingAddress(true)}
                      style={{ backgroundColor: 'inherit', border: 'none' }}
                    >
                      <i className="fas fa-edit"></i>
                    </Button>
                  </span>
                </h5>
                <SavedAddress address={newShippingAddress} />
              </Col>
            )}
            {/* Invoice address configuration */}
            {newShippingAddress && newInvoiceAddress && (
              <Col xl={6}>
                <h5 className="d-flex justify-content-between align-items-center">
                  <div>Factuuradres</div>
                  <span style={{ display: 'inline-block' }}>
                    <Button
                      variant="light"
                      className="btn-sm"
                      onClick={() => setEditInvoiceAddress(true)}
                      style={{ backgroundColor: 'inherit', border: 'none' }}
                    >
                      <i className="fas fa-edit"></i>
                    </Button>
                  </span>
                </h5>
                {new Address(newShippingAddress).equals(
                  new Address(newInvoiceAddress),
                ) ? (
                  <p className="mb-0">Zelfde als bezorgadres</p>
                ) : (
                  <SavedAddress address={newInvoiceAddress} />
                )}
                <Button
                  type="submit"
                  variant="primary"
                  className="my-3 w-100"
                  onClick={submitHandler}
                  disabled={loadingPersist}
                >
                  {loadingPersist ? <ButtonSpinner /> : 'Doorgaan > '}
                </Button>
              </Col>
            )}
          </Row>
        )}
      </FormContainer>
      <Modal
        show={showModal}
        onHide={(e) => {
          setShowModal(false)
          setEditModal(false)
        }}
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            {editModal ? 'Pas adres aan' : 'Voeg nieuw adres toe'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddressForm
            setCompanyName={setModalCompanyName}
            setFirstName={setModalFirstName}
            setLastName={setModalLastName}
            setPostalCode={setModalPostalCode}
            setAddress={setModalAddress}
            setAddition={setModalAddition}
            setCity={setModalCity}
            setCountry={setModalCountry}
            companyName={modalCompanyName}
            firstName={modalFirstName}
            lastName={modalLastName}
            postalCode={modalPostalCode}
            address={modalAddress}
            addition={modalAddition}
            city={modalCity}
            country={modalCountry}
            loading={loadingPersist}
            submitHandler={async (e) => {
              setLoadingPersist(true)

              const address = new Address({
                companyName: modalCompanyName,
                firstName: modalFirstName,
                lastName: modalLastName,
                postalCode: modalPostalCode,
                address: modalAddress,
                addition: modalAddition,
                city: modalCity,
                country: modalCountry,
              })

              const addresses = userInfo.addresses.map(
                (address) => new Address(address),
              )

              let temporaryAddresses = []

              if (!editModal) {
                console.log(addresses)
                console.log(address)

                temporaryAddresses = new BasicSet(...addresses, address).values

                console.log(temporaryAddresses)

                // add address
                await dispatch(
                  updateUserProfile({
                    id: userInfo._id,
                    addresses: temporaryAddresses,
                  }),
                )
              } else {
                // modify address
                addresses[editIndexModal] = address

                temporaryAddresses = new BasicSet(...addresses).values

                await dispatch(
                  updateUserProfile({
                    id: userInfo._id,
                    addresses: temporaryAddresses,
                  }),
                )
              }

              // FIXME
              await persistAddress(
                temporaryAddresses[selectedShippingAddress],
                temporaryAddresses[selectedInvoiceAddress],
              )

              setLoadingPersist(false)

              setEditShippingAddress(false)
              setEditInvoiceAddress(false)

              setShowModal(false)
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ShippingScreen
