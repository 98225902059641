import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Navbar,
    Container,
    Row,
    Col,
    Image,
    Nav,
    NavDropdown,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../actions/userActions'

const SuperHeader = ({ setShow }) => {
    const dispatch = useDispatch()

    // @ts-ignore
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const title = (
        <i className="fas fa-user me-2" style={{ fontSize: '1.6rem' }}></i>
    )

    return (
        <div id="superheader" className="d-block d-lg-none">
            <Container>
                <Row>
                    <Col style={{ alignContent: 'center' }} xs="auto">
                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            onClick={() => setShow(true)}
                        />
                    </Col>
                    <Col className="px-0" style={{ alignContent: 'center' }}>
                        <LinkContainer to="/">
                            <Navbar.Brand>
                                <Image
                                    src="/images/logo-lightmode.png"
                                    alt="logo"
                                    className="d-none d-sm-inline-block"
                                    height="70px"
                                />
                                <Image
                                    src="/images/logo-only-lightmode.png"
                                    alt="logo"
                                    className="d-inline-block d-sm-none"
                                    height="70px"
                                />
                            </Navbar.Brand>
                        </LinkContainer>
                    </Col>
                    <Col
                        className="ps-0"
                        xs="auto"
                        style={{ alignContent: 'center' }}
                    >
                        <Nav>
                            <LinkContainer to="/cart">
                                <Nav.Link>
                                    <i
                                        className="fas fa-shopping-cart me-2"
                                        style={{ fontSize: '1.6rem' }}
                                    ></i>
                                </Nav.Link>
                            </LinkContainer>
                            {userInfo ? (
                                <NavDropdown title={title} id="username">
                                    {userInfo && userInfo.isAdmin && (
                                        <>
                                            <LinkContainer to="/admin/users">
                                                <NavDropdown.Item>
                                                    Gebruikers
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                            <LinkContainer to="/admin/products">
                                                <NavDropdown.Item>
                                                    Producten
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                            <LinkContainer to="/admin/orders">
                                                <NavDropdown.Item>
                                                    Bestellingen
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                            <LinkContainer to="/admin/categories">
                                                <NavDropdown.Item>
                                                    Categorieën
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                        </>
                                    )}
                                    <LinkContainer to="/profile">
                                        <NavDropdown.Item>
                                            Mijn gegevens
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/orders">
                                        <NavDropdown.Item>
                                            Mijn bestellingen
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/addresses">
                                        <NavDropdown.Item>
                                            Mijn adressen
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Item
                                        className="ms-0"
                                        onClick={() => dispatch(logout())}
                                    >
                                        Uitloggen
                                    </NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <LinkContainer to="/login">
                                    <Nav.Link>
                                        <i
                                            className="fas fa-user me-2"
                                            style={{ fontSize: '1.6rem' }}
                                        ></i>
                                    </Nav.Link>
                                </LinkContainer>
                            )}
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SuperHeader
