import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Modal, Row, Col } from 'react-bootstrap'
import { shipOrder } from '../../actions/orderActions'
import ButtonSpinner from '../ButtonSpinner'

const ShippingAction = ({ order }) => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)

  // @ts-ignore
  const orderShip = useSelector((state) => state.orderShip)
  const { loading } = orderShip

  const handleClose = () => {
    setShow(false)
  }

  const handleConfirm = () => {
    shipmentHandler()
    setShow(false)
  }

  const shipmentHandler = () => {
    dispatch(shipOrder(order._id))
  }

  return (
    <>
      <Button
        type="button"
        onClick={() => setShow(true)}
        variant="primary"
        className="w-100"
      >
        {loading ? <ButtonSpinner /> : 'Markeer als verzonden'}
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            Markeer bestelling ${order._id} als verzonden
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-2">
          <Row>
            <Col>
              <Button
                variant="secondary"
                size="sm"
                className="w-100"
                onClick={handleClose}
              >
                Sluit
              </Button>
            </Col>
            <Col>
              <Button
                type="submit"
                variant="primary"
                size="sm"
                className="w-100"
                onClick={handleConfirm}
                disabled={loading}
              >
                {loading ? <ButtonSpinner /> : 'Bevestig'}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ShippingAction
