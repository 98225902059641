import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import PaymentMethod from '../components/PaymentMethod'
import { CART_DETAILS_SUCCESS } from '../constants/cartConstants'
import ButtonSpinner from '../components/ButtonSpinner'
import Message from '../components/Message'
import { getCartDetails } from '../actions/cartActions'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import * as config from '../config'

const PaymentScreen = ({ history }) => {
  const dispatch = useDispatch()

  // @ts-ignore
  const cartDetails = useSelector((state) => state.cartDetails)
  const { cartItems, shippingAddress, cartLoading, cartError } = cartDetails

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [paymentMethod, setPaymentMethod] = useState(
    cartDetails.paymentMethod || 'ideal',
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!cartItems) {
        dispatch(getCartDetails(userInfo.cart))
      }
    }
  })

  const submitHandler = async (e) => {
    e.preventDefault()

    if (cartDetails.paymentMethod === paymentMethod) {
      history.push('/placeorder')
      return // no need to update payment method
    }

    setLoading(true)

    try {
      const { data } = await axios.put(
        `/api/carts/${userInfo.cart}`,
        { paymentMethod },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        },
      )

      setLoading(false)

      dispatch({
        type: CART_DETAILS_SUCCESS,
        payload: data,
      })

      history.push('/placeorder')
    } catch (error) {
      setLoading(false)

      setError(error)
    }
  }

  return (
    <>
      <Meta title="Betaalmethode" />
      <FormContainer>
        <CheckoutSteps step1 step2 step3 step4={undefined} />
        <h2>Betaalmethode</h2>
        {cartLoading ? (
          <Loader />
        ) : cartError ? (
          <Message variant="danger">{cartError}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group>
              <Form.Label className="mb-4" as="legend">
                Selecteer betaalmethode
              </Form.Label>
              <Col>
                {Object.keys(config.paymentMethods).map((method, index) => (
                  <Form.Check
                    key={`payment-${index}`}
                    type="radio"
                    label={<PaymentMethod method={method} />}
                    id={method}
                    name="paymentMethod"
                    value={method}
                    checked={paymentMethod === method}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className="my-3"
                  ></Form.Check>
                ))}
              </Col>
            </Form.Group>

            <Button type="submit" variant="primary" className="my-3 w-50">
              {!loading ? 'Doorgaan > ' : <ButtonSpinner />}
            </Button>

            {error && <Message variant="danger">{error}</Message>}
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default PaymentScreen
