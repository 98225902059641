import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import {
    Navbar,
    Nav,
    Container,
    NavDropdown,
    Col,
    Image,
} from 'react-bootstrap'
import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'
import CartContentBadge from './CartContentBadge'
import '../css/Header.css'
import SubHeader from './SubHeader'
import SuperHeader from './SuperHeader'
import CollapsedOffcanvas from './CollapsedOffcanvas'
import { listCategories } from '../actions/categoryActions'

const Header = () => {
    const dispatch = useDispatch()

    const [show, setShow] = useState(false)

    // @ts-ignore
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    // @ts-ignore
    const categoryList = useSelector((state) => state.categoryList)
    const { categories } = categoryList

    useEffect(() => {
        dispatch(listCategories())
    }, [dispatch])

    const logoutHandler = () => {
        dispatch(logout())
    }

    const title = (
        <i className="fas fa-user me-2" style={{ fontSize: '1.6rem' }}></i>
    )

    return (
        <header>
            <CollapsedOffcanvas
                show={show}
                setShow={setShow}
                categories={categories}
            />
            <SuperHeader setShow={setShow} />
            <Navbar className="header-nav">
                <Container>
                    <Col className="d-none d-lg-block">
                        <LinkContainer to="/">
                            <Navbar.Brand>
                                <Image
                                    src="/images/logo.png"
                                    alt="logo"
                                    className="d-inline-block"
                                    height="70px"
                                />
                            </Navbar.Brand>
                        </LinkContainer>{' '}
                    </Col>
                    <Col xs={12} lg={5}>
                        <SearchBox />
                    </Col>
                    <Col className="d-none d-lg-block">
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Col className="d-lg-none">
                                <SearchBox
                                    style={{
                                        marginTop: '0.5rem',
                                        marginBottom: '0.5rem',
                                    }}
                                />
                            </Col>
                            <Nav className="ms-auto">
                                <div
                                    className="ms-lg-3"
                                    style={{ position: 'relative' }}
                                >
                                    <CartContentBadge />
                                    <LinkContainer to="/cart">
                                        <Nav.Link>
                                            <i
                                                className="fas fa-shopping-cart me-2"
                                                style={{ fontSize: '1.6rem' }}
                                            ></i>
                                        </Nav.Link>
                                    </LinkContainer>
                                </div>
                                {userInfo ? (
                                    <NavDropdown title={title} id="username">
                                        {userInfo && userInfo.isAdmin && (
                                            <>
                                                <LinkContainer to="/admin/users">
                                                    <NavDropdown.Item>
                                                        Gebruikers
                                                    </NavDropdown.Item>
                                                </LinkContainer>
                                                <LinkContainer to="/admin/products">
                                                    <NavDropdown.Item>
                                                        Producten
                                                    </NavDropdown.Item>
                                                </LinkContainer>
                                                <LinkContainer to="/admin/orders">
                                                    <NavDropdown.Item>
                                                        Bestellingen
                                                    </NavDropdown.Item>
                                                </LinkContainer>
                                                <LinkContainer to="/admin/categories">
                                                    <NavDropdown.Item>
                                                        Categorieën
                                                    </NavDropdown.Item>
                                                </LinkContainer>
                                            </>
                                        )}
                                        <LinkContainer to="/profile">
                                            <NavDropdown.Item>
                                                Mijn gegevens
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/orders">
                                            <NavDropdown.Item>
                                                Mijn bestellingen
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/addresses">
                                            <NavDropdown.Item>
                                                Mijn adressen
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                        <NavDropdown.Item
                                            className="ms-0"
                                            onClick={logoutHandler}
                                        >
                                            Uitloggen
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                ) : (
                                    <LinkContainer to="/login">
                                        <Nav.Link>
                                            <i
                                                className="fas fa-user me-2"
                                                style={{ fontSize: '1.6rem' }}
                                            ></i>
                                        </Nav.Link>
                                    </LinkContainer>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Col>
                </Container>
            </Navbar>
            <SubHeader categories={categories} />
        </header>
    )
}

export default Header
