/**
 * Calculate price per meter
 *
 * @param {number} price
 * @param {number} value - length in mm
 * @returns
 */
const calculatePricePerMeter = (price, value) => {
  return +(Math.round(price / (value / 1000) + 'e+2') + 'e-2')
}

export default calculatePricePerMeter
