import React from 'react'
import { ListGroup, Button } from 'react-bootstrap'
import CheckItem from './CheckItem'
import SavedAddress from './SavedAddress'

const SavedAddressList = ({
  addresses,
  groupName,
  selectedAddress,
  setSelectedAddress,
  editAddressHandler,
  addAddressHandler,
}) => {
  return (
    <ListGroup as="ul" variant="flush">
      {addresses.map((address, index) => (
        <ListGroup.Item as="li" key={`address-${index}`}>
          <div className="d-flex justify-content-between">
            <CheckItem
              name={groupName}
              index={index}
              checked={selectedAddress === index}
              onSelect={setSelectedAddress}
            >
              <SavedAddress address={address} />
            </CheckItem>
            <span style={{ display: 'inline-block' }}>
              <Button
                variant="light"
                className="btn-sm"
                onClick={() => editAddressHandler(address, index)}
                style={{ backgroundColor: 'inherit', border: 'none' }}
              >
                <i className="fas fa-edit"></i>
              </Button>
            </span>
          </div>
        </ListGroup.Item>
      ))}
      {addresses.length < 3 && (
        <ListGroup.Item as="li" key="addAddress">
          <CheckItem
            checked={false}
            name={groupName}
            onSelect={addAddressHandler}
          >
            <p className="mb-0">Adres toevoegen</p>
          </CheckItem>
        </ListGroup.Item>
      )}
    </ListGroup>
  )
}

export default SavedAddressList
