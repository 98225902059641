import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button, Row, Col, Breadcrumb } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { login } from '../actions/userActions'
import Meta from '../components/Meta'
import ButtonSpinner from '../components/ButtonSpinner'
import PasswordInput from '../components/PasswordInput'
import '../css/LoginScreen.css'

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  // @ts-ignore
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  // @ts-ignore
  const cartDetails = useSelector((state) => state.cartDetails)
  const { cartItems } = cartDetails

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = async (e) => {
    e.preventDefault()
    await dispatch(login(email, password, cartItems ?? []))
  }

  return (
    <>
      <Meta
        title="Inloggen"
        description="Log in op Vlonderexpert.nl om uw bestellingen van hoogwaardige FSC-gecertificeerde hardhouten vlonderplanken, onderregels en palen te beheren en uw accountgegevens te bekijken."
      />
      <FormContainer>
        <Breadcrumb>
          <LinkContainer to="/">
            <Breadcrumb.Item>&lt; Verder winkelen</Breadcrumb.Item>
          </LinkContainer>
        </Breadcrumb>
        <h2>Inloggen</h2>
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group id="email-input" controlId="email">
            <Form.Label>E-mailadres</Form.Label>
            <Form.Control
              type="email"
              placeholder="E-mailadres"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoCorrect="off"
              spellCheck="false"
              autoCapitalize="off"
              maxLength={100}
            ></Form.Control>
          </Form.Group>

          <PasswordInput password={password} setPassword={setPassword} />

          <Row style={{ marginTop: '31px' }}>
            <Col></Col>
            <Col>
              <Link className="text-end my-3 py-2 d-block" to="/forgotpassword">
                Wachtwoord vergeten?
              </Link>
            </Col>
          </Row>
          <Row style={{ marginTop: '61px' }}>
            <Button
              type="submit"
              variant="primary"
              className="mb-4 w-50 text-center mx-auto"
              disabled={loading}
            >
              {loading ? <ButtonSpinner /> : 'Inloggen'}
            </Button>
          </Row>
        </Form>

        <Row>
          <Col>
            <div id="register-link">
              Nieuwe klant?{' '}
              <Link
                to={redirect ? `/register?redirect=${redirect}` : '/register'}
              >
                Registreer
              </Link>
            </div>
          </Col>
        </Row>
      </FormContainer>
    </>
  )
}

export default LoginScreen
