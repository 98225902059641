import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import ButtonSpinner from '../ButtonSpinner'
import { cancelOrder } from '../../actions/orderActions'
import DeleteModal from '../DeleteModal'

const CancelAction = ({ order }) => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)

  // @ts-ignore
  const orderCancel = useSelector((state) => state.orderCancel)
  const { loading } = orderCancel

  const handleClose = () => {
    setShow(false)
  }

  const handleConfirm = () => {
    cancelHandler()
    setShow(false)
  }

  const cancelHandler = () => {
    dispatch(cancelOrder(order._id))
  }

  return (
    <>
      <Button
        type="button"
        onClick={() => setShow(true)}
        variant="danger"
        className="w-100"
      >
        {loading ? <ButtonSpinner /> : 'Annuleer bestelling'}
      </Button>
      <DeleteModal
        isVisible={show}
        handleClose={handleClose}
        title="Annuleer bestelling"
        body={`Weet je zeker dat je bestelling ${order._id} wil annuleren?`}
        handleConfirm={async () => handleConfirm()}
        loading={loading}
      />
    </>
  )
}

export default CancelAction
