import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productNewestReducer,
  productBestsellersReducer,
} from './reducers/productReducers'
import { cartDetailsReducer } from './reducers/cartReducers'
import {
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
} from './reducers/userReducers'
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderListUserReducer,
  orderListReducer,
  orderShipReducer,
  orderCancelReducer,
} from './reducers/orderReducers'
import {
  lengthCreateReducer,
  lengthDeleteReducer,
  lengthDetailsReducer,
  lengthUpdateReducer,
} from './reducers/lengthReducers'
import {
  categoryListReducer,
  categoryDetailsReducer,
  categoryDeleteReducer,
  categoryCreateReducer,
  categoryUpdateReducer,
} from './reducers/categoryReducers'

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productNewest: productNewestReducer,
  productBestsellers: productBestsellersReducer,
  cartDetails: cartDetailsReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderListUser: orderListUserReducer,
  orderList: orderListReducer,
  orderShip: orderShipReducer,
  orderCancel: orderCancelReducer,
  lengthCreate: lengthCreateReducer,
  lengthDelete: lengthDeleteReducer,
  lengthDetails: lengthDetailsReducer,
  lengthUpdate: lengthUpdateReducer,
  categoryList: categoryListReducer,
  categoryDetails: categoryDetailsReducer,
  categoryDelete: categoryDeleteReducer,
  categoryCreate: categoryCreateReducer,
  categoryUpdate: categoryUpdateReducer,
})

const cartItems = localStorage.getItem('cartItems')
const cartItemsFromStorage = cartItems ? JSON.parse(cartItems) : null

const userInfo = localStorage.getItem('userInfo')
const userInfoFromStorage = userInfo ? JSON.parse(userInfo) : null

const initialState = {
  cartDetails: {
    cartItems: cartItemsFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
)

export default store
