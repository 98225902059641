class Address {
  constructor({
    companyName,
    firstName,
    lastName,
    postalCode,
    address,
    addition,
    city,
    country,
  }) {
    this.companyName = companyName
    this.firstName = firstName
    this.lastName = lastName
    this.postalCode = postalCode
    this.address = address
    this.addition = addition
    this.city = city
    this.country = country
  }

  equals(other) {
    if (
      this.companyName === other.companyName &&
      this.firstName === other.firstName &&
      this.lastName === other.lastName &&
      this.postalCode === other.postalCode &&
      this.address === other.address &&
      this.addition === other.addition &&
      this.city === other.city &&
      this.country === other.country
    ) {
      return true
    }
    return false
  }

  toObjectLiteral() {
    return { ...this }
  }

  toJson() {
    return JSON.stringify(this.toObjectLiteral())
  }

  validate() {
    // TODO: implement
  }
}

export default Address
