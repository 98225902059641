import React from 'react'

import { Image } from 'react-bootstrap'
import { paymentMethods } from '../config'

const PaymentMethod = ({ method, width = 41, height = 26 }) => {
  const { displayName, logo } = paymentMethods[method]

  return (
    <span>
      <span style={{ color: '#884825', fontSize: '1rem' }}>{displayName}</span>{' '}
      <Image
        throwIfNamespace={false}
        src={logo}
        alt={`${method}-logo`}
        style={{ width: 'auto', height, marginLeft: '0.5rem' }}
      />
    </span>
  )
}

export default PaymentMethod
